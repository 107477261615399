/* eslint-disable react/prop-types */
import ModalBox from 'components/Modal';
import React from 'react';
import Button from '../Button';

import styles from './styles.module.css';

const ModalConfirmarAccion = props => {
  const modalChildren = () => (
    <>
      {props.children}
      <div className={styles.botonesContenedor}>
        <Button
          title={props.buttonTextPositiveAction}
          callback={props.buttonYesCallback}
          fullSize={false}
          type="primary"
          size="lg"
          disabled={props.loading}
          isLoading={props.loading}
          className={styles.boton}
        />
        {props.buttonNoCallback ? (
          <Button
            title={props.buttonTextNegativeAction}
            callback={props.buttonNoCallback}
            fullSize={false}
            size="lg"
            disabled={props.loading}
            className={styles.boton}
          />
        ) : null}
      </div>
    </>
  );

  return (
    <ModalBox
      titulo={props.title}
      texto={props.text}
      abierto={props.isOpen}
      onClose={props.onClose}
    >
      {modalChildren()}
    </ModalBox>
  );
};

export default ModalConfirmarAccion;
