import React from 'react';
import PropTypes from 'prop-types';

import ModalBox from 'components/Modal';
import InfoSection from '../InfoSection';
import Button from '../Button';

const ModalVerificarCiudadano = ({
  campos,
  texto,
  textoBoton,
  callbackBoton,
  cargando,
  abierto,
  onClose,
}) => {
  const contenidoModal = () => (
    <>
      {campos && <InfoSection alMedio campos={campos} />}
      <Button
        title={textoBoton}
        callback={callbackBoton}
        fullSize
        type="primary"
        size="lg"
        disabled={cargando}
        isLoading={cargando}
      />
    </>
  );

  return (
    <ModalBox
      texto={texto}
      abierto={abierto}
      onClose={onClose}
      cargando={cargando}
    >
      {contenidoModal()}
    </ModalBox>
  );
};

ModalVerificarCiudadano.propTypes = {
  campos: PropTypes.array,
  texto: PropTypes.string,
  textoBoton: PropTypes.string,
  callbackBoton: PropTypes.func,
  cargando: PropTypes.bool,
  abierto: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ModalVerificarCiudadano;
