import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// constantes
import {
  obtenerMensajeListo,
  obtenerPrimerParrafo,
  SEGUNDO_PARRAFO_TEXT,
} from 'constants/commonConstants';

// api actions
import { fetchAgente } from 'actions/agente';

// componentes
import TitleCard from 'components/TitleCard';

// estilos
import styles from './CrearConfiguracionTurnoExito.module.css';

const CrearConfiguracionTurnoExito = ({ nombreAgente, turnosPorDia }) => (
  <div className={styles.container}>
    <TitleCard
      title={obtenerMensajeListo(nombreAgente)}
      className={styles.titulo}
    />
    <div className={styles.body}>
      <p>{obtenerPrimerParrafo(turnosPorDia)}</p>
      <p className={styles.segundoParrafo}>{SEGUNDO_PARRAFO_TEXT}</p>
    </div>
  </div>
);

CrearConfiguracionTurnoExito.propTypes = {
  nombreAgente: PropTypes.string,
  turnosPorDia: PropTypes.number,
};

export const mapStateToProps = state => ({
  nombreAgente: state.agente.currentAgenteNombre,
});

export default connect(mapStateToProps, { fetchAgente })(
  CrearConfiguracionTurnoExito,
);
