import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

// actions
import { fetchAgente } from 'actions/agente';
import { showHeader } from 'actions/ui';

// selectors
import {
  cargandoUsuarioLogueado,
  currentAgenteSelector,
} from 'store/selectors';

// api services
import { buscarCiudadanos } from 'services/apiServices';

// utils
import Auth from 'utils/Auth';

// constantes
import {
  CREATE_PROFILE_ROUTE,
  STATISTICS_ROUTE,
} from 'constants/RouterConstants';
import {
  TEXTO_BOTON_BUSCAR_CIUDADANOS,
  TEXTO_BOTON_CREAR_CIUDADANO,
  TEXTO_LLENAR_INPUT,
  TEXTO_PLACEHOLDER_BARRA_BUSQUEDA,
  TEXTO_SIN_RESULTADOS_BUSQUEDA,
  TEXTO_TITULO,
  TEXTO_ESTADISTICAS_USUARIOS,
} from 'constants/commonConstants';

// componentes
import Card from 'components/Card';
import TitleCard from 'components/TitleCard';
import Snackbar from 'components/Snackbar';
import Button from 'components/Button';
import ListaBusqueda from 'components/SearchList/ListaBusqueda';

// mui
import { Pagination, TextField } from '@mui/material';

// permisos
import usePermissions from 'hooks/usePermissions';
import {
  PERMISO_CREAR_CIUDADANO,
  PERMISO_SUPER_AGENTE,
} from 'constants/permissionsConstants';

// styles
import Spinner from 'react-spinkit';
import styles from './styles.module.css';

// Estos al ser una propiedad embebida de MUI solo se puede hacer en base a su forma de modificar los estilos
const sxBorrarBorde = {
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-menu': {
    overflowY: 'scroll',
  },
};

const BuscadorCiudadanosContainer = () => {
  const [ciudadanosBusqueda, setCiudadanosBusqueda] = useState(null);
  const [cantidadTotalCiudadanosBusqueda, setCantidadTotalCiudadanosBusqueda] =
    useState(null);
  const [tamanioPagina, setTamanioPagina] = useState(null);
  const [pagina, setPagina] = useState(1);
  const [textoBuscado, setTextoBuscado] = useState('');
  const [snackBarInfo, setSnackBarInfo] = useState({
    mostrar: false,
    texto: '',
  });
  const [cargandoCiudadanos, setCargandoCiudadanos] = useState(false);
  const [buscoTexto, setBuscoTexto] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const currentAgente = useSelector(currentAgenteSelector);
  const cargandoCurrentAgente = useSelector(cargandoUsuarioLogueado);
  const puedeCrearCiudadano = usePermissions([PERMISO_CREAR_CIUDADANO]);
  const esSuperAgente = usePermissions([PERMISO_SUPER_AGENTE]);

  useEffect(() => {
    dispatch(showHeader());
    if (window.REACT_APP_FEATURED_FLAG_SNACKBAR_ELIMINAR_CIUDADANO) {
      const parsed = queryString.parse(location?.search);
      const msg = parsed.msg ? parsed.msg : null;
      if (msg) {
        setSnackBarInfo({
          mostrar: true,
          texto: msg,
        });
      }
    }
    if (
      Auth.getInstance().isAuthenticated() &&
      !currentAgente &&
      !cargandoCurrentAgente
    ) {
      dispatch(fetchAgente());
    }
  }, []);

  const handleBuscar = async (texto, paginaActual) => {
    try {
      setCargandoCiudadanos(true);
      const response = await buscarCiudadanos(texto, paginaActual);
      const agentes = response.data.results;
      setCiudadanosBusqueda(agentes);
      if (paginaActual === 1) {
        const cantidadAgentes = response.data.count;
        setTamanioPagina(
          agentes.length > 0
            ? Math.ceil(cantidadAgentes / agentes.length)
            : null,
        );
      }
      setCantidadTotalCiudadanosBusqueda(response.data.count);
      setPagina(paginaActual || 1);
    } catch {
      setCiudadanosBusqueda(null);
      setCantidadTotalCiudadanosBusqueda(null);
      setPagina(1);
      setSnackBarInfo({
        mostrar: true,
        texto: TEXTO_SIN_RESULTADOS_BUSQUEDA,
      });
    } finally {
      setCargandoCiudadanos(false);
    }
  };

  const irACrear = () => {
    history.push(CREATE_PROFILE_ROUTE);
  };

  const irAEstadisticas = () => {
    history.push(STATISTICS_ROUTE);
  };

  const handleBuscarPushed = () => {
    const trimmedText = textoBuscado.trim();

    handleBuscar(trimmedText, 1);
    setTextoBuscado(trimmedText);
    setBuscoTexto(true);
  };

  const handleCambiaBusqueda = e => {
    setTextoBuscado(e.target.value);
    setBuscoTexto(false);
  };

  const obtenerComponenteListaCiudadanos = () => {
    // Si está cargando la búsqueda
    if (cargandoCiudadanos) {
      return (
        <div className={styles.spinner}>
          <Spinner
            className="spin-class"
            name="ball-spin-fade-loader"
            fadeIn="none"
            color={styles.primary}
          />
        </div>
      );
    }
    // Si hubo resultados
    if (ciudadanosBusqueda !== null) {
      return (
        <div className={styles.contenedorCiudadanos}>
          <ListaBusqueda
            buscoRecien={buscoTexto}
            ciudadanos={ciudadanosBusqueda}
            total={cantidadTotalCiudadanosBusqueda}
            textoBusqueda={textoBuscado}
          />
          {tamanioPagina > 1 && (
            <div className={styles.paginado}>
              <Pagination
                page={pagina}
                count={tamanioPagina}
                variant="outlined"
                onChange={(_, nuevaPagina) =>
                  handleBuscar(textoBuscado, nuevaPagina)
                }
              />
            </div>
          )}
        </div>
      );
    }
    // Si todavía no realizó ninguna búsqueda
    return <span>{TEXTO_LLENAR_INPUT}</span>;
  };

  return (
    <>
      <Card>
        {esSuperAgente && (
          <Button
            title={TEXTO_ESTADISTICAS_USUARIOS}
            type="primary"
            size="lg"
            callback={irAEstadisticas}
            className={styles.boton}
          />
        )}
        <TitleCard title={TEXTO_TITULO} />
        <div className={styles.contenedorInputBoton}>
          <TextField
            value={textoBuscado}
            name="busqueda"
            type="text"
            placeholder={TEXTO_PLACEHOLDER_BARRA_BUSQUEDA}
            className={styles.inputBusqueda}
            onChange={handleCambiaBusqueda}
            onKeyDown={e => (e.key === 'Enter' ? handleBuscarPushed() : '')}
            sx={sxBorrarBorde}
          />
          <Button
            title={TEXTO_BOTON_BUSCAR_CIUDADANOS}
            type="primary"
            size="lg"
            className={styles.boton}
            callback={handleBuscarPushed}
            disabled={
              textoBuscado.trim().replaceAll('.', '').replaceAll('-', '') === ''
            }
          />
          {puedeCrearCiudadano && (
            <Button
              title={TEXTO_BOTON_CREAR_CIUDADANO}
              type="primary"
              size="lg"
              callback={irACrear}
              className={styles.boton}
            />
          )}
        </div>
        {obtenerComponenteListaCiudadanos()}
      </Card>
      <Snackbar
        desplegar={snackBarInfo.mostrar}
        mensaje={snackBarInfo.texto}
        onClose={() => setSnackBarInfo({ mostrar: false, texto: '' })}
      />
    </>
  );
};

export default BuscadorCiudadanosContainer;
