import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  SERVER_ERROR_ROUTE,
  UNAVAILABLE_ROUTE,
  INDEX_ROUTE,
} from 'constants/RouterConstants';
import {
  TITLE_ERROR_404,
  DESCRIPTION_ERROR_404,
  TITLE_ERROR_SERVER,
  DESCRIPTION_ERROR_SERVER,
  ALT_SERVER_ERROR,
  ALT_ERROR_4O4,
  TEXTO_IR_INICIO,
  TEXTO_VOLVER,
} from 'constants/commonConstants';
import { showHeader, hideHeader } from 'actions/ui';
import ErrorPage from 'components/ErrorPage';

import IMAGE_ERROR_404 from 'assets/404.png';
import IMAGE_ERROR_SERVER from 'assets/internal-server-error.png';
import { useHistory } from 'react-router-dom';
import { rutaActualSelector } from 'store/selectors';

const PaginaErrorContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const rutaActual = useSelector(rutaActualSelector);

  useEffect(() => {
    dispatch(hideHeader());
    return () => {
      dispatch(showHeader());
    };
  }, []);

  const handleIrAIndex = () => {
    history.push(INDEX_ROUTE);
  };

  const es404 = rutaActual === UNAVAILABLE_ROUTE;
  const esServerError = rutaActual === SERVER_ERROR_ROUTE;

  const textoCallback = es404 || esServerError ? TEXTO_IR_INICIO : TEXTO_VOLVER;
  const callback = es404 || esServerError ? handleIrAIndex : history.goBack;

  return (
    <ErrorPage
      title={esServerError ? TITLE_ERROR_SERVER : TITLE_ERROR_404}
      description={
        esServerError ? DESCRIPTION_ERROR_SERVER : DESCRIPTION_ERROR_404
      }
      imageSrc={esServerError ? IMAGE_ERROR_SERVER : IMAGE_ERROR_404}
      buttonName={textoCallback}
      buttonCallback={callback}
      altImg={esServerError ? ALT_SERVER_ERROR : ALT_ERROR_4O4}
    />
  );
};

export default PaginaErrorContainer;
