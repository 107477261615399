import React from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import 'dayjs/locale/es';

import flechaDer from 'assets/vectorDer.png';
import flechaIzq from 'assets/vectorIzq.png';

import {
  TEXTO_AGENDA_INFO_SIN_AGENDA,
  TEXTO_HOY,
} from 'constants/commonConstants';

import styles from './styles.module.css';

const mayusculaPrimeraLetra = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

const AgendaVideollamadaFecha = ({
  fecha,
  setFecha,
  datosDiasSemana,
  setDatosDiasSemana,
}) => {
  const cambiarFecha = (cantidad, unidad) => {
    setDatosDiasSemana({});
    const fechaACambiar = dayjs(fecha).add(cantidad, unidad);
    const diaFechaACambiar = fechaACambiar.day();
    const fechaMiercolesDeLaSemana =
      diaFechaACambiar === 6 || diaFechaACambiar === 0
        ? fechaACambiar.endOf('week').subtract(3, 'day').format('YYYY-MM-DD')
        : fechaACambiar.format('YYYY-MM-DD');
    setFecha(fechaMiercolesDeLaSemana);
  };

  const handleAnteriorSemana = () => cambiarFecha(-1, 'week');
  const handleSiguienteSemana = () => cambiarFecha(1, 'week');
  const handleAnteriorMes = () => cambiarFecha(-1, 'month');
  const handleSiguienteMes = () => cambiarFecha(1, 'month');

  const obtenerFechasSemana = () => {
    const lunesSemana = dayjs(fecha).startOf('week').add(1, 'day');
    return Array.from({ length: 5 }, (_, index) =>
      lunesSemana.add(index, 'day'),
    );
  };

  const handleCambiarDia = dia => {
    setFecha(dia.format('YYYY-MM-DD'));
  };

  return (
    <div>
      <div className={styles.contenedorHoyMes}>
        <button
          type="button"
          className={styles.botonHoy}
          onClick={() => handleCambiarDia(dayjs())}
        >
          {TEXTO_HOY}
        </button>
        <div className={styles.contenedorMes}>
          <button
            className={styles.botonFlecha}
            type="button"
            onClick={handleAnteriorMes}
          >
            <img alt="izquierda" src={flechaIzq} />
          </button>
          <p className={styles.textoMes}>
            {mayusculaPrimeraLetra(
              dayjs(fecha).locale('es').format('MMMM YYYY'),
            )}
          </p>
          <button
            className={styles.botonFlecha}
            type="button"
            onClick={handleSiguienteMes}
          >
            <img alt="derecha" src={flechaDer} />
          </button>
        </div>
      </div>
      <div className={styles.contenedorFechas}>
        <button
          className={styles.botonFlecha}
          type="button"
          onClick={handleAnteriorSemana}
        >
          <img alt="izquierda" src={flechaIzq} />
        </button>
        <div className={styles.contenedorDias}>
          {obtenerFechasSemana().map(dia => {
            const diaKey = dia.format('YYYY-MM-DD');
            // FIXME: Hay que cambiar la variable para que en lugar de un array
            // sea un dict con {agendados, exitosos, rechazados}
            const textoMetadatos1 = datosDiasSemana[diaKey]?.[0] || '';
            const textoMetadatos2 = datosDiasSemana[diaKey]?.[1] || '';
            const textoMetadatos3 = datosDiasSemana[diaKey]?.[2] || '';
            return (
              <button
                type="button"
                key={dia.locale('es').format('dddd')}
                className={
                  dayjs(fecha).isSame(dia, 'date')
                    ? styles.boxDiaSeleccionado
                    : styles.boxDia
                }
                onClick={() => handleCambiarDia(dia)}
              >
                <span className={styles.textoDia}>
                  {mayusculaPrimeraLetra(dia.locale('es').format('dddd D/M'))}
                </span>
                <div className={styles.contenedorMetadatos}>
                  <span
                    className={
                      textoMetadatos1 === TEXTO_AGENDA_INFO_SIN_AGENDA
                        ? styles.textoMetadatosDisabled
                        : styles.textoMetadatos
                    }
                  >
                    {textoMetadatos1}
                  </span>
                  <span className={styles.textoMetadatos}>
                    {textoMetadatos2}
                  </span>
                  <span className={styles.textoMetadatos}>
                    {textoMetadatos3}
                  </span>
                </div>
              </button>
            );
          })}
        </div>
        <button
          className={styles.botonFlecha}
          type="button"
          onClick={handleSiguienteSemana}
        >
          <img alt="derecha" src={flechaDer} />
        </button>
      </div>
    </div>
  );
};

AgendaVideollamadaFecha.propTypes = {
  fecha: PropTypes.string,
  setFecha: PropTypes.func,
  datosDiasSemana: PropTypes.object,
  setDatosDiasSemana: PropTypes.func,
};

export default AgendaVideollamadaFecha;
