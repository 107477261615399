import React from 'react';
import PropTypes from 'prop-types';

// constantes
import {
  CONFIRMAR_DATOS_TEXT,
  TURNOS_VIDEOLLAMADA_TEXT,
  FECHAS_LABEL,
  HORARIO_LABEL,
  TURNOS_POR_DIA_LABEL,
  AGENTES_POR_DIA_LABEL,
  AVISO_CONFIRMAR_TEXT,
  obtenerFechasTexto,
  obtenerHorasTexto,
} from 'constants/commonConstants';

// componentes
import TitleCard from 'components/TitleCard';

// estilos
import styles from './CrearConfiguracionTurnoConfirmacion.module.css';

const CrearConfiguracionTurnoConfirmacion = ({
  fechaDesde,
  fechaHasta,
  horaDesde,
  horaHasta,
  cantidadAgentes,
  turnosPorDia,
}) => {
  const renderDatosTurnos = () => (
    <div className={styles.datosExtraTurnos}>
      <div className={styles.alinearIzquierdaSecundario}>
        <p>{TURNOS_POR_DIA_LABEL}</p>
        <p>{AGENTES_POR_DIA_LABEL}</p>
      </div>
      <div className={styles.alinearDerechaSecundario}>
        <p>{turnosPorDia}</p>
        <p>{cantidadAgentes}</p>
      </div>
    </div>
  );

  const fechasTexto = obtenerFechasTexto(fechaDesde, fechaHasta);

  const horasTexto = obtenerHorasTexto(
    horaDesde.format('HH:mm'),
    horaHasta.format('HH:mm'),
  );

  return (
    <div className={styles.container}>
      <TitleCard title={CONFIRMAR_DATOS_TEXT} className={styles.tituloCard} />
      <div className={styles.body}>
        <div className={styles.datosTurnos}>
          <TitleCard
            title={TURNOS_VIDEOLLAMADA_TEXT}
            className={styles.tituloCardSecundario}
          />
          <div className={styles.datosTurnosBody}>
            <div className={styles.alinearIzquierda}>
              <p>{FECHAS_LABEL}</p>
              <p>{HORARIO_LABEL}</p>
            </div>
            <div className={styles.alinearDerecha}>
              <p>{fechasTexto}</p>
              <p>{horasTexto}</p>
            </div>
          </div>
        </div>
        {renderDatosTurnos()}
        <div>
          <p className={styles.aclaracion}>{AVISO_CONFIRMAR_TEXT}</p>
        </div>
      </div>
    </div>
  );
};

CrearConfiguracionTurnoConfirmacion.propTypes = {
  fechaDesde: PropTypes.object,
  fechaHasta: PropTypes.object,
  horaDesde: PropTypes.object,
  horaHasta: PropTypes.object,
  cantidadAgentes: PropTypes.number,
  turnosPorDia: PropTypes.number,
};

export default CrearConfiguracionTurnoConfirmacion;
