import 'moment/locale/es';
import dayjs from 'dayjs';

export const obtenerNombreCompleto = ciudadano =>
  `${ciudadano.primer_nombre} ${
    ciudadano.segundo_nombre ? ciudadano.segundo_nombre : ''
  } ${ciudadano.primer_apellido}${
    ciudadano.segundo_apellido ? ` ${ciudadano.segundo_apellido}` : ''
  }`;

/*
Metodo para borrar el mensaje en el field que viene del backend.
formErrors: Del state, donde se guardan los errores de ese flujo.
handleResetFieldError: funcion para resetear los errores.
*/
export function resetFieldError(e) {
  if (this.props.formErrors) {
    if (this.props.formErrors.field === e.target.name) {
      this.handleResetFieldError();
    }
  }
}

export function resetFieldErrorFunctional(errors, e, handleResetFieldError) {
  if (errors) {
    if (errors.field === e.target.name) {
      handleResetFieldError();
    }
  }
}

export const formatearFechaCompleta = (date = dayjs(), defaultValue = '') => {
  if (date) {
    return dayjs(date).locale('es').format('DD/MM/YYYY, HH:mm [hs]');
  }
  return defaultValue;
};
