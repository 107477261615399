import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import styles from './styles.module.css';

const ModalBox = ({ abierto, onClose, cargando, titulo, texto, children }) => {
  const cerrarModal = () => {
    onClose();
  };

  if (abierto) {
    return (
      <ReactModal
        isOpen={abierto}
        contentLabel="Modal"
        className={styles['modal-id-uy']}
        overlayClassName={styles['modal-overlay-id-uy']}
        appElement={document.getElementById('root')}
        ariaHideApp={false}
      >
        <button
          className={styles['modal-close-button']}
          onClick={!cargando ? cerrarModal : null}
          type="button"
        >
          &times;
        </button>
        {titulo ? <h3>{titulo}</h3> : null}
        <p className={styles['modal-content']}>{texto}</p>
        {children}
      </ReactModal>
    );
  }
  return null;
};

ModalBox.propTypes = {
  abierto: PropTypes.bool,
  onClose: PropTypes.func,
  cargando: PropTypes.bool,
  titulo: PropTypes.string,
  texto: PropTypes.node,
  children: PropTypes.node,
};

export default ModalBox;
