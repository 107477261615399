import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Actions
import { fetchCiudadano } from 'actions/ciudadano';
import { showHeader, hideHeader } from 'actions/ui';

// Services
import {
  obtenerInfoDnic,
  actualizarDatosPersonalesCiudadano,
  actualizarEmailCiudadano,
  eliminarCiudadano,
  reenviarEmailActivacion,
  reenviarEmailDesbloqueo,
  verificarCiudadano,
  obtenerAccionesCiudadano,
  obtenerCedulaDnic,
} from 'services/apiServices';

// Selectors
import { cargandoCiudadanoSelector, ciudadanoSelector } from 'store/selectors';

// utils
import { obtenerNombreCompleto } from 'utils/utils';

// constantes
import {
  TEXTO_ERROR_CONSULTAR_DNIC,
  CAMBIO_PRESENCIAL_ERROR,
  CIUDADANO_ELIMINADO_ERROR,
  CIUDADANO_ELIMINADO,
  VERIFICAR_EMAIL_SUCCESS,
  ENVIAR_EMAIL_ERROR,
  VERIFICAR_CIUDADANO_EXITO,
  MODIFICAR_CAMBIOS_PERSONALES_SUCCESS,
  VERIFICAR_CIUDADANO_ERROR,
  DEFAULT_SNACKBAR_INFO,
  CAMBIO_EMAIL_ERROR,
  OBTENER_ACCIONES_CIUDADANO_ERROR,
  TEXTO_TABLA_NO_HAY_REGISTROS,
} from 'constants/commonConstants';
import { SEARCH_ROUTE } from 'constants/RouterConstants';

// Componentes
import CardSpinner from 'components/CardSpinner';
import Perfil from 'components/Perfil';
import Snackbar from 'components/Snackbar';

const PerfilContainer = () => {
  const [snackBarInfo, setSnackBarInfo] = useState(DEFAULT_SNACKBAR_INFO);

  // obtener ciudadano
  const cargandoCiudadano = useSelector(cargandoCiudadanoSelector);
  const ciudadano = useSelector(ciudadanoSelector);

  const { id } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  // --------------------------------------------------------------------------
  // Snackbar
  // --------------------------------------------------------------------------
  const handleMostrarSnackbar = texto => {
    setSnackBarInfo({ mostrar: true, texto });
  };

  const handleOcultarSnackbar = () => {
    setSnackBarInfo(DEFAULT_SNACKBAR_INFO);
  };

  // --------------------------------------------------------------------------
  // Métodos
  // --------------------------------------------------------------------------

  const handleFetchCiudadano = () => {
    dispatch(fetchCiudadano(id));
  };

  const handleRedirigir = ruta => {
    history.push(ruta);
  };

  const handleObtenerDatosDNIC = async () => {
    try {
      const respuesta = await obtenerInfoDnic(ciudadano?.numero_documento);
      return respuesta;
    } catch {
      handleMostrarSnackbar(TEXTO_ERROR_CONSULTAR_DNIC);
    }
    return null;
  };

  const handleObtenerCedula = async nroSerie => {
    try {
      const respuesta = await obtenerCedulaDnic(
        ciudadano?.numero_documento,
        nroSerie,
      );
      return respuesta;
    } catch {
      // Mismo error que consulta DNIC general.
      handleMostrarSnackbar(TEXTO_ERROR_CONSULTAR_DNIC);
    }
    return null;
  };

  const handleActualizarDatosPersonales = async datos => {
    try {
      await actualizarDatosPersonalesCiudadano(ciudadano.id, datos);
      handleFetchCiudadano();
      handleMostrarSnackbar(MODIFICAR_CAMBIOS_PERSONALES_SUCCESS);
    } catch {
      handleMostrarSnackbar(CAMBIO_PRESENCIAL_ERROR);
    }
  };

  const handleActualizarEmailCiudadano = async datos => {
    try {
      await actualizarEmailCiudadano(ciudadano.id, datos);
      handleFetchCiudadano();
      handleMostrarSnackbar(MODIFICAR_CAMBIOS_PERSONALES_SUCCESS);
    } catch (response) {
      if (
        response?.data?.error.includes('ya está en uso.') &&
        response?.data?.field === 'email'
      ) {
        handleMostrarSnackbar(CAMBIO_EMAIL_ERROR);
      } else {
        handleMostrarSnackbar(CAMBIO_PRESENCIAL_ERROR);
      }
    }
  };

  const handleEliminarCiudadano = async () => {
    let texto = '';
    try {
      await eliminarCiudadano(ciudadano.id);
      texto = CIUDADANO_ELIMINADO(obtenerNombreCompleto(ciudadano));
    } catch {
      texto = CIUDADANO_ELIMINADO_ERROR(obtenerNombreCompleto(ciudadano));
    } finally {
      handleMostrarSnackbar(texto);
      handleRedirigir(`${SEARCH_ROUTE}?msg=${texto}`);
    }
  };

  const handleReenviarEmail = async () => {
    try {
      if (ciudadano.bloqueado && ciudadano.email_validado) {
        await reenviarEmailDesbloqueo(ciudadano.id);
      } else {
        await reenviarEmailActivacion(ciudadano.id);
      }
      handleMostrarSnackbar(VERIFICAR_EMAIL_SUCCESS);
    } catch {
      handleMostrarSnackbar(ENVIAR_EMAIL_ERROR);
    }
  };

  const handleVerificarCiudadano = async () => {
    try {
      await verificarCiudadano(ciudadano.id);
      handleMostrarSnackbar(VERIFICAR_CIUDADANO_EXITO);
      handleFetchCiudadano();
    } catch {
      handleMostrarSnackbar(VERIFICAR_CIUDADANO_ERROR);
    }
  };

  const handleObtenerUltimasAccionesCiudadano = async () => {
    try {
      const response = await obtenerAccionesCiudadano(ciudadano.id);
      if (response.data.length === 0) {
        handleMostrarSnackbar(TEXTO_TABLA_NO_HAY_REGISTROS);
      }
      return response.data;
    } catch {
      handleMostrarSnackbar(OBTENER_ACCIONES_CIUDADANO_ERROR);
      return [];
    }
  };

  // --------------------------------------------------------------------------
  // useEffect
  // --------------------------------------------------------------------------
  useEffect(() => {
    dispatch(showHeader());
    return () => {
      dispatch(hideHeader());
    };
  }, []);

  useEffect(() => {
    handleFetchCiudadano();
  }, [id]);

  // --------------------------------------------------------------------------
  // Render
  // --------------------------------------------------------------------------
  if (isEmpty(ciudadano)) {
    return <CardSpinner spin />;
  }
  return (
    <>
      <Perfil
        ciudadano={ciudadano}
        cargandoCiudadano={cargandoCiudadano}
        handleObtenerDatosDNIC={handleObtenerDatosDNIC}
        handleObtenerCedula={handleObtenerCedula}
        handleActualizarDatosPersonales={handleActualizarDatosPersonales}
        handleActualizarEmailCiudadano={handleActualizarEmailCiudadano}
        handleEliminarCiudadano={handleEliminarCiudadano}
        handleReenviarEmail={handleReenviarEmail}
        handleVerificarCiudadano={handleVerificarCiudadano}
        handleObtenerUltimasAccionesCiudadano={
          handleObtenerUltimasAccionesCiudadano
        }
      />
      <Snackbar
        desplegar={snackBarInfo.mostrar}
        mensaje={snackBarInfo.texto}
        onClose={handleOcultarSnackbar}
      />
    </>
  );
};

export default PerfilContainer;
