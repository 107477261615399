import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  obtenerMensajeErrorLargoMaximo,
  obtenerMensajeErrorLargoMinimo,
  REQUERIDO,
  NUMERO_POSITIVO_ERROR,
} from 'constants/commonConstants';

import styles from './styles.module.css';
import ErrorHelpText from './ErrorHelpText';

export const TextField = ({
  register,
  name,
  small,
  type,
  label,
  required,
  error,
  placeholder,
  minLength,
  maxLength,
  validationRegex,
  regexErrorMessage,
  validationErrorMessage,
  upper,
  trigger,
  touched,
  value,
  matchValue,
  showSuccess,
  validateDocument,
}) => {
  const clase = small ? 'form-field__input--small' : 'form-field__input';
  const [claseInput, setClaseInput] = useState(clase);
  const [claseLabel, setClaseLabel] = useState('form-field__label');
  const [validationError, setValidationError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validateMatch = valueToMatch => valueToMatch === matchValue;

  const getValidationFunction = () => {
    switch (name) {
      case 'repetirEmail':
        return validateMatch;
      case 'numeroDocumento':
        return validateDocument;
      default:
        return null;
    }
  };

  const validations = {
    required,
    minLength,
    maxLength,
    pattern: validationRegex,
    min: type === 'number' ? 0 : null,
    validate: getValidationFunction(),
  };

  useEffect(() => {
    if (error) {
      setValidationError(true);
      setClaseLabel(`form-field__label${small ? '--small' : ''}--error`);
      setClaseInput(`form-field__input${small ? '--small' : ''}--error`);
      switch (error.type) {
        case 'required':
          setErrorMessage(REQUERIDO);
          break;
        case 'minLength':
          setErrorMessage(obtenerMensajeErrorLargoMinimo(minLength));
          break;
        case 'maxLength':
          setErrorMessage(obtenerMensajeErrorLargoMaximo(maxLength));
          break;
        case 'pattern':
          setErrorMessage(regexErrorMessage);
          break;
        case 'validate':
          setErrorMessage(validationErrorMessage);
          break;
        case 'min':
          setErrorMessage(NUMERO_POSITIVO_ERROR);
          break;
        case 'custom':
          setErrorMessage(error.message);
          break;
        default:
      }
    } else {
      setSubmitError(false);
      setClaseLabel(`form-field__label`);
      setValidationError(false);
      // eslint-disable-next-line no-shadow
      let claseInput = 'form-field__input';
      // los estilos de small y exito son disjuntos
      if (small) claseInput += '--small';
      if (showSuccess) claseInput += '--exito';
      setClaseInput(claseInput);
    }
  }, [error, showSuccess]);

  useEffect(() => {
    if (touched && name === 'email') trigger('repetirEmail');
  }, [value]);

  return (
    <div className={styles['form-field']}>
      <span>
        <label className={styles[claseLabel]} htmlFor={name}>
          {label}
        </label>
        {!required && (
          <span className={styles['form-field__label--required']}>
            {' '}
            (Opcional)
          </span>
        )}
      </span>
      <span>
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register(name, validations)}
          className={styles[claseInput]}
          type={type}
          placeholder={placeholder}
          id={name}
          style={upper && { textTransform: 'uppercase' }}
        />
      </span>

      <ErrorHelpText
        error={validationError || submitError}
        msg={errorMessage}
        small={small}
      />
    </div>
  );
};

TextField.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  errorMessage: PropTypes.object,
  validationRegex: PropTypes.oneOfType([
    PropTypes.shape(RegExp),
    PropTypes.bool,
  ]),
  regexErrorMessage: PropTypes.string,
  validationErrorMessage: PropTypes.string,
  upper: PropTypes.bool,
  trigger: PropTypes.func,
  touched: PropTypes.bool,
  value: PropTypes.string,
  matchValue: PropTypes.string,
  showSuccess: PropTypes.bool,
  validateDocument: PropTypes.func,
};

export default TextField;
