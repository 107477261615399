import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import './styles.css';

const Button = props => {
  const type = props.type ? `btn-${props.type}` : '';
  const size = props.size ? `btn-${props.size}` : '';
  const side = props.side ? `btn-${props.side}` : '';
  const full = props.fullSize ? 'full-size' : '';
  const isLast = props.isLast ? 'is-last' : '';
  const isFirst = props.isFirst ? 'is-first' : '';
  const id = props.id ? props.id : null;
  const className = props.className || '';

  if (props.isLoading) {
    return (
      // eslint-disable-next-line react/button-has-type
      <button
        id={id}
        style={props.style}
        disabled={props.disabled}
        className={`btn ${type} ${size} ${side} ${full} ${isLast} ${isFirst} ${className}`}
      >
        <Spinner
          data-testid="spinner"
          name="ball-beat"
          fadeIn="none"
          color="white"
        />
      </button>
    );
  }
  return (
    <button
      id={id}
      type={props.isSubmit ? 'submit' : 'button'}
      style={props.style}
      className={`btn ${type} ${size} ${side} ${full} ${isLast} ${isFirst} ${className}`}
      disabled={props.disabled}
      tabIndex={props.tabIndex || 0}
      onClick={props.callback}
    >
      <span id={id ? `${id}-span` : null}>{props.title}</span>
      {props.logo ? (
        <img
          src={props.logo}
          srcSet={`${props.logo2x} 2x, ${props.logo3x} 3x`}
          alt="Logo del botón"
        />
      ) : null}
    </button>
  );
};

Button.propTypes = {
  logo: PropTypes.string,
  logo2x: PropTypes.string,
  logo3x: PropTypes.string,
  callback: PropTypes.func,
  tabIndex: PropTypes.number,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  side: PropTypes.string,
  fullSize: PropTypes.bool,
  isLast: PropTypes.bool,
  isFirst: PropTypes.bool,
  isSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string,
};

export default Button;
