import { FETCH_AGENTE } from 'constants/ActionTypes';

export const INITIAL_STATE = {
  currentAgente: null,
  currentAgenteApellido: null,
  currentAgenteAgenda: null,
  currentAgentePermisos: null,
  currentAgenteOrganismo: null,
  loading: false,
};

// eslint-disable-next-line default-param-last
export default function agenteReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // -----------------------------------------------------------------------
    // FETCH_MIS_DATOS
    // -----------------------------------------------------------------------
    case FETCH_AGENTE.SUCCESS:
      return {
        ...state,
        currentAgenteNombre: action?.payload?.data?.primer_nombre,
        currentAgenteApellido: action?.payload?.data?.primer_apellido,
        currentAgenteTramiteEnCurso: action?.payload?.data?.tramite_en_curso,
        currentAgentePermisos: action?.payload?.data?.user.permisos,
        currentAgenteOrganismo: action?.payload?.data?.organismo,
        loading: false,
      };

    case FETCH_AGENTE.ERROR:
      return {
        ...state,
        currentAgenteNombre: null,
        currentAgenteApellido: null,
        currentAgentePermisos: null,
        currentAgenteOrganismo: null,
        loading: false,
      };

    case FETCH_AGENTE.PENDING:
      return {
        ...state,
        loading: true,
      };

    // DEFAULT
    default:
      return state;
  }
}
