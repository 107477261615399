import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

// actions
import { hideHeader, showHeader } from 'actions/ui';
import { fetchPaises } from 'actions/ciudadano';

// api services
import { crearCiudadano } from 'services/apiServices';

// selectors
import { cargandoPaisesSelector, paisesSelector } from 'store/selectors';

// constantes
import { PROFILE_ROUTE } from 'constants/RouterConstants';
import { NO_TIENE_PERMISOS_DE_ESCRITURA } from 'constants/commonConstants';

// componentes
import CardSpinner from 'components/CardSpinner';
import Snackbar from 'components/Snackbar';
import CrearPerfil from 'components/CrearPerfil';

const CrearPerfilContainer = () => {
  const [snackBarInfo, setSnackBarInfo] = useState({
    mostrarSnackBar: false,
    snackBarText: null,
  });
  const [cargandoCrear, setCargandoCrear] = useState(false);
  const [erroresForm, setErroresForm] = useState(null);

  const paises = useSelector(paisesSelector);
  const cargandoPaises = useSelector(cargandoPaisesSelector);

  const dispatch = useDispatch();
  const history = useHistory();
  const prefillValues = queryString.parse(window.location.search);

  useEffect(() => {
    dispatch(showHeader());
    if (paises.length === 0) {
      dispatch(fetchPaises());
    }
    return () => {
      dispatch(hideHeader());
    };
  }, []);

  const crearPerfil = async valores => {
    setCargandoCrear(true);
    try {
      const response = await crearCiudadano(valores);
      setCargandoCrear(false);
      history.push(`${PROFILE_ROUTE}/${response.data.id}`);
    } catch (response) {
      setCargandoCrear(false);
      setErroresForm(response.data);
      if (response.status === '403')
        setSnackBarInfo({
          mostrarSnackBar: true,
          snackBarText: NO_TIENE_PERMISOS_DE_ESCRITURA,
        });
    }
  };

  if (cargandoPaises) {
    return <CardSpinner spin />;
  }

  return (
    <>
      <CrearPerfil
        paises={paises}
        crearPerfil={crearPerfil}
        erroresCrearPerfil={erroresForm}
        creandoPerfil={cargandoCrear}
        prefillValues={prefillValues}
      />
      <Snackbar
        desplegar={snackBarInfo.mostrarSnackBar}
        mensaje={snackBarInfo.snackBarText}
        onClose={() =>
          setSnackBarInfo({
            ...snackBarInfo,
            mostrarSnackBar: false,
          })
        }
      />
    </>
  );
};

export default CrearPerfilContainer;
