import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Auth from 'utils/Auth';
import * as ROUTES from 'constants/RouterConstants';
import CardSpinner from 'components/CardSpinner';
import { NO_TIENE_PERMISOS_DE_AGENTE } from 'constants/commonConstants';
import { useSelector } from 'react-redux';
import { cargandoUsuarioLogueado } from 'store/selectors';
import usePermissions from 'hooks/usePermissions';

const WithPermissions = ({ children, permisos }) => {
  const cargando = useSelector(cargandoUsuarioLogueado);

  const hasPermissions = usePermissions(permisos);

  if (cargando) {
    return <CardSpinner spin className="spinner__transparent" />;
  }

  if (!hasPermissions) {
    return <Redirect to={ROUTES.UNAVAILABLE_ROUTE} />;
  }

  return children;
};

WithPermissions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  permisos: PropTypes.arrayOf(PropTypes.node),
};

const agenteLogueado = (WrappedComponent, permisos) => {
  const ComponenteVerificarAgenteAutenticado = props => {
    const [triStateAuthFlag, setTriStateAuthFlag] = useState(null);

    const checkAuth = () => {
      if (Auth.getInstance().isAuthenticated()) {
        setTriStateAuthFlag(true);
      } else {
        Auth.getInstance().cleanStorage();
        Auth.getInstance()
          .obtainToken()
          .then(() => {
            setTriStateAuthFlag(Auth.getInstance().isAuthenticated());
          })
          .catch(() => {
            setTriStateAuthFlag(false);
          });
      }
    };

    useEffect(() => {
      checkAuth();
    }, []);

    if (triStateAuthFlag === null) {
      return <CardSpinner spin className="spinner__transparent" />;
    }
    if (triStateAuthFlag) {
      if (
        Auth.getInstance().isAgente() &&
        Auth.getInstance().agentHasVerified()
      ) {
        return (
          <WithPermissions permisos={permisos || []}>
            <WrappedComponent {...props} />
          </WithPermissions>
        );
      }
      return (
        <Redirect
          to={`${ROUTES.INDEX_ROUTE}?msg=${NO_TIENE_PERMISOS_DE_AGENTE}`}
        />
      );
    }
    window.location.replace(`${ROUTES.LOGIN_ROUTE}?agnt=1`);
    return null;
  };

  return ComponenteVerificarAgenteAutenticado;
};

export default agenteLogueado;
