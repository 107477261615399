import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import ModalBox from 'components/Modal';
import Button from '../Button';

import styles from './styles.module.css';

const ModalConfirmarAccionConInput = ({
  textoModal,
  abierto,
  onClose,
  cargando,
  textoBoton,
  textoLabelInput,
  handleBoton,
  nombreCampo,
  textoAComparar,
}) => {
  const { register, handleSubmit, watch } = useForm({
    mode: 'onChange',
    defaultValues: { [nombreCampo]: '' },
  });

  const watchCampo = watch(nombreCampo);

  return (
    <ModalBox
      texto={textoModal}
      abierto={abierto}
      onClose={onClose}
      cargando={cargando}
    >
      <form
        className={styles.modalConInput}
        onSubmit={handleSubmit(() => {
          handleBoton();
        })}
      >
        <label htmlFor={nombreCampo}>{textoLabelInput}</label>
        <input
          id={nombreCampo}
          {...register(nombreCampo)}
          value={watchCampo}
          type="text"
        />

        <Button
          title={textoBoton}
          fullSize
          type="primary"
          size="lg"
          disabled={
            cargando ||
            (textoAComparar.length > 0 &&
              watchCampo.toLowerCase() !== textoAComparar.toLowerCase())
          }
          isLoading={cargando}
          isSubmit
        />
      </form>
    </ModalBox>
  );
};

ModalConfirmarAccionConInput.propTypes = {
  textoModal: PropTypes.node,
  abierto: PropTypes.bool,
  onClose: PropTypes.func,
  cargando: PropTypes.bool,
  textoBoton: PropTypes.string,
  textoLabelInput: PropTypes.string,
  handleBoton: PropTypes.func,
  nombreCampo: PropTypes.string,
  textoAComparar: PropTypes.string,
};

export default ModalConfirmarAccionConInput;
