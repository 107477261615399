import {
  AGENDA_TURNOS,
  AGENDA_TURNOS_CONSULTA,
  TIPO_TURNO_CONSULTA,
  TIPO_TURNO_VALIDACION,
} from 'constants/commonConstants';
import {
  CONFIGURACION_TURNOS_CONSULTA,
  CREAR_CONFIGURACION_TURNO,
  CREAR_CONFIGURACION_TURNOS_CONSULTA,
  CONFIGURACION_TURNOS,
} from 'constants/RouterConstants';
import {
  CONFIGURACION_TURNO_BASE_URL,
  CONFIGURACION_TURNO_CONSULTA_BASE_URL,
  ELIMINAR_CONFIGURACIONES_CONSULTA_URL,
  ELIMINAR_CONFIGURACIONES_URL,
  FETCH_CONFIGURACION_TURNO_CONSULTA_LISTAR_URL,
  FETCH_CONFIGURACION_TURNO_LISTAR_URL,
  FETCH_AGENTE_AGENDA_URL,
  FETCH_AGENTE_AGENDA_CONSULTA_URL,
} from 'constants/ApiConstants';

export const tipoTurnoFromPath = pathName => {
  const soporteIdentifiers = [
    'configuracion-turnos-consulta',
    'agenda-videollamada-soporte',
  ];
  const contieneSoporte = soporteIdentifiers.some(identifier =>
    pathName.includes(identifier),
  );

  return contieneSoporte ? TIPO_TURNO_CONSULTA : TIPO_TURNO_VALIDACION;
};

export const tipoTurnos = Object.freeze({
  [TIPO_TURNO_CONSULTA]: {
    agendasTitulo: AGENDA_TURNOS_CONSULTA,
    configuracionTurnosUrl: CONFIGURACION_TURNOS_CONSULTA,
    crearConfiguracionTurnoUrl: CONFIGURACION_TURNO_CONSULTA_BASE_URL,
    fetchTurnosUrl: FETCH_CONFIGURACION_TURNO_CONSULTA_LISTAR_URL,
    crearConfiguracionTurnoPath: CREAR_CONFIGURACION_TURNOS_CONSULTA,
    eliminarConfiguracionTurnoDiaUrl: ELIMINAR_CONFIGURACIONES_CONSULTA_URL,
    obtenerAgendaVideollamadaUrl: FETCH_AGENTE_AGENDA_CONSULTA_URL,
  },
  [TIPO_TURNO_VALIDACION]: {
    agendasTitulo: AGENDA_TURNOS,
    configuracionTurnosUrl: CONFIGURACION_TURNOS,
    crearConfiguracionTurnoUrl: CONFIGURACION_TURNO_BASE_URL,
    fetchTurnosUrl: FETCH_CONFIGURACION_TURNO_LISTAR_URL,
    crearConfiguracionTurnoPath: CREAR_CONFIGURACION_TURNO,
    eliminarConfiguracionTurnoDiaUrl: ELIMINAR_CONFIGURACIONES_URL,
    obtenerAgendaVideollamadaUrl: FETCH_AGENTE_AGENDA_URL,
  },
});
