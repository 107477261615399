import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

// react-phone-input
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json';

// constants
import {
  MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO,
  MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO_ARIA_LABEL,
  MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO,
  MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO_ARIA_LABEL,
  MODIFICAR_NUMERO_TELEFONO_DESCRIPCION,
  MODIFICAR_NUMERO_TELEFONO_EXITO_VOLVER,
  MODIFICAR_NUMERO_TELEFONO_TEXTO_ERROR,
  MODIFICAR_NUMERO_TELEFONO_TEXTO_EXITO,
  MODIFICAR_NUMERO_TELEFONO_TEXTO_INFORMATIVO,
  MODIFICAR_NUMERO_TELEFONO_TITULO,
  TEXTO_NUMERO_TELEFONO_AVISO,
  TEXTO_PLACEHOLDER_NUMERO_TELEFONO,
} from 'constants/commonConstants';
import { SEARCH_ROUTE } from 'constants/RouterConstants';

import { showHeader } from 'actions/ui';

// actions
import { fetchCiudadano } from 'actions/ciudadano';

// selectors
import { ciudadanoSelector } from 'store/selectors';

// api services
import { actualizarTelefonoCiudadano } from 'services/apiServices';

// componentes
import CardSmall from 'components/CardSmall';
import ButtonSolid from 'components/ButtonSolid';
import CardSuccess from 'components/CardSuccess';

// styles
import styles from './CambioNumeroTelefonoContainer.module.css';

const CambioNumeroTelefonoContainer = () => {
  const ciudadano = useSelector(ciudadanoSelector);

  const history = useHistory();
  const dispatch = useDispatch();

  const [cargando, setCargando] = useState(false);
  const [mostrarError, setMostrarError] = useState(false);

  const [pantallaExito, setPantallaExito] = useState(false);

  const { watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      numero_telefono: `+${ciudadano?.numero_telefono}`,
    },
    mode: 'onChange',
  });

  const numeroTelefono = watch('numero_telefono');

  useEffect(() => {
    dispatch(showHeader());
  }, []);

  const handleFetchCiudadano = () => {
    dispatch(fetchCiudadano(ciudadano.id));
  };

  const handleBotonPrincipal = async datos => {
    setCargando(true);
    try {
      await actualizarTelefonoCiudadano(ciudadano.id, datos);
      handleFetchCiudadano();
      setPantallaExito(true);
    } catch (response) {
      setMostrarError(true);
    } finally {
      setCargando(false);
    }
  };

  const handleOnFocusNumeroTelefono = valor => {
    if (isEmpty(numeroTelefono) && valor.key !== 'Backspace') {
      setValue('numero_telefono', '+598');
    }
  };

  const handleOnBlurNumeroTelefono = () => {
    if (numeroTelefono.length <= 4) {
      setValue('numero_telefono', '');
    }
  };

  if (!pantallaExito) {
    return (
      <CardSmall
        title={MODIFICAR_NUMERO_TELEFONO_TITULO}
        desc={MODIFICAR_NUMERO_TELEFONO_DESCRIPCION}
      >
        <form onSubmit={handleSubmit(handleBotonPrincipal)}>
          <div className={styles.contenedorNumeroTelefono}>
            <PhoneInput
              placeholder={TEXTO_PLACEHOLDER_NUMERO_TELEFONO}
              inputClass={styles.inputNumeroTelefono}
              country="uy"
              value={numeroTelefono}
              onChange={valor => setValue('numero_telefono', valor)}
              localization={es}
              enableLongNumbers
              disableCountryCode={isEmpty(numeroTelefono)}
              onFocus={handleOnFocusNumeroTelefono}
              onBlur={handleOnBlurNumeroTelefono}
            />
            <p className={styles.help_text}>{TEXTO_NUMERO_TELEFONO_AVISO}</p>
          </div>
          <div className={styles.contenedorBotones}>
            <ButtonSolid
              text={MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO}
              ariaLabel={MODIFICAR_NUMERO_TELEFONO_BOTON_SECUNDARIO_ARIA_LABEL}
              handleClick={() => history.goBack()}
              className={styles.botonSecundario}
            />
            <ButtonSolid
              text={MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO}
              ariaLabel={MODIFICAR_NUMERO_TELEFONO_BOTON_PRIMARIO_ARIA_LABEL}
              isLoading={cargando}
              isSubmit
              isDisabled={numeroTelefono?.length <= 4}
            />
          </div>
          {mostrarError && (
            <p className={styles.textoError}>
              {MODIFICAR_NUMERO_TELEFONO_TEXTO_ERROR}
            </p>
          )}
        </form>
      </CardSmall>
    );
  }
  return (
    <CardSuccess
      title={MODIFICAR_NUMERO_TELEFONO_TEXTO_EXITO}
      description={MODIFICAR_NUMERO_TELEFONO_TEXTO_INFORMATIVO}
      returnUrl={SEARCH_ROUTE}
      headerText={MODIFICAR_NUMERO_TELEFONO_EXITO_VOLVER}
    />
  );
};

export default CambioNumeroTelefonoContainer;
