import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  SHOW_HEADER,
  HIDE_HEADER,
} from 'constants/ActionTypes';

const INITIAL_STATE = {
  snackbar: {
    text: '',
    show: false,
    duration: 5000,
  },
  showHeader: true,
};

// eslint-disable-next-line default-param-last
export default function uiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: { ...state.snackbar, show: true, text: action.payload.text },
      };

    case HIDE_SNACKBAR:
      return {
        ...state,
        snackbar: { ...state.snackbar, show: false, text: '' },
      };

    case SHOW_HEADER:
      return {
        ...state,
        showHeader: true,
      };

    case HIDE_HEADER:
      return {
        ...state,
        showHeader: false,
      };

    // DEFAULT
    default:
      return state;
  }
}
