import React from 'react';
import PropTypes from 'prop-types';

import { JitsiMeeting } from '@jitsi/react-sdk';

import Card from 'components/Card';
// Styles
import { NOMBRE_AGENTE } from 'constants/commonConstants';
import styles from '../Videollamada/Container/index.module.css';

export const Videollamada = ({ videollamada, nombreAgente }) => (
  <Card className={styles.contenedorVideollamada}>
    <JitsiMeeting
      domain={window.REACT_APP_DOMINIO_VIDEOLLAMADA}
      roomName={videollamada.link}
      getIFrameRef={n => {
        n.classList.add(styles.iframeVideollamada);
      }}
      configOverwrite={{ defaultLanguage: 'es' }}
      userInfo={{ displayName: `${NOMBRE_AGENTE} ${nombreAgente}` }}
    />
  </Card>
);

Videollamada.propTypes = {
  videollamada: PropTypes.object,
  nombreAgente: PropTypes.string,
};
