import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { Redirect, useHistory } from 'react-router-dom';

// actions
import { hideHeader } from 'actions/ui';

// utils
import Auth from 'utils/Auth';

// router constants
import {
  LOGIN_ROUTE,
  LOGOUT_PROCESS,
  SEARCH_ROUTE,
} from 'constants/RouterConstants';

// componentes
import CardSpinner from 'components/CardSpinner';
import Inicio from 'components/Inicio';
import Snackbar from 'components/Snackbar';

const InicioContainer = () => {
  const [triStateAuthFlag, setTriStateAuthFlag] = useState(null);
  const [snackBarInfo, setSnackBarInfo] = useState({
    mostrar: false,
    texto: '',
  });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(hideHeader());
    // Parsea y muestra mensaje del backend (si existe)
    const parsed = queryString.parse(history.location.search);
    const msg = parsed.msg ? parsed.msg : null;
    if (msg) {
      setSnackBarInfo({ mostrar: true, texto: msg });
    }

    // Se maneja este caso particular de manera similar al HOC de autenticación
    // No se implementó otro HOC para pantallas solo opt-out porque no corresponde.
    if (Auth.getInstance().isAuthenticated()) {
      setTriStateAuthFlag(true);
    } else {
      Auth.getInstance().cleanStorage();
      Auth.getInstance()
        .obtainToken()
        .then(() => {
          setTriStateAuthFlag(Auth.getInstance().isAuthenticated());
        })
        .catch(() => {
          setTriStateAuthFlag(false);
        });
    }
  }, []);

  const handleIrAlLogin = () => {
    window.location.replace(`${LOGIN_ROUTE}?agnt=1`);
  };

  const handleIrAlLogout = () => {
    history.push(LOGOUT_PROCESS);
  };

  if (triStateAuthFlag === null) {
    return <CardSpinner spin className="spinner__transparent" />;
  }
  if (
    triStateAuthFlag &&
    Auth.getInstance().isAgente() &&
    Auth.getInstance().agentHasVerified()
  ) {
    return <Redirect to={SEARCH_ROUTE} />;
  }
  return (
    <>
      <Inicio goToLogin={handleIrAlLogin} goToLogout={handleIrAlLogout} />
      <Snackbar
        desplegar={snackBarInfo.mostrar}
        mensaje={snackBarInfo.texto}
        onClose={() => setSnackBarInfo({ mostrar: false, texto: '' })}
      />
    </>
  );
};

export default InicioContainer;
