import React, { useState, useEffect } from 'react';

// api services
import { obtenerEstadisticasDeCiudadanosConEmailDuplicado } from 'services/apiServices';

// componentes
import ShortCard from 'components/ShortCard/ShortCard';
import Spinner from 'react-spinkit';

import 'components/ShortCard/styles.css';
import styles from './styles.module.css';

const EstadisticasUsuariosConEmailDuplicado = () => {
  const [
    cargandoEstadisticasDeCiudadanosConEmailDuplicado,
    setCargandoEstadisticasDeCiudadanosConEmailDuplicado,
  ] = useState(true);
  const [ciudadanosConEmailDuplicado, setCiudadanosConEmailDuplicado] =
    useState();

  useEffect(() => {
    const cargarDatosDeCiudadanosConEmailDuplicado = async () => {
      const response = await obtenerEstadisticasDeCiudadanosConEmailDuplicado();
      if (response.data) setCiudadanosConEmailDuplicado(response.data);
      setCargandoEstadisticasDeCiudadanosConEmailDuplicado(false);
    };
    cargarDatosDeCiudadanosConEmailDuplicado();
  }, []);

  return (
    <div>
      {cargandoEstadisticasDeCiudadanosConEmailDuplicado ? (
        <div className={styles.center}>
          <Spinner
            className="spin-class"
            name="ball-spin-fade-loader"
            fadeIn="none"
          />
        </div>
      ) : (
        <div>
          {ciudadanosConEmailDuplicado &&
          Object.keys(ciudadanosConEmailDuplicado).length ? (
            <div>
              <p>Cantidad de usuarios con email duplicado:</p>
              <div className="container">
                <ShortCard
                  titulo="Usuarios con email duplicado"
                  valor={ciudadanosConEmailDuplicado.total}
                />
              </div>
            </div>
          ) : (
            <p>
              Todavia no hay datos de usuarios con email duplicado disponibles
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default EstadisticasUsuariosConEmailDuplicado;
