/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-spinkit';

import { hideHeader } from 'actions/ui';
import { fetchAgente } from 'actions/agente';
import {
  fetchVideollamada,
  fetchCedula,
  validarPersona,
  rechazarTramite,
  desvincularAgenteTramite,
  estadoTramite,
} from 'services/apiServices';
import {
  ERROR_VERIFICACION,
  ERROR_TOMAR_CAPTURA,
  ERROR_NUMERO_SERIE,
  ERROR_PRIMER_APELLIDO,
  ERROR_OBTENER_CEDULA,
  NOMBRE_AGENTE,
  OBTENER_NIVEL_INTERMEDIO,
  NOMBRE_COMPLETO_LABEL,
  TIPO_DOCUMENTO_LABEL,
  NUMERO_DOCUMENTO_LABEL,
  PAIS_EMISOR_LABEL,
  CORREO_ELECTRONICO_LABEL,
  NIVEL_LABEL,
  TERMINAR_VIDEOLLAMADA_TEXT,
  DESVINCULARSE_VIDEOLLAMADA_TEXT,
  CONFIRMAR_TERMINAR_VIDEOLLAMADA_TEXT,
  VOLVER_A_VIDEOLLAMADA,
  VOLVER_BOTON_TEXT,
  PENDIENTE_AGENTE,
  ERROR,
  RECHAZADO_VERIFICACION,
  INICIANDO_PROCESO,
  VERIFICANDO_ESTADO,
  NUMERO_TELEFONO_LABEL,
} from 'constants/commonConstants';
import Button from 'components/Button';
import InfoItem from 'components/InfoItem';
import Card from 'components/Card';
import {
  AGENDA_VIDEOLLAMADA,
  UNAVAILABLE_ROUTE,
} from 'constants/RouterConstants';
import BotonVolver from 'components/BotonVolver';
import Cedula from '../Cedula/Cedula';
import Verificacion from '../Verificacion/Verificacion';
import MotivoRechazo from '../MotivoRechazo/MotivoRechazo';
import styles from './index.module.css';

const VideollamadaContainer = ({ ocultarHeader, agenteLogueado }) => {
  const { id } = useParams();

  const [api, setApi] = useState(null);
  const [numeroParticipantes, setNumeroParticipantes] = useState(0);
  const [cargandoCedula, setCargandoCedula] = useState(false);
  const [cargandoCaptura, setCargandoCaptura] = useState(false);
  const [textoCargandoCaptura, setTextoCargandoCaptura] = useState(null);
  const [cargandoColgar, setCargandoColgar] = useState(false);
  const [cedula, setCedula] = useState(null);
  const [nroSerie, setNroSerie] = useState(null);
  const [errorCedula, setErrorCedula] = useState(null);
  const [errorVerificacion, setErrorVerificacion] = useState(null);
  const [verificado, setVerificado] = useState(null);
  const [modalColgarAbierto, setModalColgarAbierto] = useState(false);
  const [datosVideollamada, setDatosVideollamada] = useState({});
  const [cargandoVideollamada, setCargandoVideollamada] = useState(false);
  const [tramiteValidado, setTramiteValidado] = useState(null);
  const [ladoCedula, setLadoCedula] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const solicitarEstadoVideollamada = async () => {
    setVerificado(null);
    setTextoCargandoCaptura(VERIFICANDO_ESTADO);
    let intentos = window.REACT_APP_CANTIDAD_INTENTOS_POLLING_VIDEOLLAMADA;

    const detenerIntervalo = (resultadoVerificacion, intervalo, error) => {
      setVerificado(resultadoVerificacion);
      clearInterval(intervalo);
      setCargandoCaptura(false);
      setTextoCargandoCaptura(null);
      setErrorVerificacion(error);
    };

    const intervalo = setInterval(async () => {
      try {
        const response = await estadoTramite(id);
        const datos = response.data;
        switch (datos?.estado) {
          case PENDIENTE_AGENTE:
            // Verificacion exitosa
            detenerIntervalo(true, intervalo, null);
            break;
          case RECHAZADO_VERIFICACION:
            // La persona no es reconocida
            detenerIntervalo(false, intervalo, null);
            break;
          case ERROR:
            // Error en la verificación
            detenerIntervalo(null, intervalo, ERROR_VERIFICACION);
            break;
          default:
            intentos -= 1;
            break;
        }
        if (intentos === 0) {
          // Se llega al máximo de intentos
          detenerIntervalo(null, intervalo, ERROR_VERIFICACION);
        }
      } catch {
        detenerIntervalo(null, intervalo, ERROR_VERIFICACION);
      }
    }, 10000);
  };

  const capturarPantalla = () => {
    setErrorVerificacion(null);
    api.captureLargeVideoScreenshot().then(data => {
      const foto = data?.dataURL;
      if (foto) {
        const fotoBase64 = foto.split(',').pop();
        const numeroSerie = datosVideollamada?.nro_serie || nroSerie;
        setCargandoCaptura(true);
        setTextoCargandoCaptura(INICIANDO_PROCESO);
        validarPersona(fotoBase64, numeroSerie, id)
          .then(response => {
            if (response.status !== 200) {
              setErrorVerificacion(ERROR_VERIFICACION);
            } else {
              solicitarEstadoVideollamada();
            }
          })
          .catch(() => {
            setCargandoCaptura(false);
            setTextoCargandoCaptura(null);
            setErrorVerificacion(ERROR_VERIFICACION);
          });
      } else {
        setErrorVerificacion(ERROR_TOMAR_CAPTURA);
      }
    });
  };

  const cargarCedula = async (numeroSerie, esDorsoCedula = false) => {
    setErrorCedula(null);
    setCargandoCedula(true);
    try {
      const response = await fetchCedula(
        datosVideollamada?.ciudadano?.primer_apellido,
        datosVideollamada?.ciudadano?.numero_documento,
        numeroSerie,
        esDorsoCedula,
      );
      const datos = response.data;
      if (datos?.error_dnic) {
        setErrorCedula(datos.error_dnic);
      } else if (datos?.cedula) {
        setCedula(datos?.cedula);
      } else if (!datos?.numero_serie) {
        setErrorCedula(ERROR_NUMERO_SERIE);
      } else {
        setErrorCedula(ERROR_PRIMER_APELLIDO);
      }
      setCargandoCedula(false);
    } catch {
      setCargandoCedula(false);
      setErrorCedula(ERROR_OBTENER_CEDULA);
    }
  };

  useEffect(() => {
    ocultarHeader();
    const obtenerInfoVideollamada = async () => {
      try {
        setCargandoVideollamada(true);
        const response = await fetchVideollamada(id);
        setDatosVideollamada(response.data);
      } catch (response) {
        if (response.status === 404) history.push(UNAVAILABLE_ROUTE);
        setDatosVideollamada({});
      } finally {
        setCargandoVideollamada(false);
      }
    };
    obtenerInfoVideollamada();
  }, []);

  useEffect(() => {
    const numeroSerie = nroSerie || datosVideollamada?.nro_serie;
    if (numeroSerie) {
      cargarCedula(numeroSerie);
    }
  }, [datosVideollamada, nroSerie]);

  const onSubmitNroSerie = data => {
    setNroSerie(data.nroSerie);
  };

  const handleColgarLlamada = async data => {
    setCargandoColgar(true);
    try {
      await rechazarTramite(id, data.motivoRechazo, data.motivoRechazoExtra);
      setCargandoColgar(false);
      setModalColgarAbierto(false);
      dispatch(fetchAgente());
      history.push(AGENDA_VIDEOLLAMADA);
    } catch {
      setCargandoColgar(false);
      setModalColgarAbierto(false);
    }
  };

  const handleBotonVolverODesvincularse = async () => {
    try {
      await desvincularAgenteTramite(id);
    } finally {
      dispatch(fetchAgente());
      history.push(AGENDA_VIDEOLLAMADA);
    }
  };

  const botonColgar = (
    <Button
      type="secondary"
      size="lg"
      title={TERMINAR_VIDEOLLAMADA_TEXT}
      isLoading={cargandoColgar}
      className={styles.boton_cancelar}
      callback={() => setModalColgarAbierto(true)}
    />
  );

  const botonDesvincularse = (
    <Button
      type="secondary"
      size="lg"
      title={DESVINCULARSE_VIDEOLLAMADA_TEXT}
      className={styles.boton_secundario}
      callback={handleBotonVolverODesvincularse}
    />
  );

  const handleCambiarLadoCedula = () => {
    const nuevoLadoCedula = !ladoCedula;
    setLadoCedula(nuevoLadoCedula);
    cargarCedula(nroSerie || datosVideollamada?.nro_serie, nuevoLadoCedula);
  };

  return (
    <div>
      <BotonVolver
        texto={VOLVER_BOTON_TEXT}
        handlePrevious={handleBotonVolverODesvincularse}
      />
      <div className={styles.contenedor}>
        <Card className={styles.contenedorInformacion}>
          <h1 className={styles.contenedorInformacion__titulo}>
            {OBTENER_NIVEL_INTERMEDIO}
          </h1>
          {!cargandoVideollamada ? (
            <>
              <div className={styles.datosUsuario}>
                <InfoItem
                  key="nombre"
                  label={NOMBRE_COMPLETO_LABEL}
                  labelClass={styles.labelDatoPersonal}
                  content={datosVideollamada?.ciudadano?.nombre_completo}
                  contentClass={styles.datoPersonal}
                />
                <InfoItem
                  key="tipoDocumento"
                  label={TIPO_DOCUMENTO_LABEL}
                  labelClass={styles.labelDatoPersonal}
                  content={
                    datosVideollamada?.ciudadano?.documento?.tipo_documento
                      ?.nombre
                  }
                  contentClass={styles.datoPersonal}
                />
                <InfoItem
                  key="nroDocumento"
                  label={NUMERO_DOCUMENTO_LABEL}
                  labelClass={styles.labelDatoPersonal}
                  content={datosVideollamada?.ciudadano?.numero_documento}
                  contentClass={styles.datoPersonal}
                />
                <InfoItem
                  key="pais"
                  label={PAIS_EMISOR_LABEL}
                  labelClass={styles.labelDatoPersonal}
                  content={
                    datosVideollamada?.ciudadano?.documento?.pais_emisor?.nombre
                  }
                  contentClass={styles.datoPersonal}
                />
                <InfoItem
                  key="correo"
                  label={CORREO_ELECTRONICO_LABEL}
                  labelClass={styles.labelDatoPersonal}
                  content={datosVideollamada?.ciudadano?.email}
                  contentClass={styles.datoPersonal}
                />
                <InfoItem
                  key="telefono"
                  label={NUMERO_TELEFONO_LABEL}
                  labelClass={styles.labelDatoPersonal}
                  content={
                    datosVideollamada?.ciudadano?.numero_telefono || 'Sin datos'
                  }
                  contentClass={styles.datoPersonal}
                />
                <InfoItem
                  key="nivel"
                  label={NIVEL_LABEL}
                  labelClass={styles.labelDatoPersonal}
                  content={datosVideollamada?.ciudadano?.nivel}
                  contentClass={styles.datoPersonal__rojo}
                />
              </div>
              <Cedula
                cedula={cedula}
                errorCedula={errorCedula}
                cargandoCedula={cargandoCedula}
                handleCambiarLadoCedula={handleCambiarLadoCedula}
                onSubmit={onSubmitNroSerie}
              />
            </>
          ) : (
            <Spinner
              className={styles.spinner}
              name="ball-beat"
              fadeIn="none"
              color="black"
            />
          )}
          {cedula && (
            <Verificacion
              verificado={verificado}
              cargandoCaptura={cargandoCaptura}
              textoCargandoCaptura={textoCargandoCaptura}
              capturarPantalla={capturarPantalla}
              errorVerificacion={errorVerificacion}
              id={id}
              nombrePersona={datosVideollamada?.ciudadano?.nombre_completo}
              numeroParticipantes={numeroParticipantes}
              tramiteValidado={tramiteValidado}
              setTramiteValidado={setTramiteValidado}
            />
          )}
          {!verificado && !tramiteValidado ? (
            <>
              <div className={styles.botonesContenedor}>
                {botonDesvincularse}
                {botonColgar}
              </div>
              <MotivoRechazo
                onSubmit={handleColgarLlamada}
                cargandoRechazar={cargandoColgar}
                onCancel={() => setModalColgarAbierto(false)}
                onClose={() => setModalColgarAbierto(false)}
                modalAbierto={modalColgarAbierto}
                textoModal={CONFIRMAR_TERMINAR_VIDEOLLAMADA_TEXT}
                textoConfirmar={TERMINAR_VIDEOLLAMADA_TEXT}
                textoCancelar={VOLVER_A_VIDEOLLAMADA}
              />
            </>
          ) : null}
        </Card>
        <Card className={styles.contenedorVideollamada}>
          {!cargandoVideollamada && datosVideollamada?.link ? (
            <JitsiMeeting
              domain={window.REACT_APP_DOMINIO_VIDEOLLAMADA}
              roomName={datosVideollamada?.link}
              jwt={datosVideollamada?.jwt_agente}
              getIFrameRef={n => {
                n.classList.add(styles.iframeVideollamada);
              }}
              configOverwrite={{
                defaultLanguage: 'es',
              }}
              userInfo={{
                displayName: `${NOMBRE_AGENTE} ${agenteLogueado}`,
              }}
              onApiReady={externalApi => {
                setApi(externalApi);
                setNumeroParticipantes(externalApi.getNumberOfParticipants());
                externalApi.addListener('participantJoined', () => {
                  const numberOfParticipants =
                    externalApi.getNumberOfParticipants();
                  setNumeroParticipantes(numberOfParticipants + 1);
                });
                externalApi.addListener('participantLeft', () => {
                  setNumeroParticipantes(1);
                });
              }}
            />
          ) : (
            <Spinner
              className={`${styles.spinner} spin-class`}
              name="ball-spin-fade-loader"
              fadeIn="none"
              color="black"
            />
          )}
        </Card>
      </div>
    </div>
  );
};

VideollamadaContainer.propTypes = {
  ocultarHeader: PropTypes.func,
  agenteLogueado: PropTypes.string,
};

const mapStateToProps = state => ({
  agenteLogueado: state.agente?.currentAgenteNombre,
});

export default connect(mapStateToProps, {
  fetchAgente,
  ocultarHeader: hideHeader,
})(VideollamadaContainer);
