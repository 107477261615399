import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Card from 'components/Card';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import styles from './styles.module.css';

const AlertNuevo = ({ texto, textoNegrita, url, urlTexto, error }) => (
  <Card
    classNameInner={error ? styles.contenedorError : styles.contenedorInfo}
    className={styles.card}
  >
    <InfoOutlinedIcon
      className={error ? styles.iconoError : styles.iconoInfo}
    />
    <div className={styles.contenedorTextos}>
      <p>{texto}</p>
      <b>{textoNegrita}</b>
    </div>
    {url && (
      <Link className={styles.textoLink} to={url} relative="path">
        <p>{urlTexto}</p>
      </Link>
    )}
  </Card>
);

AlertNuevo.propTypes = {
  error: PropTypes.bool,
  texto: PropTypes.string,
  textoNegrita: PropTypes.string,
  url: PropTypes.string,
  urlTexto: PropTypes.string,
};

export default AlertNuevo;
