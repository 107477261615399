import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

// constantes
import {
  TEXTO_ACCION,
  TEXTO_CAMPO_ACTIVO,
  TEXTO_CAMPO_CI,
  TEXTO_CAMPO_EMAIL,
  TEXTO_CAMPO_ESCRITURA,
  TEXTO_CAMPO_NOMBRE_COMPLETO,
  TEXTO_CAMPO_ORGANISMO,
  TEXTO_CAMPO_RESPONSABLE,
  TEXTO_CAMPO_VIDEOLLAMADA,
  TEXTO_ULTIMO_ACCESO,
} from 'constants/commonConstants';

// MUI
import EditIcon from '@mui/icons-material/Edit';
import { Checkbox } from '@mui/material';

// styles
import styles from './styles.module.css';

const camposTabla = [
  {
    nombre: TEXTO_CAMPO_CI,
    grande: true,
  },
  {
    nombre: TEXTO_CAMPO_NOMBRE_COMPLETO,
    grande: true,
  },
  {
    nombre: TEXTO_CAMPO_EMAIL,
    grande: true,
  },
  {
    nombre: TEXTO_CAMPO_ORGANISMO,
    grande: true,
  },
];

const GestionAgentesTabla = ({
  datosAgentes,
  handleEditarInfoAgente,
  esSuperAgente,
}) => {
  const permisosTabla = useMemo(() => {
    const permisos = [
      {
        nombre: TEXTO_CAMPO_ACTIVO,
      },
      {
        nombre: TEXTO_CAMPO_ESCRITURA,
      },
      {
        nombre: TEXTO_CAMPO_VIDEOLLAMADA,
      },
    ];
    if (esSuperAgente)
      permisos.push({
        nombre: TEXTO_CAMPO_RESPONSABLE,
      });
    return permisos;
  }, [esSuperAgente]);

  const celdasDatosAgente = datosAgente => {
    const fechaFormateada = datosAgente?.ultimo_acceso
      ? dayjs(datosAgente.ultimo_acceso).locale('es').format('D/MM/YYYY')
      : 'Sin registro';
    return (
      <>
        <td key="ci" className={styles.ciDatos}>
          {datosAgente?.numero_documento}
        </td>
        <td key="nombreCompleto">{datosAgente?.nombre_completo}</td>
        <td key="email">{datosAgente?.email}</td>
        <td key="organismo">{datosAgente?.organismo?.nombre}</td>
        <td key="activo">
          <Checkbox disabled defaultChecked={datosAgente?.permisos?.activo} />
        </td>
        <td key="escritura">
          <Checkbox
            disabled
            defaultChecked={datosAgente?.permisos?.escritura}
          />
        </td>
        <td key="videollamada">
          <Checkbox
            disabled
            defaultChecked={datosAgente?.permisos?.videollamada}
          />
        </td>
        {esSuperAgente && (
          <td key="responsable">
            <Checkbox
              disabled
              defaultChecked={datosAgente?.permisos?.responsable}
            />
          </td>
        )}
        <td key="ultimoAcceso">{fechaFormateada}</td>
        <td key="boton">
          <EditIcon
            size="small"
            className={styles.iconoEditar}
            onClick={() => handleEditarInfoAgente(datosAgente)}
          />
        </td>
      </>
    );
  };

  const mostrarDatosAgentes = agentes => {
    const datos = [];

    agentes?.map(agente =>
      datos.push(
        <tr key={`agente${agente?.id}`} className={styles.fila}>
          {celdasDatosAgente(agente)}
        </tr>,
      ),
    );
    return datos;
  };

  return (
    <div className={styles.contenedor}>
      <table className={styles.tabla}>
        <thead>
          <tr className={styles.cabezal}>
            {camposTabla.map(({ nombre, grande }) => (
              <th
                key={nombre}
                className={
                  grande
                    ? styles.primerFilaCeldaGrande
                    : styles.primerFilaCeldaChica
                }
              >
                {nombre}
              </th>
            ))}
            {permisosTabla.map(({ nombre }) => (
              <th key={nombre} className={styles.primerFilaPermisos}>
                {nombre}
              </th>
            ))}
            <th key="Último acceso" className={styles.primerFilaCeldaChica}>
              {TEXTO_ULTIMO_ACCESO}
            </th>
            <th key="Acción" className={styles.primerFilaCeldaChica}>
              {TEXTO_ACCION}
            </th>
          </tr>
        </thead>
        <tbody>{mostrarDatosAgentes(datosAgentes)}</tbody>
      </table>
    </div>
  );
};

GestionAgentesTabla.propTypes = {
  datosAgentes: PropTypes.array,
  handleEditarInfoAgente: PropTypes.func,
  esSuperAgente: PropTypes.bool,
};

export default GestionAgentesTabla;
