import { FETCH_PAISES } from 'constants/ActionTypes';

export const INITIAL_STATE = {
  paises: [],
  loading: true,
};

// eslint-disable-next-line default-param-last
export default function paisesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // -----------------------------------------------------------------------
    // FETCH_MIS_DATOS
    // -----------------------------------------------------------------------
    case FETCH_PAISES.SUCCESS:
      return {
        ...state,
        paises: action.payload.data,
        loading: false,
      };

    case FETCH_PAISES.ERROR:
      return {
        ...state,
        paises: [],
        loading: false,
      };

    case FETCH_PAISES.PENDING:
      return {
        ...state,
        loading: true,
      };

    // DEFAULT
    default:
      return state;
  }
}
