import React from 'react';
import PropTypes from 'prop-types';

// componentes
import Button from '../Button';

// styles
import 'styles/bootstrap/bootstrap-grid.css';
import styles from './styles.module.css';

const InfoCiudadanoConBoton = ({
  textoSinDatos,
  campos,
  mostrarBoton,
  handleBoton,
  textoBoton,
  cargando,
}) => (
  <div className="row">
    <div className={`col-md-8 ${styles.contenedor}`}>
      {textoSinDatos ? (
        <p className={styles.textoPrincipal}>{textoSinDatos}</p>
      ) : (
        campos.map(campo => (
          <div className="row" key={campo.label}>
            <span className={`col-md-6 ${styles.izquierda}`}>
              {campo.label}
            </span>
            <span className={`col-md-6 ${styles.derecha}`}>{campo.valor}</span>
          </div>
        ))
      )}
    </div>
    <div className="col-md-4">
      {mostrarBoton && (
        <Button
          className={styles.boton}
          title={textoBoton}
          callback={handleBoton}
          type="primary"
          size="sm"
          side="right"
          isLoading={cargando}
        />
      )}
    </div>
  </div>
);

InfoCiudadanoConBoton.propTypes = {
  textoSinDatos: PropTypes.string,
  campos: PropTypes.array,
  mostrarBoton: PropTypes.bool,
  handleBoton: PropTypes.func,
  textoBoton: PropTypes.string,
  cargando: PropTypes.bool,
};

export default InfoCiudadanoConBoton;
