import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const ShortCard = ({ titulo, valor }) => (
  <div className="short-card">
    <p className="small">{titulo}</p>
    <p className="large">{valor}</p>
  </div>
);

ShortCard.propTypes = {
  titulo: PropTypes.string,
  valor: PropTypes.string,
};

export default ShortCard;
