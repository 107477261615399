import React from 'react';
import PropTypes from 'prop-types';

import {
  MOTIVO_RECHAZO_1_TEXTO_TOOLTIP,
  MOTIVO_RECHAZO_2_TEXTO_TOOLTIP,
  MOTIVO_RECHAZO_3_TEXTO_TOOLTIP,
  MOTIVO_RECHAZO_4_TEXTO_TOOLTIP,
  MOTIVO_RECHAZO_5_TEXTO_TOOLTIP,
  MOTIVO_RECHAZO_6_TEXTO_TOOLTIP,
  MOTIVO_RECHAZO_7_TEXTO_TOOLTIP,
  MOTIVO_RECHAZO_8_TEXTO_TOOLTIP,
  MOTIVO_RECHAZO_9_TEXTO_TOOLTIP,
  ESTADO_ERROR,
  ESTADO_RECHAZADO_AGENTE,
  ESTADO_AGENDADO,
  ESTADO_RECHAZADO_VERIFICACION,
  ESTADO_EXITOSO,
  ESTADO_PENDIENTE_AGENTE,
  ESTADO_PENDIENTE_VERIFICACION,
  ESTADO_RECHAZADO_USUARIO,
  ESTADO_PENDIENTE_USUARIO,
} from 'constants/commonConstants';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from 'components/Tooltip';
import styles from './styles.module.css';

export const ColumnaEstado = ({ tramite, puedeTomarLlamada }) => {
  const estadoMappings = {
    [ESTADO_RECHAZADO_USUARIO]: {
      estilos: styles.tdEstadoRechazado,
      texto: 'Rechazado Ciudadano',
    },
    [ESTADO_RECHAZADO_AGENTE]: {
      estilos: styles.tdEstadoRechazado,
      texto: 'Rechazado Agente',
    },
    [ESTADO_EXITOSO]: {
      estilos: styles.tdEstadoExitoso,
      texto: 'Finalizado Ciudadano',
    },
    [ESTADO_PENDIENTE_USUARIO]: {
      estilos: styles.tdEstadoPendienteCiudadano,
      texto: 'Esperando Ciudadano',
    },
    [ESTADO_PENDIENTE_VERIFICACION]: {
      estilos: styles.tdEstadoEnCurso,
      texto: 'En curso',
    },
    [ESTADO_PENDIENTE_AGENTE]: {
      estilos: styles.tdEstadoEnCurso,
      texto: 'En curso',
    },
    [ESTADO_RECHAZADO_VERIFICACION]: {
      estilos: styles.tdEstadoEnCurso,
      texto: 'En curso',
    },
    [ESTADO_AGENDADO]:
      tramite.agente === null
        ? {
            estilos: styles.tdEstadoPendienteAgente,
            texto: 'Esperando Agente',
          }
        : {
            estilos: styles.tdEstadoEnCurso,
            texto: 'En curso',
          },
    [ESTADO_ERROR]: {
      estilos: styles.tdEstadoInterrumpido,
      texto: 'Interrumpido',
    },
  };

  const estadoActual = estadoMappings[tramite.estado];

  const estilosEstado = estadoActual ? estadoActual.estilos : null;
  const textoEstado = estadoActual ? estadoActual.texto : null;

  const motivoRechazoMapping = [
    MOTIVO_RECHAZO_1_TEXTO_TOOLTIP,
    MOTIVO_RECHAZO_2_TEXTO_TOOLTIP,
    MOTIVO_RECHAZO_3_TEXTO_TOOLTIP,
    MOTIVO_RECHAZO_4_TEXTO_TOOLTIP,
    MOTIVO_RECHAZO_5_TEXTO_TOOLTIP,
    MOTIVO_RECHAZO_6_TEXTO_TOOLTIP,
    MOTIVO_RECHAZO_7_TEXTO_TOOLTIP,
    MOTIVO_RECHAZO_8_TEXTO_TOOLTIP,
    MOTIVO_RECHAZO_9_TEXTO_TOOLTIP,
  ];

  return (
    <td
      className={puedeTomarLlamada ? styles.tdDeshabilitado : ''}
      key="estado"
    >
      <div className={estilosEstado}>
        {textoEstado}
        {tramite.estado === ESTADO_RECHAZADO_AGENTE && (
          <Tooltip
            title={motivoRechazoMapping[tramite.motivo_rechazo_numero - 1]}
            placement="bottom"
            arrow
          >
            <InfoOutlinedIcon className={styles.iconoInfo} />
          </Tooltip>
        )}
      </div>
    </td>
  );
};

ColumnaEstado.propTypes = {
  puedeTomarLlamada: PropTypes.bool,
  tramite: PropTypes.object,
};
