import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { LOGOUT_CERRANDO_SESION } from 'constants/commonConstants';

import Auth from 'utils/Auth';
import { hideHeader } from 'actions/ui';
import CardSpinner from 'components/CardSpinner';
import * as ROUTES from 'constants/RouterConstants';

const LogoutProcessContainer = ({ ocultarHeader }) => {
  const history = useHistory();

  useEffect(() => {
    if (Auth.getInstance().isAuthenticated()) {
      ocultarHeader();
      Auth.getInstance().logout();
    } else {
      history.push(ROUTES.INDEX_ROUTE);
    }
  }, []);

  return (
    <CardSpinner
      spin
      text={LOGOUT_CERRANDO_SESION}
      className="spinner__transparent"
    />
  );
};

LogoutProcessContainer.propTypes = {
  ocultarHeader: PropTypes.func,
};

export default connect(null, {
  ocultarHeader: hideHeader,
})(LogoutProcessContainer);
