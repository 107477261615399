import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import ciudadanoReducer from '../reducers/ciudadano';
import paisesReducer from '../reducers/paises';
import uiReducer from '../reducers/ui';
import agenteReducer from '../reducers/agente';
import configuracionTurnoReducer from '../reducers/configuracionTurno';
import customRouterReducer from '../reducers/customRouter';
import apiMiddleware from '../middlewares/ApiMiddleware';

const REDUX_STATE_KEY = 'reduxState';

const persistedState = () => {
  const existingState = localStorage.getItem(REDUX_STATE_KEY);
  if (existingState) {
    return JSON.parse(existingState);
  }
  return {};
};

export const history = createBrowserHistory();
const middlewares = [apiMiddleware, routerMiddleware(history)];
const reducers = combineReducers({
  // reducers go here...
  ciudadano: ciudadanoReducer,
  form: reduxFormReducer,
  router: connectRouter(history),
  paises: paisesReducer,
  ui: uiReducer,
  agente: agenteReducer,
  configuracionesTurno: configuracionTurnoReducer,
  customRouter: customRouterReducer,
});

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducers,
  persistedState(),
  composeEnhancers(applyMiddleware(...middlewares)),
);
/* eslint-enable */

store.subscribe(() => {
  localStorage.setItem(REDUX_STATE_KEY, JSON.stringify(store.getState()));
});
