import React from 'react';
import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '16px',
    minHeight: '50px',
    fontSize: '12px',
    lineHeight: '16px',
  },
});

export default Tooltip;
