import React from 'react';
import dayjs from 'dayjs';
import Spinner from 'react-spinkit';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const SelectorHora = ({ disabled, periodos, onChange, selected, loading }) => {
  const handleListItemClick = item => {
    if (!disabled) {
      const itemIndex = periodos.findIndex(periodo => periodo.id === item.id);
      onChange(periodos[itemIndex]);
    }
  };

  const componenteHoras = () => (
    <>
      <div
        className={`${styles.horasInternasDiv} ${disabled && styles.disabled}`}
      >
        <ul className={styles.ul}>
          {periodos.map(item => (
            <li className={styles.li} key={item.id}>
              <button
                type="button"
                className={`${styles.boton} ${
                  selected && selected.id === item.id && styles.selected
                }`}
                onClick={() => handleListItemClick(item)}
                disabled={disabled}
              >
                {`${dayjs(item.value).format('HH:mm')} hs`}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {disabled && <div className={styles.fakeBar} />}
    </>
  );

  return (
    <div className={styles.contenedor}>
      {loading ? (
        <div className={styles.contenedorSpinner}>
          <Spinner
            className={`${styles.spinner} spin-class`}
            name="ball-spin-fade-loader"
            fadeIn="none"
          />
        </div>
      ) : (
        componenteHoras()
      )}
    </div>
  );
};

SelectorHora.propTypes = {
  disabled: PropTypes.bool,
  periodos: PropTypes.array,
  onChange: PropTypes.func,
  selected: PropTypes.object,
  loading: PropTypes.bool,
};

export default SelectorHora;
