export const cargandoUsuarioLogueado = state => state.agente.loading;
export const currentAgenteSelector = state => state.agente.currentAgenteNombre;

// Paises
export const cargandoPaisesSelector = state => state.paises.loading;
export const paisesSelector = state => state.paises.paises;

// Videollamada
export const obtenerPermisosAgente = state =>
  state.agente?.currentAgentePermisos || {};
export const tramiteEnCursoSelector = state =>
  state.agente.currentAgenteTramiteEnCurso;

// Router
export const rutaActualSelector = state => state.router.location.pathname;

// Perfil Ciudadano
export const cargandoCiudadanoSelector = state =>
  state.ciudadano.cargandoCiudadano;
export const ciudadanoSelector = state => state.ciudadano.ciudadano;

// Header
export const mostrarHeaderSelector = state => state.ui.showHeader;
