/* eslint-disable react/prop-types */
import React from 'react';
import Button from '../Button';
import './styles.css';

const ErrorPage = props => (
  <div className="container">
    <div className="error__container">
      <div className="error_text_container">
        <h1>{props.title}</h1>
        <span className="body-1">{props.description}</span>
        <div style={{ margin: '16px 0' }}>
          <Button
            type="primary"
            size="lg"
            title={props.buttonName}
            callback={props.buttonCallback}
          />
        </div>
      </div>
      <div className="error-imagen-container">
        <img
          alt={props.altImg}
          src={props.imageSrc}
          className="error_img"
        ></img>
      </div>
    </div>
  </div>
);

export default ErrorPage;
