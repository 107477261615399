export const INDEX_ROUTE = '/';
export const SEARCH_ROUTE = '/buscar';
export const LOGOUT_PROCESS = '/logout-process';
export const LOGIN_ROUTE = `${window.REACT_APP_AUTOGESTION_HOST}/login`;

export const PROFILE_ROUTE = '/ciudadano';
export const PROFILE_DETAIL_ROUTE = '/ciudadano/:id(\\d+)';
export const CAMBIAR_TELEFONO_ROUTE = '/ciudadano/cambiar-telefono';

export const CREATE_PROFILE_ROUTE = '/crear-ciudadano';
export const STATISTICS_ROUTE = '/estadisticas';

export const SERVER_ERROR_ROUTE = '/error';
export const UNAVAILABLE_ROUTE = '/desconocido';

// ---------------------------------------------------------------------------
// Videollamada
// ---------------------------------------------------------------------------
export const VIDEOLLAMADA = '/videollamada/:id(\\d+)';
export const VIDEOLLAMADA_SOPORTE = '/videollamada-soporte/:uuid';
export const AGENDA_VIDEOLLAMADA = '/agenda-videollamada';
export const AGENDA_VIDEOLLAMADA_SOPORTE = '/agenda-videollamada-soporte';

// ---------------------------------------------------------------------------
// Configuración turnos
// ---------------------------------------------------------------------------

// Validacion
export const CONFIGURACION_TURNOS = '/configuracion-turnos';
export const CREAR_CONFIGURACION_TURNO = `${CONFIGURACION_TURNOS}/crear`;
// Consulta
export const CONFIGURACION_TURNOS_CONSULTA = '/configuracion-turnos-consulta';
export const CREAR_CONFIGURACION_TURNOS_CONSULTA = `${CONFIGURACION_TURNOS_CONSULTA}/crear`;

// ---------------------------------------------------------------------------
// Gestión de agentes
// ---------------------------------------------------------------------------
export const GESTION_AGENTES = '/gestion-agentes';
