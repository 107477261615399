/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import {
  SERVER_ERROR_ROUTE,
  UNAVAILABLE_ROUTE,
  INDEX_ROUTE,
} from 'constants/RouterConstants';
import {
  TITLE_ERROR_404,
  DESCRIPTION_ERROR_404,
  TITLE_ERROR_SERVER,
  DESCRIPTION_ERROR_SERVER,
  ALT_SERVER_ERROR,
  ALT_ERROR_4O4,
} from 'constants/commonConstants';
import { showHeader, hideHeader } from 'actions/ui';
import ErrorPage from 'components/ErrorPage';

import IMAGE_ERROR_404 from 'assets/404.png';
import IMAGE_ERROR_SERVER from 'assets/internal-server-error.png';

class ErrorPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleGoBackTwoPages = this.handleGoBackTwoPages.bind(this);
    this.handleGoToIndex = this.handleGoToIndex.bind(this);
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.errorPage404 = true;
  }

  componentDidMount() {
    this.props.hideHeader();
  }

  componentWillUnmount() {
    this.props.showHeader();
  }

  handleGoBack(pages) {
    this.props.history.goBack(pages);
  }

  handleGoBackTwoPages() {
    this.props.history.go(-2);
  }

  handleGoToIndex() {
    this.props.history.push(INDEX_ROUTE);
  }

  render() {
    const is404 = this.props.actualPath === UNAVAILABLE_ROUTE;
    const isServerError = this.props.actualPath === SERVER_ERROR_ROUTE;
    let callback = null;
    let callbackText = 'Volver';
    if (is404 || isServerError) {
      callbackText = 'Ir al Inicio';
      callback = this.handleGoToIndex;
    } else {
      callback = this.props.history.goBack;
    }
    return (
      <ErrorPage
        title={isServerError ? TITLE_ERROR_SERVER : TITLE_ERROR_404}
        description={
          isServerError ? DESCRIPTION_ERROR_SERVER : DESCRIPTION_ERROR_404
        }
        imageSrc={isServerError ? IMAGE_ERROR_SERVER : IMAGE_ERROR_404}
        buttonName={callbackText}
        buttonCallback={callback}
        altImg={isServerError ? ALT_SERVER_ERROR : ALT_ERROR_4O4}
      />
    );
  }
}

const mapStateToProps = state => ({
  actualPath: state.router.location.pathname,
});

export default connect(mapStateToProps, {
  showHeader,
  hideHeader,
})(ErrorPageContainer);
