import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

export const RadioButtonField = ({
  register,
  tabIndex = 0,
  name,
  label,
  value,
  disabled,
  required,
}) => (
  <div className={styles.radio_button_field_nuevo}>
    <input
      {...register(name, { required })}
      id={`${name}-${value}`}
      tabIndex={tabIndex}
      type="radio"
      disabled={disabled}
      name={name}
      aria-label={label}
      value={value}
      className={styles.radio_button_field_nuevo__radio_button}
    />
    <label className={styles.alt} htmlFor={`${name}-${value}`}>
      {label}
    </label>
  </div>
);

RadioButtonField.propTypes = {
  register: PropTypes.func,
  tabIndex: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
