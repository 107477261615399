import React from 'react';
import PropTypes from 'prop-types';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles.css';

const diasLetras = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
const meses = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Setiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const Calendario = ({
  setFechasSeleccionadas,
  fechasSeleccionadas,
  fechaMinima,
  fechaMaxima,
  esRango,
  generarIcono,
  tileClassName,
  tileDisabled,
  handleCambioMes,
}) => {
  const handleOnChange = newDate => {
    setFechasSeleccionadas(newDate);
  };

  return (
    <div className="calendario">
      <Calendar
        locale="es-ES"
        onChange={handleOnChange}
        value={fechasSeleccionadas}
        formatShortWeekday={(_, date) => diasLetras[date.getDay()]}
        formatMonthYear={(_, date) =>
          `${meses[date.getMonth()]} ${date.getFullYear()}`
        }
        minDate={fechaMinima}
        maxDate={fechaMaxima}
        tileDisabled={({ date }) => tileDisabled(date)}
        selectRange={esRango}
        // Desactivar botones para navegar entre años
        prev2Label={null}
        next2Label={null}
        // Habilitar icono en fecha
        tileContent={({ date }) => generarIcono && generarIcono(date)}
        tileClassName={tileClassName}
        // Al cambiar de mes
        onActiveStartDateChange={handleCambioMes}
      />
    </div>
  );
};

Calendario.propTypes = {
  setFechasSeleccionadas: PropTypes.func,
  fechasSeleccionadas: PropTypes.array,
  fechaMinima: PropTypes.object,
  fechaMaxima: PropTypes.object,
  esRango: PropTypes.bool,
  generarIcono: PropTypes.func,
  tileClassName: PropTypes.string,
  tileDisabled: PropTypes.func,
  handleCambioMes: PropTypes.func,
};

export default Calendario;
