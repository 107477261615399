import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import {
  PREFIJO_BASE64,
  ALT_CEDULA_CIUDADANO,
  LARGO_NUMERO_SERIE,
  NUMERO_SERIE_CEDULA_LABEL,
  NUMERO_SERIE_CEDULA_PLACEHOLDER,
  CARGAR_CEDULA,
  TEXTO_CAMBIAR_LADO_CEDULA,
} from 'constants/commonConstants';
import Spinner from 'react-spinkit';
import Alert from 'components/Alert';
import TextField from 'components/HookForm/TextField';
import Button from 'components/Button';

import styles from './cedula.module.css';

const FormularioCedula = ({ onSubmit, cargandoCedula }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  return (
    <form className={styles.formulario} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        register={register}
        name="nroSerie"
        label={NUMERO_SERIE_CEDULA_LABEL}
        type="text"
        required
        maxLength={LARGO_NUMERO_SERIE}
        minLength={LARGO_NUMERO_SERIE}
        error={errors.nroSerie}
        placeholder={NUMERO_SERIE_CEDULA_PLACEHOLDER}
        upper
      />
      <Button
        type="primary"
        size="lg"
        title={CARGAR_CEDULA}
        isSubmit
        isLoading={cargandoCedula}
        className={styles.botonInfoDnic}
      />
    </form>
  );
};

FormularioCedula.propTypes = {
  onSubmit: PropTypes.func,
  cargandoCedula: PropTypes.bool,
};

const Cedula = ({
  cedula,
  errorCedula,
  cargandoCedula,
  onSubmit,
  handleCambiarLadoCedula,
}) => {
  const formulario = (
    <FormularioCedula onSubmit={onSubmit} cargandoCedula={cargandoCedula} />
  );
  if (cargandoCedula) {
    return (
      <Spinner
        className={styles.spinner}
        name="ball-beat"
        fadeIn="none"
        color="black"
      />
    );
  }
  if (cedula) {
    return (
      <>
        <img
          className={styles.cedula}
          src={`${PREFIJO_BASE64}${cedula}`}
          alt={ALT_CEDULA_CIUDADANO}
        />
        <Button
          type="primary"
          size="lg"
          title={TEXTO_CAMBIAR_LADO_CEDULA}
          isLoading={cargandoCedula}
          className={styles.boton}
          callback={handleCambiarLadoCedula}
        />
      </>
    );
  }
  if (errorCedula) {
    return (
      <>
        {formulario}
        <Alert text={errorCedula} type="error" />
      </>
    );
  }
  return formulario;
};

Cedula.propTypes = {
  cedula: PropTypes.string,
  errorCedula: PropTypes.string,
  cargandoCedula: PropTypes.bool,
  onSubmit: PropTypes.func,
  handleCambiarLadoCedula: PropTypes.func,
};

export default Cedula;
