import React from 'react';

// componentes
import Card from 'components/Card';
import EstadisticasUsuariosPorNivel from './EstadisticasUsuariosPorNivel';
import EstadisticasUsuariosLogueados from './EstadisticasUsuariosLogueados';
import EstadisticasUsuariosConEmailDuplicado from './EstadisticasUsuariosConEmailDuplicado';

import 'components/ShortCard/styles.css';

const EstadisticasContainer = () => (
  <Card>
    <div>
      <EstadisticasUsuariosPorNivel />
      <EstadisticasUsuariosLogueados />
      <EstadisticasUsuariosConEmailDuplicado />
    </div>
  </Card>
);

export default EstadisticasContainer;
