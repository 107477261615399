// ---------------------------------------------------------------------------
// Base Action Type
// ---------------------------------------------------------------------------
const asyncActionType = (
  type,
  extraPendingActions = [],
  extraSuccessActions = [],
  extraErrorActions = [],
) => ({
  PENDING: `${type}_PENDING`,
  SUCCESS: `${type}_SUCCESS`,
  ERROR: `${type}_ERROR`,
  EXTRA_PENDING: extraPendingActions,
  EXTRA_SUCCESS: extraSuccessActions,
  EXTRA_ERROR: extraErrorActions,
});

// ---------------------------------------------------------------------------
// AGENTES
// ---------------------------------------------------------------------------
export const FETCH_AGENTE = asyncActionType('FETCH_AGENTE');

// ---------------------------------------------------------------------------
// PAISES
// ---------------------------------------------------------------------------
export const FETCH_PAISES = asyncActionType('FETCH_PAISES');

// ---------------------------------------------------------------------------
// CIUDADANO
// ---------------------------------------------------------------------------
export const FETCH_CIUDADANO = asyncActionType('FETCH_CIUDADANO');
export const RESET_FORM_ERRORS = 'RESET_FORM_ERRORS';
export const RESET_PROFILE = 'RESET_PROFILE';
export const REMOVE_CIUDADANO = asyncActionType('REMOVE_CIUDADANO');

// ---------------------------------------------------------------------------
// UI
// ---------------------------------------------------------------------------
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const SHOW_HEADER = 'SHOW_HEADER';
export const HIDE_HEADER = 'HIDE_HEADER';

// ---------------------------------------------------------------------------
// CONFIGURACION TURNO
// ---------------------------------------------------------------------------

export const FETCH_CONFIGURACIONES_TURNO = asyncActionType(
  'FETCH_CONFIGURACIONES_TURNO',
);

export const FETCH_CONFIGURACIONES_TURNO_CONSULTA = asyncActionType(
  'FETCH_CONFIGURACIONES_TURNO_CONSULTA',
);
