import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';

// componentes
import SelectorHora from 'components/SelectorHora';

// assets
import menuDown from 'assets/icons/menu-down--black.png';
import menuUp from 'assets/icons/menu-up--black.png';

import styles from './styles.module.css';

const DropdownSelectorHora = ({
  texto,
  horaSeleccionada,
  setHoraSeleccionada,
  mostrarSelectorHora,
  setMostrarSelectorHora,
  horaMinima,
}) => {
  const handleSeleccionarHora = hora => {
    setHoraSeleccionada(hora);
    setMostrarSelectorHora(false);
  };

  const periodosSelector = Array(48)
    .fill(0)
    .map((_, i) => ({
      id: i,
      value: dayjs()
        .set('hour', i / 2)
        .set('minute', i % 2 === 0 ? 0 : 30)
        .toDate(),
    }))
    .filter(
      obj => Math.trunc(dayjs(obj.value).diff(horaMinima, 'minute')) >= 0,
    );

  const horaTexto = !isEmpty(horaSeleccionada)
    ? dayjs(horaSeleccionada.value).format('HH:mm')
    : '';

  return (
    <div
      className={
        !isEmpty(texto)
          ? styles.contenedorPrincipal
          : styles.contenedorPrincipalSinTexto
      }
    >
      <p className={styles.texto}>{texto}</p>
      <div className={styles.contenedorInputSelector}>
        <button
          type="button"
          className={styles.input}
          onClick={() => {
            setMostrarSelectorHora(!mostrarSelectorHora);
          }}
        >
          {horaTexto}
          <img
            src={mostrarSelectorHora ? menuUp : menuDown}
            className={
              !isEmpty(horaSeleccionada)
                ? styles.menuDown
                : styles.menuDownNoValue
            }
            alt="dropdown"
          />
        </button>
        {mostrarSelectorHora && (
          <SelectorHora
            periodos={periodosSelector}
            onChange={handleSeleccionarHora}
            selected={horaSeleccionada}
          />
        )}
      </div>
    </div>
  );
};

DropdownSelectorHora.propTypes = {
  texto: PropTypes.string,
  horaSeleccionada: PropTypes.object,
  setHoraSeleccionada: PropTypes.func,
  mostrarSelectorHora: PropTypes.bool,
  setMostrarSelectorHora: PropTypes.func,
  horaMinima: PropTypes.object,
};

export default DropdownSelectorHora;
