import {
  obtenerTextoAgendaInfoAgendados,
  TEXTO_AGENDA_INFO_SIN_AGENDA,
  CANTIDAD_EXITOSOS,
  CANTIDAD_RECHAZADOS,
  CANTIDAD_AGENDADOS,
  obtenerTextoAgendaInfoExitosos,
  obtenerTextoAgendaInfoRechazados,
  TIPO_TURNO_VALIDACION,
  TIPO_TURNO_CONSULTA,
} from 'constants/commonConstants';

export const obtenerDatosAgendaPorDiaDeSemana = (tipoTurno, datos) =>
  Object.fromEntries(
    Object.entries(datos).map(([dia, turnos]) => {
      const cantidadAgendados = turnos[CANTIDAD_AGENDADOS];
      const cantidadExitosos = turnos[CANTIDAD_EXITOSOS];
      const cantidadRechazados = turnos[CANTIDAD_RECHAZADOS];
      if (
        [cantidadAgendados, cantidadExitosos, cantidadRechazados].some(
          cantidad => cantidad > 0,
        )
      ) {
        if (tipoTurno === TIPO_TURNO_VALIDACION) {
          return [
            dia,
            [
              obtenerTextoAgendaInfoAgendados(cantidadAgendados),
              obtenerTextoAgendaInfoExitosos(cantidadExitosos),
              obtenerTextoAgendaInfoRechazados(cantidadRechazados),
            ],
          ];
        }
        if (tipoTurno === TIPO_TURNO_CONSULTA) {
          return [dia, [obtenerTextoAgendaInfoAgendados(cantidadAgendados)]];
        }
      }
      return [dia, [TEXTO_AGENDA_INFO_SIN_AGENDA]];
    }),
  );
