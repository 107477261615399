import {
  FETCH_CIUDADANO,
  FETCH_PAISES,
  RESET_FORM_ERRORS,
  RESET_PROFILE,
  REMOVE_CIUDADANO, // BLOQUEAR_CIUDADANO, DESBLOQUEAR_CIUDADANO,
} from 'constants/ActionTypes';

import {
  API_REQUEST,
  FETCH_PAISES_URL,
  CIUDADANO_DETAIL_URL,
} from 'constants/ApiConstants';

// ---------------------------------------------------------------------------
// Paises y documentos
// ---------------------------------------------------------------------------
export const fetchPaises = () => ({
  type: API_REQUEST,
  payload: {
    url: `${FETCH_PAISES_URL}`,
    method: 'get',
    next: FETCH_PAISES,
  },
});

// ---------------------------------------------------------------------------
// Ciudadanos
// ---------------------------------------------------------------------------
export const fetchCiudadano = id => ({
  type: API_REQUEST,
  payload: {
    url: CIUDADANO_DETAIL_URL(id),
    method: 'get',
    next: FETCH_CIUDADANO,
  },
});

export const eliminarCiudadano = id => ({
  type: API_REQUEST,
  payload: {
    url: CIUDADANO_DETAIL_URL(id),
    method: 'delete',
    next: REMOVE_CIUDADANO,
  },
});

// ---------------------------------------------------------------------------
// Form helpers
// ---------------------------------------------------------------------------
export const resetFormErrors = () => ({
  type: RESET_FORM_ERRORS,
});

export const resetProfile = () => ({
  type: RESET_PROFILE,
});
