import { useSelector } from 'react-redux';
import {
  cargandoUsuarioLogueado,
  obtenerPermisosAgente,
} from 'store/selectors';

const usePermissions = listaPermisos => {
  const cargando = useSelector(cargandoUsuarioLogueado);
  const permisos = useSelector(obtenerPermisosAgente);

  if (cargando) {
    return false;
  }

  if (listaPermisos.length === 0) {
    return true;
  }

  return listaPermisos.some(perm => {
    const permApp = perm[0];
    const permNombre = perm[1];
    if (permisos[permApp]) {
      return permisos[permApp].includes(permNombre);
    }
    return false;
  });
};

export default usePermissions;
