import { FETCH_AGENTE } from 'constants/ActionTypes';

import { API_REQUEST, FETCH_AGENTE_URL } from 'constants/ApiConstants';

import Auth from 'utils/Auth';

// ---------------------------------------------------------------------------
// Agentes
// ---------------------------------------------------------------------------
export const fetchAgente = (id = Auth.getInstance().getUserId()) => ({
  type: API_REQUEST,
  payload: {
    url: FETCH_AGENTE_URL(id),
    method: 'get',
    next: FETCH_AGENTE,
  },
});
