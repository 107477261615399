import jwtDecode from 'jwt-decode';

import {
  LOGOUT_URL,
  REFRESH_TOKEN_URL,
  REFRESH_INTERVAL,
  OBTAIN_TOKEN_URL,
} from 'constants/ApiConstants';
import { INDEX_ROUTE, SEARCH_ROUTE } from 'constants/RouterConstants';
import { axiosAuth } from './axiosBackend';

let instance = null;

export default class Auth {
  constructor() {
    if (!instance) {
      this.getAuthToken = this.getAuthToken.bind(this);
      this.setAuthToken = this.setAuthToken.bind(this);

      this.isAgente = this.isAgente.bind(this);
      this.isAuthenticated = this.isAuthenticated.bind(this);
      this.isTokenExpired = this.isTokenExpired.bind(this);
      this.getUserId = this.getUserId.bind(this);
      this.storeToken = this.storeToken.bind(this);
      this.decodeToken = this.decodeToken.bind(this);
      this.obtainToken = this.obtainToken.bind(this);

      this.logout = this.logout.bind(this);
      this.refreshToken = this.refreshToken.bind(this);
      this.refresherAction = this.refresherAction.bind(this);

      this.id = Math.random();
      this.storage = window.localStorage;
      this.refresher = null;
      instance = this;
    }
    // eslint-disable-next-line no-constructor-return
    return instance;
  }

  static getInstance() {
    if (instance === null) {
      instance = new Auth();
    }
    if (this.refresher === null) {
      this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    }
    return instance;
  }

  /*
   Retorna el JWT para acceso a recursos del Backend.
   @returns {String} JWT.
  */
  getAuthToken() {
    return this.storage.getItem('token');
  }

  /*
   Almacena el token pasado como parámetro en el brwoser.
   @returns {String} token.
  */
  setAuthToken(newToken) {
    this.storage.setItem('token', newToken);
    return newToken;
  }

  /*
   Indica si un ciudadano es Agente o no.
   Se deduce de la flag que se envia en el Token
   @returns {Integer} ID.
  */
  isAgente() {
    const decoded = this.decodeToken();
    return decoded ? decoded.agnt !== 0 : false;
  }

  agentHasVerified() {
    const decoded = this.decodeToken();
    if ('agent_verified' in decoded) {
      return decoded.agent_verified;
    }
    return false;
  }

  /*
   Indica si hay un ciudadano logueado.
   @returns {Boolean} Retorna True si hay un ciudadano loagueado, false en otro caso.
  */
  isAuthenticated() {
    const res = !this.isTokenExpired();
    if (!res) {
      this.cleanStorage();
    }
    return res;
  }

  /*
   Retorna True si el token almacenado en el browser expiró.
   @returns {Boolean}
  */
  isTokenExpired() {
    const decoded = this.decodeToken();
    if (!decoded || typeof decoded.exp === 'undefined') {
      return true;
    }
    const d = new Date(0);
    d.setUTCSeconds(decoded.exp);
    if (d === null) {
      return true;
    }
    return new Date().valueOf() > d.valueOf();
  }

  /*
   Parsea el JWT almacenado en el browser y retorna el userID.
   @returns {Integer} ID.
  */
  getUserId() {
    const decoded = this.decodeToken();
    return decoded ? decoded.cid : null;
  }

  /*
   Decodifica el JWT almacenado.
   No se valida firma.
   @returns {Object} Token decodificado.
  */
  decodeToken() {
    const token = this.getAuthToken();
    let decoded;
    try {
      decoded = jwtDecode(token);
    } catch (err) {
      decoded = null;
    }
    return decoded;
  }

  /*
   Obtiene y almacena un JWT con nueva fecha de vencimiento a partir
   del token actual.
   @returns {String} JWT.
  */
  refreshToken() {
    axiosAuth({
      method: 'post',
      url: REFRESH_TOKEN_URL,
      data: { token: this.getAuthToken() },
    })
      .then(response => {
        this.setAuthToken(response.data.token);
      })
      .catch(() => {
        clearInterval(this.refresher);
        this.storage.clear();
        window.location.replace(INDEX_ROUTE);
      });
  }

  /*
   Obtiene y almacena un JWT con nueva fecha de vencimiento a partir
   de la sesión existente con el Broker de autenticación.
   @returns {String} JWT.
  */
  obtainToken() {
    return new Promise((resolve, reject) => {
      axiosAuth({
        method: 'get',
        url: OBTAIN_TOKEN_URL,
      })
        .then(response => {
          this.setAuthToken(response.data.token);
          resolve();
        })
        .catch(err => reject(err));
    });
  }

  /*
   Retorna el JWT para acceso a recursos del Backend.
   @returns {String} JWT.
  */
  logout() {
    const headers = { Authorization: `Bearer ${this.getAuthToken()}` };
    this.cleanStorage();
    clearInterval(this.refresher);
    axiosAuth({
      method: 'get',
      url: LOGOUT_URL,
      headers,
    })
      .then(() => {
        window.location.replace(
          `${window.REACT_APP_AUTOGESTION_HOST}/logout-process?post_logout_redirect_uri=${window.REACT_APP_AGENTES_HOST}`,
        );
      })
      .catch(() => {
        window.location.replace(
          `${window.REACT_APP_AUTOGESTION_HOST}/logout-process?post_logout_redirect_uri=${window.REACT_APP_AGENTES_HOST}`,
        );
      });
  }

  /*
   Extracts token from Cookie in the first response,
   deletes and stores it in SessionStorage.
   @returns {Integer} ID.
  */
  storeToken(params) {
    const returnedToken = params.replace('?token=', '');
    this.setAuthToken(returnedToken);
    const decoded = this.decodeToken();
    if (decoded.agnt === 0) {
      return false;
    }
    if (this.refresher === null) {
      this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    }
    return true;
  }

  /*
  Elimina el token del sotrage.
  */
  cleanStorage() {
    this.storage.removeItem('token');
    this.storage.removeItem('reduxState');
  }

  /*
  Método ejecutado por refresher
  */
  refresherAction() {
    if (this.isAuthenticated()) {
      if (
        Auth.getInstance().isAgente() &&
        window.location.pathname === INDEX_ROUTE
      ) {
        window.location.replace(SEARCH_ROUTE);
      }
      this.refreshToken();
    }
  }

  startRefresher() {
    if (this.refresher === null) {
      this.refresher = setInterval(this.refresherAction, REFRESH_INTERVAL);
    }
  }
}

Auth.getInstance().startRefresher();
