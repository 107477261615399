/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';

import Auth from 'utils/Auth';
import Button from '../Button';

// import idUruguay from 'assets/brand.svg';

import './styles.css';

const Inicio = props => (
  <div className="agentes-inicio">
    <div className="inner-div">
      <div className="row top-row">
        <div className="col-md-12 col-xs-12">
          <div className="title-row">
            <span className="brand-text">gub.uy</span>
            <span className="line-separator line-separator__margin-login"></span>
            <span className="text">Agentes</span>
          </div>
        </div>
      </div>
      {!Auth.getInstance().isAuthenticated() ? (
        <div className="row button-margin">
          <div className="col-md-12 col-xs-12 col1 center-block">
            <Button
              title="Iniciar Sesión"
              type="primary"
              size="lg"
              side="center"
              callback={props.goToLogin}
            />
          </div>
        </div>
      ) : null}
      {Auth.getInstance().isAuthenticated() ? (
        <div>
          <div className="row">
            <div className="col-md-12 col-xs-12 center-block">
              <Button
                title="Cerrar sesión"
                type="primary"
                size="lg"
                side="center"
                callback={props.goToLogout}
              />
            </div>
          </div>
          <div className="row help-text-container">
            <div className="col-md-12 help-text-agente-no-login">
              Si sos un agente de registro comunicate con{' '}
              <a href="mailto:soporte@agesic.gub.uy">soporte@agesic.gub.uy</a>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

export default connect(null, null, null)(Inicio);
