import {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  SHOW_HEADER,
  HIDE_HEADER,
} from 'constants/ActionTypes';

export const showSnackbar = text => ({
  type: SHOW_SNACKBAR,
  payload: {
    text,
  },
});

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});

export const showHeader = () => ({
  type: SHOW_HEADER,
});

export const hideHeader = () => ({
  type: HIDE_HEADER,
});
