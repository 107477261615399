import React from 'react';
import PropTypes from 'prop-types';
import { formatearFechaCompleta } from 'utils/utils';

import {
  TEXTO_TABLA_ACCIONES_ACCION,
  TEXTO_TABLA_ACCIONES_FECHA,
  TEXTO_TABLA_ACCIONES_RESPONSABLE,
} from 'constants/commonConstants';
import styles from './tablaAcciones.module.css';

const TablaAcciones = ({ acciones }) => (
  <section className={styles.acciones}>
    <table className={styles.acciones__tabla}>
      <thead>
        <tr>
          <th>{TEXTO_TABLA_ACCIONES_FECHA}</th>
          <th>{TEXTO_TABLA_ACCIONES_ACCION}</th>
          <th>{TEXTO_TABLA_ACCIONES_RESPONSABLE}</th>
        </tr>
      </thead>
      <tbody>
        {acciones.map(accion => (
          <tr key={accion.id}>
            <td>{formatearFechaCompleta(accion.timestamp_access)}</td>
            <td>{accion.descripcion}</td>
            <td>{accion.responsable}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </section>
);

TablaAcciones.propTypes = {
  acciones: PropTypes.arrayOf(PropTypes.object),
};

export default TablaAcciones;
