import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'store/ConfigureStore';

import * as ROUTES from 'constants/RouterConstants';

import HeaderContainer from 'containers/HeaderContainer/HeaderContainer';
import InicioContainer from 'containers/InicioContainer/InicioContainer';
import LogoutProcessContainer from 'containers/LogoutProcessContainer';
import PerfilContainer from 'containers/PerfilContainer/PerfilContainer';
import EstadisticasContainer from 'containers/Estadisticas/EstadisticasContainer';
import ErrorPageContainer from 'containers/ErrorPageContainer';
import VideollamadaContainer from 'containers/Videollamada/Container/VideollamadaContainer';
import VideollamadaSoporteContainer from 'containers/VideollamadaSoporte/VideollamadaSoporteContainer';
import AgendaVideollamadaContainer from 'containers/AgendaVideollamada/AgendaVideollamadaContainer';
import ConfiguracionTurnoContainer from 'containers/ConfiguracionTurno/ListarConfiguracionTurno/ListarConfiguracionTurno';
import CrearConfiguracionTurnoContainer from 'containers/ConfiguracionTurno/CrearConfiguracionTurno/CrearConfiguracionTurnoContainer/CrearConfiguracionTurnoContainer';
import CambioNumeroTelefonoContainer from 'containers/CambioNumeroTelefono/CambioNumeroTelefonoContainer';

import {
  PERMISO_CREAR_CIUDADANO,
  PERMISO_SUPER_AGENTE,
  PERMISO_GESTIONAR_CONFIGURACION_TURNO,
  PERMISO_HACER_TRAMITE_VIDEOLLAMADA,
  PERMISO_PUEDE_ACCEDER_GESTION_AGENTES,
} from 'constants/permissionsConstants';
import GestionAgentesContainer from 'containers/GestionAgentes/GestionAgentesContainer';
import CrearPerfilContainer from 'containers/CrearPerfilContainer/CrearPerfilContainer';
import PaginaErrorContainer from 'containers/PaginaErrorContainer/PaginaErrorContainer';
import BuscadorCiudadanosContainer from 'containers/BuscadorCiudadanosContainer/BuscadorCiudadanosContainer';
import authWrapper from 'enhancers/authWrapper';
import styles from '../index.module.css';

const App = () => (
  <div className={styles['max-height']}>
    <ConnectedRouter history={history}>
      <div>
        <HeaderContainer />
        <Switch>
          <Route exact path={ROUTES.INDEX_ROUTE} component={InicioContainer} />
          <Route
            exact
            path={ROUTES.SEARCH_ROUTE}
            component={authWrapper(BuscadorCiudadanosContainer)}
          />
          <Route
            exact
            path={ROUTES.PROFILE_DETAIL_ROUTE}
            component={authWrapper(PerfilContainer)}
          />
          <Route
            exact
            path={ROUTES.CREATE_PROFILE_ROUTE}
            component={authWrapper(CrearPerfilContainer, [
              PERMISO_CREAR_CIUDADANO,
            ])}
          />
          <Route
            exact
            path={ROUTES.CAMBIAR_TELEFONO_ROUTE}
            component={authWrapper(CambioNumeroTelefonoContainer)}
          />
          <Route
            exact
            path={ROUTES.STATISTICS_ROUTE}
            component={authWrapper(EstadisticasContainer, [
              PERMISO_SUPER_AGENTE,
            ])}
          />
          <Route
            exact
            path={ROUTES.AGENDA_VIDEOLLAMADA}
            component={authWrapper(AgendaVideollamadaContainer, [
              PERMISO_HACER_TRAMITE_VIDEOLLAMADA,
            ])}
          />
          <Route
            exact
            path={ROUTES.AGENDA_VIDEOLLAMADA_SOPORTE}
            component={authWrapper(AgendaVideollamadaContainer, [
              PERMISO_HACER_TRAMITE_VIDEOLLAMADA,
            ])}
          />
          <Route
            exact
            path={ROUTES.VIDEOLLAMADA}
            component={authWrapper(VideollamadaContainer, [
              PERMISO_HACER_TRAMITE_VIDEOLLAMADA,
            ])}
          />
          <Route
            exact
            path={ROUTES.VIDEOLLAMADA_SOPORTE}
            component={authWrapper(VideollamadaSoporteContainer, [
              PERMISO_HACER_TRAMITE_VIDEOLLAMADA,
            ])}
          />
          <Route
            exact
            path={ROUTES.CONFIGURACION_TURNOS}
            component={authWrapper(ConfiguracionTurnoContainer, [
              PERMISO_GESTIONAR_CONFIGURACION_TURNO,
            ])}
          />
          <Route
            exact
            path={ROUTES.CONFIGURACION_TURNOS_CONSULTA}
            component={authWrapper(ConfiguracionTurnoContainer, [
              PERMISO_GESTIONAR_CONFIGURACION_TURNO,
            ])}
          />
          <Route
            exact
            path={ROUTES.CREAR_CONFIGURACION_TURNO}
            component={authWrapper(CrearConfiguracionTurnoContainer, [
              PERMISO_GESTIONAR_CONFIGURACION_TURNO,
            ])}
          />
          <Route
            exact
            path={ROUTES.CREAR_CONFIGURACION_TURNOS_CONSULTA}
            component={authWrapper(CrearConfiguracionTurnoContainer, [
              PERMISO_GESTIONAR_CONFIGURACION_TURNO,
            ])}
          />
          <Route
            exact
            path={ROUTES.GESTION_AGENTES}
            component={authWrapper(GestionAgentesContainer, [
              PERMISO_PUEDE_ACCEDER_GESTION_AGENTES,
            ])}
          />
          <Route
            exact
            path={ROUTES.LOGOUT_PROCESS}
            component={LogoutProcessContainer}
          />
          <Route
            exact
            path={ROUTES.SERVER_ERROR_ROUTE}
            component={ErrorPageContainer}
          />
          <Route
            exact
            path={ROUTES.UNAVAILABLE_ROUTE}
            component={ErrorPageContainer}
          />
          <Route component={PaginaErrorContainer} />
        </Switch>
      </div>
    </ConnectedRouter>
  </div>
);

export default App;
