import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';
import 'styles/bootstrap/bootstrap-grid.css';

const InfoSection = ({ titulo, campos, alMedio }) => {
  const lineas = campos.map(campo => (
    <div className="row" key={campo.label}>
      <span className={`col-md-${alMedio ? 6 : 4} ${styles.izquierda}`}>
        {campo.label}
      </span>
      <span className={`col-md-${alMedio ? 6 : 8} ${styles.derecha}`}>
        {campo.valor}
      </span>
    </div>
  ));

  return (
    <div>
      <fieldset className={styles.fieldset}>
        {titulo && <legend className={styles.titulo}>{titulo}</legend>}
        {lineas}
      </fieldset>
    </div>
  );
};

InfoSection.propTypes = {
  titulo: PropTypes.string,
  campos: PropTypes.array,
  alMedio: PropTypes.bool,
};

export default InfoSection;
