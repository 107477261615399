import React from 'react';
import Spinner from 'react-spinkit';
import PropTypes from 'prop-types';

import Card from '../Card';
import styles from './styles.css';

const CardSpinner = ({ spin, text = 'Cargando...', className }) => (
  <Card className={`spinner ${className}`}>
    {spin ? (
      <div className="spinner__container">
        <Spinner
          className="spin-class"
          name="ball-spin-fade-loader"
          fadeIn="none"
          color={styles.primary}
        />
        <div className="headline spin-text">{text}</div>
      </div>
    ) : null}
  </Card>
);

CardSpinner.propTypes = {
  spin: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default CardSpinner;
