import React from 'react';
import { useSelector } from 'react-redux';

import Header from 'components/Header';
import { currentAgenteSelector, mostrarHeaderSelector } from 'store/selectors';

const HeaderContainer = () => {
  const currentAgente = useSelector(currentAgenteSelector);
  const mostrarHeader = useSelector(mostrarHeaderSelector);

  return <Header showHeader={mostrarHeader} currentAgente={currentAgente} />;
};

export default HeaderContainer;
