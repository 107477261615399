import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// router constants
import { CREATE_PROFILE_ROUTE, PROFILE_ROUTE } from 'constants/RouterConstants';

// constantes
import {
  CREAR_USUARIO_FROM_DNIC,
  TEXTO_BOTON_OBTENER_DATOS,
  TEXTO_DATOS_DNIC,
  TEXTO_EMAIL,
  TEXTO_ERROR_CONSULTAR_DNIC,
  TEXTO_NOMBRE_COMPLETO,
  TEXTO_NOMBRE_EN_CEDULA,
  TEXTO_NO_ENCONTRADO_CONSULTAR_DNIC,
  TEXTO_NUMERO_DOCUMENTO,
  TEXTO_NUMERO_DOCUMENTO_ABREVIADO,
  TEXTO_PRIMER_APELLIDO,
  TEXTO_PRIMER_NOMBRE,
  TEXTO_SEGUNDO_APELLIDO,
  TEXTO_SEGUNDO_NOMBRE,
  TEXTO_SIN_RESULTADOS,
  TEXTO_SIN_RESULTADOS_CEDULA,
} from 'constants/commonConstants';

// permisos
import usePermissions from 'hooks/usePermissions';
import { FF_OBTENER_INFO_DNIC } from 'constants/permissionsConstants';

// api services
import { obtenerInfoDnic } from 'services/apiServices';

// utils
import { uruguayanCIValidator } from 'utils/validators';

// componentes
import Snackbar from 'components/Snackbar';
import InfoSection from 'components/InfoSection';
import Button from 'components/Button';
import ListaItem from '../ListaItem';

// styles
import styles from '../styles.module.css';

const SNACKBAR_DEFAULT = {
  mostrar: false,
  mensaje: '',
};

export const ListaBusqueda = ({
  ciudadanos,
  total,
  textoBusqueda,
  buscoRecien,
}) => {
  const [cargandoInfoDnic, setCargandoInfoDnic] = useState(false);
  const [datosDnic, setDatosDnic] = useState(null);
  const [snackBarInfo, setSnackBarInfo] = useState(SNACKBAR_DEFAULT);
  const [mostrarBotonDnic, setMostrarBotonDnic] = useState(true);

  const history = useHistory();
  const puedeObtenerInfoDnic = usePermissions([FF_OBTENER_INFO_DNIC]);

  const irAlPerfil = cid => {
    history.push(`${PROFILE_ROUTE}/${cid}`);
  };

  useEffect(() => {
    setDatosDnic(null);
    setMostrarBotonDnic(true);
  }, [textoBusqueda]);

  const sinPuntosNiGuion = texto => texto.replaceAll('.', '').replace('-', '');

  const validarCedula = useCallback(
    () => uruguayanCIValidator(sinPuntosNiGuion(textoBusqueda)) === null,
    [textoBusqueda],
  );

  const textoBuscadoEsCedula = validarCedula();

  const toParams = datos =>
    datos.reduce((acc, dato) => {
      acc[dato.param] = dato.value || '';
      return acc;
    }, {});

  const handleCrearUsuario = () => {
    // Abrir una nueva ventana para que el agente no pierda la tab de búsqueda
    const url = new URL(
      `${window.REACT_APP_AGENTES_HOST}${CREATE_PROFILE_ROUTE}`,
    );
    url.search = new URLSearchParams(toParams(datosDnic));
    window.open(url, '_blank');
  };

  const handleObtenerDatosDNIC = async () => {
    try {
      setCargandoInfoDnic(true);
      const response = await obtenerInfoDnic(sinPuntosNiGuion(textoBusqueda));
      setDatosDnic([
        {
          param: 'primerNombre',
          title: TEXTO_PRIMER_NOMBRE,
          value: response.data.primer_nombre,
        },
        {
          param: 'segundoNombre',
          title: TEXTO_SEGUNDO_NOMBRE,
          value: response.data.segundo_nombre,
        },
        {
          param: 'primerApellido',
          title: TEXTO_PRIMER_APELLIDO,
          value: response.data.primer_apellido,
        },
        {
          param: 'segundoApellido',
          title: TEXTO_SEGUNDO_APELLIDO,
          value: response.data.segundo_apellido,
        },
        {
          param: 'nombreEnCedula',
          title: TEXTO_NOMBRE_EN_CEDULA,
          value: response.data.nombre_en_cedula,
        },
        {
          param: 'numeroDocumento',
          title: TEXTO_NUMERO_DOCUMENTO,
          value: response.data.numero_documento,
        },
      ]);
      setMostrarBotonDnic(false);
    } catch (e) {
      let textoSnackBar;
      if (e.status === 404) {
        textoSnackBar = TEXTO_NO_ENCONTRADO_CONSULTAR_DNIC;
      } else {
        textoSnackBar = TEXTO_ERROR_CONSULTAR_DNIC;
      }
      setSnackBarInfo({ mostrar: true, mensaje: textoSnackBar });
    } finally {
      setCargandoInfoDnic(false);
    }
  };

  return (
    <>
      <Snackbar
        desplegar={snackBarInfo.mostrar}
        mensaje={snackBarInfo.mensaje}
        onClose={() => setSnackBarInfo(SNACKBAR_DEFAULT)}
      />
      {ciudadanos.length ? (
        <div>
          <span className={styles['caption-1']}>
            {ciudadanos.length !== 1
              ? `Se encontraron ${total} resultados`
              : `Se encontró ${total} resultado`}
          </span>
          <table className={`${styles.table} col-md-12`} id="tableList">
            <thead>
              <tr className="row">
                <th className={`col-md-3 ${styles.table__header}`}>
                  {TEXTO_NOMBRE_COMPLETO}
                </th>
                <th className={`col-md-3 ${styles.table__header}`}>
                  {TEXTO_NUMERO_DOCUMENTO_ABREVIADO}
                </th>
                <th className={`col-md-4 ${styles.table__header}`}>
                  {TEXTO_EMAIL}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className={styles.table__body}></tr>
              {ciudadanos.map((ciudadano, index) => (
                <ListaItem
                  ciudadano={ciudadano}
                  index={index}
                  key={ciudadano.numero_documento}
                  callback={irAlPerfil}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className={styles.contenedorSinResultados}>
          {mostrarBotonDnic && buscoRecien && (
            <span className={styles['caption-1']}>
              {textoBuscadoEsCedula
                ? TEXTO_SIN_RESULTADOS_CEDULA
                : TEXTO_SIN_RESULTADOS}
            </span>
          )}
          {puedeObtenerInfoDnic &&
            textoBuscadoEsCedula &&
            mostrarBotonDnic &&
            buscoRecien && (
              <Button
                className={styles.buttonModify}
                title={TEXTO_BOTON_OBTENER_DATOS}
                type="primary"
                size="sm"
                side="right"
                callback={handleObtenerDatosDNIC}
                isLoading={cargandoInfoDnic}
              />
            )}
        </div>
      )}
      {datosDnic && (
        <>
          <InfoSection
            titulo={TEXTO_DATOS_DNIC}
            campos={datosDnic.map(campo => ({
              label: campo.title,
              valor: campo.value,
            }))}
          />
          <Button
            title={CREAR_USUARIO_FROM_DNIC}
            type="primary"
            size="lg"
            side="center"
            classname={styles.buttonModify}
            callback={handleCrearUsuario}
          />
        </>
      )}
    </>
  );
};

ListaBusqueda.propTypes = {
  ciudadanos: PropTypes.array,
  total: PropTypes.number,
  textoBusqueda: PropTypes.string,
  buscoRecien: PropTypes.bool,
};

export default ListaBusqueda;
