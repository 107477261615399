/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DATO_REQUERIDO } from 'constants/commonConstants';

import ErrorHelpText from './ErrorHelpText';

import styles from './styles.module.css';

export const SelectField = ({
  register,
  name,
  label,
  required,
  options,
  small,
  error,
  disabled,
}) => {
  const inputClass = small ? 'form-field__select--small' : 'form-field__select';
  const [claseLabel, setClaseLabel] = useState('form-field__label');
  const [claseInput, setClaseInput] = useState(inputClass);

  const valueSelected = value =>
    value !== undefined && value !== 'opcion-por-defecto';

  const validations = {
    validate: valueSelected,
  };

  useEffect(() => {
    if (error) {
      setClaseLabel('form-field__label--error');
      name === 'tipoDocumento'
        ? setClaseInput('form-field__select--small--error')
        : setClaseInput('form-field__select--error');
    } else {
      setClaseLabel('form-field__label');
      name === 'tipoDocumento'
        ? setClaseInput('form-field__select--small')
        : setClaseInput('form-field__select');
    }
  }, [error]);

  return (
    <div className={styles['form-field']}>
      <span>
        <label className={styles[claseLabel]} htmlFor={name}>
          {label}
        </label>
        {!required && (
          <span className={styles['form-field__label--required']}>
            {' '}
            (Opcional)
          </span>
        )}
      </span>
      <span>
        <select
          {...register(name, validations)}
          id={name}
          disabled={disabled}
          className={
            name === 'tipoDocumento'
              ? styles['form-field__select--small']
              : styles[claseInput]
          }
        >
          {options &&
            options.map(obj => (
              <option key={obj.value} value={obj.value}>
                {obj.label}
              </option>
            ))}
        </select>
      </span>
      <ErrorHelpText error={error} msg={DATO_REQUERIDO} small={small} />
    </div>
  );
};

SelectField.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  small: PropTypes.bool,
  error: PropTypes.object,
  disabled: PropTypes.bool,
};

export default SelectField;
