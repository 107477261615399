import React, { useState, useEffect } from 'react';

// api services
import { obtenerEstadisticasDeNivelesCiudadanos } from 'services/apiServices';

// componentes
import ShortCard from 'components/ShortCard/ShortCard';
import Spinner from 'react-spinkit';

import 'components/ShortCard/styles.css';
import styles from './styles.module.css';

const EstadisticasUsuariosPorNivel = () => {
  const [
    cargandoEstadisticasDeNivelesCiudadanos,
    setCargandoEstadisticasDeNivelesCiudadanos,
  ] = useState(true);
  const [nivelesCiudadanos, setNivelesCiudadanos] = useState();
  const [fechaParseada, setFechaParseada] = useState();

  useEffect(() => {
    const cargarDatosDeNivelesCiudadanos = async () => {
      const response = await obtenerEstadisticasDeNivelesCiudadanos();
      if (response.data) {
        setNivelesCiudadanos(response.data);
        const [anio, mes, dia] = response.data.fecha.split('-');
        setFechaParseada(`${dia}/${mes}/${anio}`);
      }
      setCargandoEstadisticasDeNivelesCiudadanos(false);
    };
    cargarDatosDeNivelesCiudadanos();
  }, []);

  return (
    <div>
      {cargandoEstadisticasDeNivelesCiudadanos ? (
        <div className={styles.center}>
          <Spinner
            className="spin-class"
            name="ball-spin-fade-loader"
            fadeIn="none"
          />
        </div>
      ) : (
        <div>
          {nivelesCiudadanos && Object.keys(nivelesCiudadanos).length ? (
            <div>
              <p>Datos obtenidos el {fechaParseada}:</p>
              <div>
                <p>Cantidad de usuarios por nivel:</p>
                <div className="container">
                  <ShortCard
                    titulo="Usuarios Totales"
                    valor={nivelesCiudadanos.total}
                  />
                  <ShortCard
                    titulo="Usuarios Nivel 0"
                    valor={nivelesCiudadanos.total_nivel_mail_sin_verificar}
                  />
                  <ShortCard
                    titulo="Usuarios Nivel 1"
                    valor={nivelesCiudadanos.total_nivel_basico}
                  />
                  <ShortCard
                    titulo="Usuarios Nivel 2"
                    valor={
                      nivelesCiudadanos.total_nivel_intermedio_con_2FA_y_sin_2FA
                    }
                  />
                  <ShortCard
                    titulo="Usuarios Nivel 3"
                    valor={nivelesCiudadanos.total_nivel_avanzado}
                  />
                </div>
              </div>
            </div>
          ) : (
            <p>Todavia no hay datos de usuarios por nivel disponibles</p>
          )}
        </div>
      )}
    </div>
  );
};

export default EstadisticasUsuariosPorNivel;
