import {
  VALIDADOR_TIPO_ERROR,
  VALIDADOR_VAL_ERROR,
  CI_INVALIDA,
  REQUERIDO,
  CORREOS_DEBEN_SER_IGUALES,
  VALID_EMAIL,
  VALID_NAME,
  MAX_LEN_NAMES,
  MAX_LEN_ERROR,
  FORMATO_CI_INVALIDO,
} from 'constants/commonConstants';

//----------------------------------------------------------------------------
// Regexs
//----------------------------------------------------------------------------
export const regOnlyNumbers = /^\d+$/;
const validaName =
  /^[A-Za-zñÑäÄëËïÏöÖüÜáéíóúáéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙÇçÃẼĨÕŨãẽĩõũ\-'`´ ]+$/;
export const validEmailRegex =
  /^[a-zA-Z0-9.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/i;

//----------------------------------------------------------------------------
// Funciones auxiliares
//----------------------------------------------------------------------------
// Validador digito CI.
const digitValidator = ci => {
  if (!ci) return false;
  let a = 0;
  let i = 0;
  let value = ci;
  if (ci.length <= 6) {
    for (i = ci.length; i < 7; i += 1) {
      value = `0${value}`;
    }
  }
  for (i = 0; i < 7; i += 1) {
    a += (parseInt('2987634'[i], 10) * parseInt(value[i], 10)) % 10;
  }
  return a % 10 === 0 ? 0 : 10 - (a % 10);
};

export const formatoCI = ci => {
  const dig = ci[ci.length - 1];
  const num = ci.toString().slice(0, -1);
  if (dig !== digitValidator(num).toString() || ci.length > 8) return false;
  return true;
};

// Validador CI Uruguaya
export const uruguayanCIValidator = ci => {
  if (ci && !regOnlyNumbers.test(ci)) return FORMATO_CI_INVALIDO;
  if (!ci || !formatoCI(ci)) return CI_INVALIDA;
  return null;
};

const maxLengthValidator = value => {
  if (value) {
    return value.length > MAX_LEN_NAMES;
  }
  return false;
};

export const documentValidator = value => value && !value.includes('-');

// ----------------------------------------------------------------------------
// Respuestas de validadores
// ----------------------------------------------------------------------------
export const createResponse = (msg, val, type) => ({
  msg,
  val: `${val}%`,
  classVal: type,
});

// ----------------------------------------------------------------------------
// Validadores
// ----------------------------------------------------------------------------
export const required = value =>
  value
    ? undefined
    : createResponse(REQUERIDO, VALIDADOR_VAL_ERROR, VALIDADOR_TIPO_ERROR);

export const matchEmail = (value, values) =>
  value !== values.email
    ? createResponse(
        CORREOS_DEBEN_SER_IGUALES,
        VALIDADOR_VAL_ERROR,
        VALIDADOR_TIPO_ERROR,
      )
    : undefined;

export const email = value =>
  value &&
  !/^[a-zA-Z0-9.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/i.test(
    value,
  )
    ? createResponse(VALID_EMAIL, VALIDADOR_VAL_ERROR, VALIDADOR_TIPO_ERROR)
    : undefined;

export const validUruguayanCI = value =>
  uruguayanCIValidator(value) === null
    ? undefined
    : createResponse(
        uruguayanCIValidator(value),
        VALIDADOR_VAL_ERROR,
        VALIDADOR_TIPO_ERROR,
      );

export const validName = value =>
  validaName.test(value)
    ? undefined
    : createResponse(VALID_NAME, VALIDADOR_VAL_ERROR, VALIDADOR_TIPO_ERROR);

export const maxLenght = value =>
  maxLengthValidator(value)
    ? createResponse(MAX_LEN_ERROR, VALIDADOR_VAL_ERROR, VALIDADOR_TIPO_ERROR)
    : undefined;
