import { push } from 'connected-react-router';
import { axiosAPI } from 'utils/axiosBackend';
import { API_REQUEST } from 'constants/ApiConstants';
import {
  SERVER_ERROR_ROUTE,
  UNAVAILABLE_ROUTE,
} from 'constants/RouterConstants';
import Auth from 'utils/Auth';

// ----------------------------------------------------------------------------
// Auxiliar para determinar reidrecciones a páginas de error.
// ----------------------------------------------------------------------------
const isFetch = action => {
  const fetchActions = ['FETCH_CIUDADANO_ERROR', 'FETCH_PAISES_ERROR'];
  return fetchActions.some(elem => action === elem);
};

// ----------------------------------------------------------------------------
// ApiMiddleware
// ----------------------------------------------------------------------------
const apiMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    // Si el action no es del tipo API_REQUEST
    // que continue el pipeline de middlewares.
    if (action.type !== API_REQUEST) {
      return next(action);
    }

    // Todas las acciones asincrónicas disparan al comenzar
    // una action PENDING para controlar la latencia de la respuesta.
    dispatch({ type: action.payload.next.PENDING });

    // Se extraen los datos y headers a enviar en el pedido.
    const { method, url, data } = action.payload;
    const headers = Auth.getInstance().isAuthenticated()
      ? { Authorization: `Bearer ${Auth.getInstance().getAuthToken()}` }
      : {};
    // Se retorna la promesa del pedido axios.
    return axiosAPI({
      method,
      url,
      data,
      headers,
    })
      .then(resp => {
        // Si el pedido fue exitoso se dispara la action SUCCESS.
        dispatch({ type: action.payload.next.SUCCESS, payload: resp });

        // Si la action tiene EXTRA actions, se disparan todas.
        action.payload.next.EXTRA_SUCCESS.forEach(extraAction => {
          // FIXME: La redirección se hace de manera muy rebuscada.
          // Utilizando react-router-redux se puede simplificar mucho.
          // Por ahora se deja esta bifurcación, hay que cambiar los casos
          // que ya lo necesitan.
          if (
            typeof extraAction === 'object' &&
            Object.prototype.hasOwnProperty.call(extraAction, 'type') &&
            extraAction.type.startsWith('@@router')
          ) {
            dispatch(extraAction);
          } else {
            dispatch({ type: extraAction, payload: data });
          }
        });
      })
      .catch(err => {
        // En caso de error, se dispara la action ERROR.
        // Si corresponde se redirige a la pantalla de error.
        if (err.request) {
          const code = err.request.status;
          if (
            isFetch(action.payload.next.ERROR) &&
            code >= 400 &&
            code !== 404
          ) {
            dispatch(push(SERVER_ERROR_ROUTE));
          } else if (code === 404 || code === 403) {
            dispatch(push(UNAVAILABLE_ROUTE));
          }
          dispatch({
            type: action.payload.next.ERROR,
            payload: JSON.parse(err.request.response),
            error_code: JSON.stringify(err.request.status),
          });
        } else {
          dispatch({ type: action.payload.next.ERROR, payload: err });
        }
      });
  };

export default apiMiddleware;
