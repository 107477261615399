import React from 'react';
import PropTypes from 'prop-types';

import volver from 'assets/icons/volver.svg';
import styles from './styles.module.css';

const BotonVolver = ({ handlePrevious, texto }) => (
  <div className={styles.volver}>
    <button
      className={styles.volver__boton}
      type="button"
      onClick={handlePrevious}
      aria-label="Volver"
    >
      <img src={volver} className={styles.volver__boton__icono} alt={texto} />
      <span className={styles.volver__boton__texto}>{texto}</span>
    </button>
  </div>
);

BotonVolver.propTypes = {
  handlePrevious: PropTypes.func,
  texto: PropTypes.string,
};

export default BotonVolver;
