import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

// constantes
import {
  CONFIGURACION_AGENDA_DESC,
  CONFIGURACION_AGENDA_TEXT,
  CONTINUAR_BOTON_TEXT,
  CONFIRMAR_BOTON_TEXT,
  VOLVER_BOTON_TEXT,
  IR_MODIFICAR_AGENDA_TEXT,
} from 'constants/commonConstants';

import { tipoTurnos, tipoTurnoFromPath } from 'utils/tipoTurnos';

// services
import { crearConfiguracionTurno } from 'services/apiServices';

// componentes
import Card from 'components/Card';
import Button from 'components/Button';
import dayjs from 'dayjs';
import CrearConfiguracionTurnoForm from '../CrearConfiguracionTurnoForm/CrearConfiguracionTurnoForm';
import CrearConfiguracionTurnoConfirmacion from '../CrearConfiguracionTurnoConfirmacion/CrearConfiguracionTurnoConfirmacion';
import CrearConfiguracionTurnoExito from '../CrearConfiguracionTurnoExito/CrearConfiguracionTurnoExito';

import styles from './styles.module.css';

const CrearConfiguracionTurnoContainer = () => {
  const [fechaDesdeSeleccionada, setFechaDesdeSeleccionada] = useState({});
  const [fechaHastaSeleccionada, setFechaHastaSeleccionada] = useState({});
  const [horaDesdeSeleccionada, setHoraDesdeSeleccionada] = useState({});
  const [horaHastaSeleccionada, setHoraHastaSeleccionada] = useState({});
  const [cantidadAgentes, setCantidadAgentes] = useState('');
  const [paginaActual, setPaginaActual] = useState(0);
  const [cargando, setCargando] = useState(false);

  const tipoTurno = tipoTurnoFromPath(window.location.pathname);

  const history = useHistory();

  const puedeContinuar =
    !isEmpty(fechaDesdeSeleccionada) &&
    !isEmpty(fechaHastaSeleccionada) &&
    !isEmpty(horaDesdeSeleccionada) &&
    !isEmpty(horaHastaSeleccionada) &&
    Number(cantidadAgentes) > 0;

  const botonPrincipalText =
    paginaActual === 0 ? CONTINUAR_BOTON_TEXT : CONFIRMAR_BOTON_TEXT;

  const turnosPorDia =
    Math.round(
      dayjs(horaHastaSeleccionada?.value).diff(
        dayjs(horaDesdeSeleccionada?.value),
        'hour',
        true,
      ) /
        (Number(window.REACT_APP_DURACION_VIDEOLLAMADA) / 60),
    ) * cantidadAgentes;

  const obtenerPaginaActual = () => {
    if (paginaActual === 0) {
      return (
        <CrearConfiguracionTurnoForm
          fechaDesdeSeleccionada={fechaDesdeSeleccionada}
          setFechaDesdeSeleccionada={setFechaDesdeSeleccionada}
          fechaHastaSeleccionada={fechaHastaSeleccionada}
          setFechaHastaSeleccionada={setFechaHastaSeleccionada}
          horaDesdeSeleccionada={horaDesdeSeleccionada}
          setHoraDesdeSeleccionada={setHoraDesdeSeleccionada}
          horaHastaSeleccionada={horaHastaSeleccionada}
          setHoraHastaSeleccionada={setHoraHastaSeleccionada}
          setCantidadAgentes={setCantidadAgentes}
          cantidadAgentes={cantidadAgentes}
          setPaginaActual={setPaginaActual}
        />
      );
    }
    if (paginaActual === 1) {
      return (
        <CrearConfiguracionTurnoConfirmacion
          fechaDesde={fechaDesdeSeleccionada}
          fechaHasta={fechaHastaSeleccionada}
          horaDesde={dayjs(horaDesdeSeleccionada.value)}
          horaHasta={dayjs(horaHastaSeleccionada.value)}
          cantidadAgentes={Number(cantidadAgentes)}
          turnosPorDia={turnosPorDia}
        />
      );
    }
    return <CrearConfiguracionTurnoExito turnosPorDia={turnosPorDia} />;
  };

  const handleBotonPrincipal = async () => {
    if (paginaActual === 0) {
      setPaginaActual(1);
    } else {
      setCargando(true);
      try {
        const fechas = [];
        for (
          let fechaActual = dayjs(fechaDesdeSeleccionada);
          fechaActual <= dayjs(fechaHastaSeleccionada);
          fechaActual = fechaActual.add(1, 'day')
        ) {
          if (fechaActual.day() > 0 && fechaActual.day() < 6) {
            fechas.push(fechaActual.format('YYYY-MM-DD'));
          }
        }
        await crearConfiguracionTurno(
          tipoTurno,
          fechas,
          dayjs(horaDesdeSeleccionada?.value).format('HH:mm'),
          dayjs(horaHastaSeleccionada?.value).format('HH:mm'),
          cantidadAgentes,
        );
        setCargando(false);
        setPaginaActual(2);
      } catch {
        setCargando(false);
      }
    }
  };

  const handleBotonSecundario = () => {
    if (paginaActual === 1) {
      setPaginaActual(0);
    } else {
      history.push(tipoTurnos[tipoTurno].configuracionTurnosUrl);
    }
  };

  return (
    <div className={styles.mainContenedor}>
      <p className={styles.textoPrincipalTitulo}>{CONFIGURACION_AGENDA_TEXT}</p>
      <p className={styles.textoPrincipalDescripcion}>
        {CONFIGURACION_AGENDA_DESC}
      </p>
      <Card>
        <div className={styles.cardContenedor}>
          {obtenerPaginaActual()}
          <div className={styles.botonesContenedor}>
            {paginaActual >= 1 && (
              <Button
                type="secondary"
                size="lg"
                title={
                  paginaActual === 1
                    ? VOLVER_BOTON_TEXT
                    : IR_MODIFICAR_AGENDA_TEXT
                }
                callback={handleBotonSecundario}
                className={styles.botonSecundario}
              />
            )}
            {paginaActual < 2 && (
              <Button
                type="primary"
                size="lg"
                title={botonPrincipalText}
                isLoading={cargando}
                disabled={!puedeContinuar}
                callback={handleBotonPrincipal}
                className={styles.botonPrincipal}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CrearConfiguracionTurnoContainer;
