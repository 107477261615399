import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// react-phone-input
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import es from 'react-phone-input-2/lang/es.json';

// constantes
import {
  CI_CODE,
  LABEL_CORREO_ELECTRONICO,
  LABEL_DOCUMENTO,
  LABEL_PAIS_EMISOR,
  LABEL_PRIMER_APELLIDO,
  LABEL_PRIMER_NOMBRE,
  LABEL_REPETIR_CORREO_ELECTRONICO,
  LABEL_SEGUNDO_APELLIDO,
  LABEL_SEGUNDO_NOMBRE,
  LABEL_TIPO_DOCUMENTO,
  PLACEHOLDER_DOCUMENTO,
  REGEX_ERROR_CORREO,
  REGEX_ERROR_DOCUMENTO,
  SUBTITULO_CREAR_CIUDADANO,
  TEXTO_BOTON_ALTA,
  TEXTO_NUMERO_TELEFONO,
  TEXTO_OPCIONAL,
  TEXTO_PLACEHOLDER_NUMERO_TELEFONO,
  TITULO_CREAR_CIUDADANO,
  UY_CODE,
  VALIDATION_ERROR_CORREO,
  VALIDATION_ERROR_DOCUMENTO,
} from 'constants/commonConstants';

// validadores
import {
  documentValidator,
  formatoCI,
  regOnlyNumbers,
  validEmailRegex,
} from 'utils/validators';

// componentes
import TextField from 'components/HookForm/TextField';
import SelectField from 'components/HookForm/SelectField';
import Card from '../Card';
import Button from '../Button';

// estilos
import styles from './styles.module.css';

const CrearPerfil = ({
  paises,
  crearPerfil,
  creandoPerfil,
  erroresCrearPerfil,
  prefillValues,
}) => {
  const [opcionesPaises, setOpcionesPaises] = useState([]);
  const [opcionesDocumentos, setOpcionesDocumentos] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    trigger,
    setValue,
    formState: { isDirty, isValid, errors, touchedFields },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      paisEmisor: UY_CODE,
      tipoDocumento: CI_CODE,
      primerNombre: prefillValues?.primerNombre,
      segundoNombre: prefillValues?.segundoNombre,
      primerApellido: prefillValues?.primerApellido,
      segundoApellido: prefillValues?.segundoApellido,
      numeroDocumento: prefillValues?.numeroDocumento,
    },
  });

  const watchPaisEmisor = watch('paisEmisor');
  const watchEmail = watch('email');
  const watchRepetirEmail = watch('repetirEmail');
  const watchNumeroTelefono = watch('numeroTelefono');

  const inicializarOpcionesDocumento = () => {
    const listaPaises = paises;
    const obj = listaPaises.filter(pais => pais.codigo === watchPaisEmisor);
    setOpcionesDocumentos(
      obj[0].documentos.map(documento => ({
        value: documento.tipo_documento.codigo,
        label: documento.tipo_documento.nombre,
      })),
    );
  };

  useEffect(() => {
    if (opcionesDocumentos && opcionesDocumentos.length > 0) {
      setValue('tipoDocumento', opcionesDocumentos[0].value.toString());
    }
  }, [opcionesDocumentos]);

  const inicializarOpciones = () => {
    setOpcionesPaises(
      paises.map(pais => ({
        value: pais.codigo,
        label: pais.nombre,
      })),
    );
    inicializarOpcionesDocumento();
  };

  useEffect(() => {
    inicializarOpciones();
  }, [watchPaisEmisor]);

  useEffect(() => {
    if (erroresCrearPerfil?.field === 'numero_documento') {
      setError('numeroDocumento', {
        type: 'custom',
        message: erroresCrearPerfil?.error,
      });
    }
    if (erroresCrearPerfil?.field === 'email') {
      setError('email', {
        type: 'custom',
        message: erroresCrearPerfil?.error,
      });
    }
  }, [erroresCrearPerfil]);

  const handleCrearPerfil = () => {
    const valores = {
      primer_nombre: watch('primerNombre'),
      segundo_nombre: watch('segundoNombre'),
      primer_apellido: watch('primerApellido'),
      segundo_apellido: watch('segundoApellido'),
      tipo_documento: watch('tipoDocumento'),
      numero_documento: watch('numeroDocumento'),
      email: watchEmail,
      numero_telefono: watchNumeroTelefono || '',
      repetir_email: watchRepetirEmail,
      pais_emisor: watchPaisEmisor,
    };
    if (isValid && isDirty && !creandoPerfil) {
      crearPerfil(valores);
    }
  };

  const handleOnFocusNumeroTelefono = valor => {
    if (isEmpty(watchNumeroTelefono) && valor.key !== 'Backspace') {
      setValue('numeroTelefono', '+598');
    }
  };

  const handleOnBlurNumeroTelefono = () => {
    if (watchNumeroTelefono.length <= 4) {
      setValue('numeroTelefono', '');
    }
  };

  return (
    <div className={styles.contenedorPrincipal}>
      <div className={styles.contenedorTitulos}>
        <h1 className={styles.titulo}>{TITULO_CREAR_CIUDADANO}</h1>
        <h2 className={styles.subtitulo}>{SUBTITULO_CREAR_CIUDADANO}</h2>
      </div>
      <Card>
        {/* Primer y segundo nombre */}
        <form
          className={styles.contenedorForm}
          onSubmit={handleSubmit(handleCrearPerfil)}
        >
          <div className={styles.contenedorComponentes}>
            <TextField
              register={register}
              name="primerNombre"
              label={LABEL_PRIMER_NOMBRE}
              type="text"
              required
              error={errors.primerNombre}
            />
            <TextField
              register={register}
              name="segundoNombre"
              label={LABEL_SEGUNDO_NOMBRE}
              type="text"
            />
          </div>
          {/* Primer y segundo apellido */}
          <div className={styles.contenedorComponentes}>
            <TextField
              register={register}
              name="primerApellido"
              label={LABEL_PRIMER_APELLIDO}
              type="text"
              required
              error={errors.primerApellido}
            />
            <TextField
              register={register}
              name="segundoApellido"
              label={LABEL_SEGUNDO_APELLIDO}
              type="text"
            />
          </div>
          {/* Pais emisor y Documento */}
          <div className={styles.contenedorComponentesPais}>
            <SelectField
              register={register}
              name="paisEmisor"
              label={LABEL_PAIS_EMISOR}
              required
              options={opcionesPaises}
            />
            <div className={styles.inputDocumento}>
              <SelectField
                register={register}
                name="tipoDocumento"
                label={LABEL_TIPO_DOCUMENTO}
                small
                required
                disabled={opcionesDocumentos.length === 1}
                options={opcionesDocumentos}
              />
            </div>
            <TextField
              register={register}
              name="numeroDocumento"
              label={LABEL_DOCUMENTO}
              type="text"
              placeholder={PLACEHOLDER_DOCUMENTO}
              required
              small
              validateDocument={
                watchPaisEmisor === UY_CODE ? formatoCI : documentValidator
              }
              validationRegex={watchPaisEmisor === UY_CODE && regOnlyNumbers}
              regexErrorMessage={REGEX_ERROR_DOCUMENTO}
              validationErrorMessage={VALIDATION_ERROR_DOCUMENTO}
              error={errors?.numeroDocumento}
            />
          </div>
          {/* Correo electrónico */}
          <div className={styles.contenedorComponentes}>
            <TextField
              register={register}
              trigger={trigger}
              name="email"
              type="email"
              label={LABEL_CORREO_ELECTRONICO}
              value={watchEmail}
              required
              validationRegex={validEmailRegex}
              regexErrorMessage={REGEX_ERROR_CORREO}
              matchValue={watchEmail}
              error={errors?.email}
              touched={touchedFields.email}
              validationErrorMessage={VALIDATION_ERROR_CORREO}
            />
            <TextField
              register={register}
              name="repetirEmail"
              type="email"
              label={LABEL_REPETIR_CORREO_ELECTRONICO}
              value={watchRepetirEmail}
              required
              regexErrorMessage={REGEX_ERROR_CORREO}
              matchValue={watchEmail}
              error={errors?.repetirEmail}
              validationErrorMessage={VALIDATION_ERROR_CORREO}
              validationRegex={validEmailRegex}
              showSuccess={touchedFields.repetirEmail}
            />
          </div>
          {window.REACT_APP_HABILITAR_NUMERO_TELEFONO_CREAR_PERFIL && (
            <div className={styles.contenedorComponentesTelefono}>
              <div className={styles.contenedorInternoTelefono}>
                <label className={styles.labelTelefono}>
                  {TEXTO_NUMERO_TELEFONO}
                </label>
                <label className={styles.labelTelefonoParentesis}>
                  {TEXTO_OPCIONAL}
                </label>
                <div className={styles.contenedorNumeroTelefono}>
                  <PhoneInput
                    country="uy"
                    placeholder={TEXTO_PLACEHOLDER_NUMERO_TELEFONO}
                    inputClass={styles.inputNumeroTelefono}
                    value={watchNumeroTelefono}
                    onChange={valor => {
                      setValue('numeroTelefono', valor);
                    }}
                    localization={es}
                    enableLongNumbers
                    preferredCountries={['uy']}
                    disableCountryCode={isEmpty(watchNumeroTelefono)}
                    onFocus={handleOnFocusNumeroTelefono}
                    onBlur={handleOnBlurNumeroTelefono}
                  />
                </div>
              </div>
            </div>
          )}
          {/* Continuar */}
          <div className={styles.contenedorComponentes}>
            <Button
              className={styles.boton}
              isSubmit
              type="primary"
              size="lg"
              title={TEXTO_BOTON_ALTA}
              disabled={!isDirty || !isValid}
              isLoading={creandoPerfil}
            />
          </div>
        </form>
      </Card>
    </div>
  );
};

CrearPerfil.propTypes = {
  paises: PropTypes.array,
  crearPerfil: PropTypes.func,
  creandoPerfil: PropTypes.bool,
  erroresCrearPerfil: PropTypes.object,
  prefillValues: PropTypes.shape({
    primerNombre: PropTypes.string,
    segundoNombre: PropTypes.string,
    primerApellido: PropTypes.string,
    segundoApellido: PropTypes.string,
    numeroDocumento: PropTypes.string,
  }),
};

export default CrearPerfil;
