import React, { useState } from 'react';
import PropTypes from 'prop-types';

// constantes
import {
  MODAL_CONFIRMAR_CAMBIO_DATOS,
  TEXTO_MODAL_NEGATIVO,
  TEXTO_MODAL_POSITIVO,
} from 'constants/commonConstants';

// componentes
import UpdateInfoCiudadanoForm from 'components/UpdateInfoCiudadanoForm';
import InfoCiudadanoConBoton from 'components/InfoCiudadanoConBoton';
import ModalConfirmarAccion from '../ModalConfirmarAccion';

// estilos
import styles from './styles.module.css';

/**
 * Este componente muestra los datos ingresado como campos, permitiendo realizar una acción
 * cualquiera con un botón o llevar a cabo la modificación de los datos mencionados.
 * Cuando se trata de una modificación el componente renderiza un formulario con los datos precargados
 * y cuenta con un modal para confirmar los cambios.
 *
 * @param titulo - El nombre del InfoSection.
 * @param mostrarBoton - Determina si se muestra el botón de la acción o no.
 * @param textoBoton - Determina el texto del botón.
 * @param contenidoExtra - Determina si se muestra algo adicional en el InfoSection.
 * @param campos - Determina los campos a mostrar/modificar.
 * @param textoSinDatos - En caso de no tener campos a mostrar, el componente renderiza este texto.
 * @param handleAccion - La función async a llevar a cabo.
 * @param accionEsModificar - Determina si la acción es una modificación o no.
 * @returns {React.JSX.Element} - El JSX que renderiza el componente.
 */
const InfoSectionConAccion = ({
  titulo,
  mostrarBoton,
  textoBoton,
  contenidoExtra,
  campos,
  textoSinDatos,
  handleAccion,
  accionEsModificar,
  handleBotonExcepcional,
}) => {
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [datosFormulario, setDatosFormulario] = useState(null);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [cargando, setCargando] = useState(false);

  const realizarAccionModificar = async () => {
    setCargando(true);
    await handleAccion(datosFormulario);
    setMostrarModal(false);
    setMostrarFormulario(false);
    setCargando(false);
  };

  const realizarAccion = async () => {
    setCargando(true);
    await handleAccion();
    setCargando(false);
  };

  const guardarFormulario = datos => {
    setDatosFormulario(datos);
    setMostrarModal(true);
  };

  const handleBoton = () =>
    accionEsModificar ? setMostrarFormulario(true) : realizarAccion();

  let contenidoPrincipal;
  if (mostrarFormulario) {
    contenidoPrincipal = (
      <>
        <UpdateInfoCiudadanoForm
          campos={campos}
          modificarCallback={guardarFormulario}
          botonVolverCallback={() => {
            setMostrarFormulario(false);
          }}
        />
        <ModalConfirmarAccion
          isOpen={mostrarModal}
          text={MODAL_CONFIRMAR_CAMBIO_DATOS}
          buttonYesCallback={realizarAccionModificar}
          buttonNoCallback={() => setMostrarModal(false)}
          buttonTextPositiveAction={TEXTO_MODAL_POSITIVO}
          buttonTextNegativeAction={TEXTO_MODAL_NEGATIVO}
          onClose={() => setMostrarModal(false)}
          loading={cargando}
        />
      </>
    );
  } else {
    contenidoPrincipal = (
      <>
        <InfoCiudadanoConBoton
          textoSinDatos={textoSinDatos}
          campos={campos}
          textoBoton={textoBoton}
          handleBoton={handleBotonExcepcional || handleBoton}
          mostrarBoton={mostrarBoton}
          cargando={cargando}
        />
        {contenidoExtra}
      </>
    );
  }

  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.titulo}>{titulo}</legend>
      {contenidoPrincipal}
    </fieldset>
  );
};

InfoSectionConAccion.propTypes = {
  titulo: PropTypes.string,
  campos: PropTypes.array,
  mostrarBoton: PropTypes.bool,
  textoBoton: PropTypes.string,
  contenidoExtra: PropTypes.node,
  handleAccion: PropTypes.func,
  textoSinDatos: PropTypes.string,
  accionEsModificar: PropTypes.bool,
  handleBotonExcepcional: PropTypes.func,
};

export default InfoSectionConAccion;
