import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import {
  EXPLICAR_MOTIVO_RECHAZO,
  MOTIVO_RECHAZO,
  MOTIVO_RECHAZO_1,
  MOTIVO_RECHAZO_2,
  MOTIVO_RECHAZO_3,
  MOTIVO_RECHAZO_4,
  MOTIVO_RECHAZO_5,
  MOTIVO_RECHAZO_6,
  MOTIVO_RECHAZO_7,
  MOTIVO_RECHAZO_8,
  MOTIVO_RECHAZO_9,
  MOTIVO_RECHAZO_OBLIGATORIO,
} from 'constants/commonConstants';

// componentes
import TextAreaField from 'components/HookForm/TextAreaField';
import Button from 'components/Button';
import { RadioButtonField } from 'components/RadioButton';

import ModalBox from 'components/Modal';
import styles from './motivoRechazo.module.css';

const MotivoRechazoConfirmacion = ({
  onSubmit,
  cargandoRechazar,
  modalAbierto,
  onClose,
  textoModal,
  textoConfirmar,
  textoCancelar,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const modalChildren = () => (
    <form className={styles.formulario} onSubmit={handleSubmit(onSubmit)}>
      <RadioButtonField
        register={register}
        name="motivoRechazo"
        label={MOTIVO_RECHAZO_1}
        value={1}
        required
      />
      <RadioButtonField
        register={register}
        name="motivoRechazo"
        label={MOTIVO_RECHAZO_2}
        value={2}
        required
      />
      <RadioButtonField
        register={register}
        name="motivoRechazo"
        label={MOTIVO_RECHAZO_3}
        value={3}
        required
      />
      <RadioButtonField
        register={register}
        name="motivoRechazo"
        label={MOTIVO_RECHAZO_4}
        value={4}
        required
      />
      <RadioButtonField
        register={register}
        name="motivoRechazo"
        label={MOTIVO_RECHAZO_5}
        value={5}
        required
      />
      <RadioButtonField
        register={register}
        name="motivoRechazo"
        label={MOTIVO_RECHAZO_6}
        value={6}
        required
      />
      <RadioButtonField
        register={register}
        name="motivoRechazo"
        label={MOTIVO_RECHAZO_7}
        value={7}
        required
      />
      <RadioButtonField
        register={register}
        name="motivoRechazo"
        label={MOTIVO_RECHAZO_9}
        value={9}
        required
      />
      <RadioButtonField
        register={register}
        name="motivoRechazo"
        label={MOTIVO_RECHAZO_8}
        value={8}
        required
      />
      {errors.motivoRechazo && (
        <span className={styles.motivoRechazoObligatorio}>
          {MOTIVO_RECHAZO_OBLIGATORIO}
        </span>
      )}
      <TextAreaField
        register={register}
        name="motivoRechazoExtra"
        label={MOTIVO_RECHAZO}
        type="text"
        maxLength={400}
        error={errors.motivoRechazoExtra}
        placeholder={EXPLICAR_MOTIVO_RECHAZO}
        className={styles.textArea}
      />
      <div className={styles.contenedorBotones}>
        <Button
          type="primary"
          size="lg"
          title={textoConfirmar}
          isSubmit
          isLoading={cargandoRechazar}
          className={styles.boton}
        />
        <Button
          type="secondary"
          size="lg"
          title={textoCancelar}
          className={styles.boton}
          callback={onClose}
        />
      </div>
    </form>
  );

  return (
    <ModalBox abierto={modalAbierto} onClose={onClose} texto={textoModal}>
      {modalChildren()}
    </ModalBox>
  );
};

MotivoRechazoConfirmacion.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  cargandoRechazar: PropTypes.bool,
  modalAbierto: PropTypes.bool,
  textoModal: PropTypes.string,
  textoCancelar: PropTypes.string,
  textoConfirmar: PropTypes.string,
};

export default MotivoRechazoConfirmacion;
