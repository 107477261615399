import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

// MUI
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';

// componentes
import ButtonFlat from 'components/ButtonFlat';

// constantes
import {
  TEXTO_CAMPO_ACTIVO,
  TEXTO_CAMPO_ESCRITURA,
  TEXTO_CAMPO_RESPONSABLE,
  TEXTO_CAMPO_VIDEOLLAMADA,
  TEXTO_CEDULA,
  TEXTO_NOMBRE,
  TEXTO_ORGANISMO,
  TEXTO_PERMISOS,
  TEXTO_QUITAR,
} from 'constants/commonConstants';

// styles
import styles from './styles.module.css';

// Estos al ser una propiedad embebida de MUI solo se puede hacer en base a su forma de modificar los estilos
const sxBorrarBorde = {
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-menu': {
    overflowY: 'scroll',
  },
};

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: '315px',
    },
  },
};

const GestionAgentesFiltrado = ({
  filtros,
  setFiltros,
  organismosDisponibles,
  obtenerAgentes,
  esSuperAgente,
}) => {
  const debouncedFiltrado = useCallback(
    debounce(filtrosActuales => {
      obtenerAgentes(null, filtrosActuales);
    }, 500),
    [],
  );

  const permisosDisponibles = useMemo(() => {
    const permisos = [
      TEXTO_CAMPO_ACTIVO,
      TEXTO_CAMPO_ESCRITURA,
      TEXTO_CAMPO_VIDEOLLAMADA,
    ];
    if (esSuperAgente) permisos.push(TEXTO_CAMPO_RESPONSABLE);
    return permisos;
  }, [esSuperAgente]);

  const handleCedula = e => {
    const nuevosFiltros = { ...filtros, cedula: e.target.value };
    setFiltros(nuevosFiltros);
    debouncedFiltrado(nuevosFiltros);
  };

  const handleNombre = e => {
    const nuevosFiltros = { ...filtros, nombre: e.target.value };
    setFiltros(nuevosFiltros);
    debouncedFiltrado(nuevosFiltros);
  };

  const handleOrganismo = e => {
    const nuevosFiltros = { ...filtros, organismo: e.target.value };
    setFiltros(nuevosFiltros);
    obtenerAgentes(null, nuevosFiltros);
  };

  const handlePermisos = e => {
    const nuevosFiltros = { ...filtros, permisos: e.target.value };
    setFiltros(nuevosFiltros);
    obtenerAgentes(null, nuevosFiltros);
  };

  const handleQuitarFiltros = () => {
    const sinFiltros = {
      organismo: '',
      cedula: '',
      nombre: '',
      permisos: [],
    };
    setFiltros(sinFiltros);
    obtenerAgentes(null, sinFiltros);
  };

  return (
    <div className={styles.contenedorSelects}>
      <div>
        <InputLabel id="cedula" className={styles.label}>
          {TEXTO_CEDULA}
        </InputLabel>
        <TextField
          labelid="cedula"
          className={styles.textField}
          name="cedula"
          onChange={handleCedula}
          sx={sxBorrarBorde}
          value={filtros?.cedula}
        />
      </div>
      <div>
        <InputLabel id="nombre" className={styles.label}>
          {TEXTO_NOMBRE}
        </InputLabel>
        <TextField
          labelid="nombre"
          className={styles.textFieldExtendido}
          name="nombre"
          onChange={handleNombre}
          sx={sxBorrarBorde}
          value={filtros?.nombre}
        />
      </div>
      {esSuperAgente && (
        <div>
          <InputLabel id="organismo" className={styles.label}>
            {TEXTO_ORGANISMO}
          </InputLabel>
          <Select
            className={styles.select}
            MenuProps={menuProps}
            labelid="organismo"
            value={filtros?.organismo}
            onChange={handleOrganismo}
            sx={sxBorrarBorde}
          >
            {organismosDisponibles?.map(organismo => (
              <MenuItem key={organismo.nombre} value={organismo.nombre}>
                {organismo.nombre}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <div>
        <InputLabel id="permisos" className={styles.label}>
          {TEXTO_PERMISOS}
        </InputLabel>
        <Select
          labelid="permisos"
          className={styles.select}
          value={filtros?.permisos}
          onChange={handlePermisos}
          sx={sxBorrarBorde}
          multiple
        >
          {permisosDisponibles?.map(permiso => (
            <MenuItem key={permiso} value={permiso}>
              {permiso}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={styles.botonesContenedor}>
        <ButtonFlat
          text={TEXTO_QUITAR}
          className={styles.botonPrimario}
          handleOnClick={handleQuitarFiltros}
        />
      </div>
    </div>
  );
};

GestionAgentesFiltrado.propTypes = {
  filtros: PropTypes.object,
  setFiltros: PropTypes.func,
  organismosDisponibles: PropTypes.array,
  obtenerAgentes: PropTypes.func,
  esSuperAgente: PropTypes.bool,
};

export default GestionAgentesFiltrado;
