/* eslint-disable react/prop-types */
import React from 'react';
import './styles.css';
import 'styles/bootstrap/bootstrap-grid.css';

const InfoSection = props => {
  const lineas = props.info_array.map(object => (
    <div className="row box--row" key={object.title}>
      <span
        className={`col-md-${props.small ? 6 : 4}
                box--row__span box--row__span_title`}
      >
        {object.title}
      </span>
      <span
        className={`col-md-${props.small ? 6 : 8}
                box--row__span box--row__span_value${
                  props.small ? '-left' : ''
                }`}
      >
        {object.value}
      </span>
    </div>
  ));

  return (
    <div>
      <fieldset className="box">
        {props.title ? (
          <legend className="box__title">{props.title}</legend>
        ) : null}
        {lineas}
      </fieldset>
    </div>
  );
};

export default InfoSection;
