/* eslint-disable react/prop-types */
import React from 'react';

import menuDownWhite from 'assets/icons/menu-down--white.png';
import menuDownWhite2x from 'assets/icons/menu-down--white@2x.png';
import menuDownWhite3x from 'assets/icons/menu-down--white@3x.png';

import menuUpWhite from 'assets/icons/menu-up--white.png';
import menuUpWhite2x from 'assets/icons/menu-up--white@2x.png';
import menuUpWhite3x from 'assets/icons/menu-up--white@3x.png';

import './styles.css';

const ButtonMenuNavbar = ({ handleOpenMenu, isMenuOpen }) => (
  // eslint-disable-next-line react/button-has-type
  <button className="button-menu-navbar" onClick={handleOpenMenu}>
    <span>MENU</span>
    {isMenuOpen ? (
      <img
        src={menuUpWhite}
        srcSet={`${menuUpWhite2x} 2x, ${menuUpWhite3x} 3x`}
        alt="Menú para ver más opciones: Abierto"
      />
    ) : (
      <img
        src={menuDownWhite}
        srcSet={`${menuDownWhite2x} 2x, ${menuDownWhite3x} 3x`}
        alt="Menú para ver más opciones: Cerrado"
      />
    )}
  </button>
);

export default ButtonMenuNavbar;
