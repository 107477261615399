import React from 'react';
import PropTypes from 'prop-types';

// constantes
import {
  textoModalCi,
  TEXTO_BOTON_PRIMARIO,
  TEXTO_BOTON_SECUNDARIO,
  TEXTO_CAMPO_ACTIVO,
  TEXTO_CAMPO_ESCRITURA,
  TEXTO_CAMPO_RESPONSABLE,
  TEXTO_CAMPO_VIDEOLLAMADA,
  TEXTO_TITULO_MODAL_EDITAR_AGENTE,
  TEXTO_TOOLTIP_ACTIVO,
  TEXTO_TOOLTIP_ESCRITURA,
  TEXTO_TOOLTIP_ESCRITURA_NO_PERMITIDO,
  TEXTO_TOOLTIP_RESPONSABLE,
  TEXTO_TOOLTIP_VIDEOLLAMADA,
} from 'constants/commonConstants';

// componentes
import TitleCard from 'components/TitleCard';
import Tooltip from 'components/Tooltip';
import ButtonFlat from 'components/ButtonFlat';

// MUI
import { Checkbox, FormControlLabel } from '@mui/material';

// styles
import ModalBox from 'components/Modal';
import styles from './styles.module.css';

const GestionAgentesModal = ({
  modalEditarAbierto,
  setModalEditarAbierto,
  agenteAEditar,
  handleBotonPrincipal,
  cargandoEditarPermisos,
  checkboxesPermisos,
  setCheckboxesPermisos,
  esSuperAgente,
}) => {
  const handleEditarPermisoActivo = () => {
    const copiaCheckboxesPermisos = checkboxesPermisos;
    setCheckboxesPermisos({
      ...copiaCheckboxesPermisos,
      activo: !copiaCheckboxesPermisos.activo,
    });
  };

  const handleEditarPermisoResponsable = () => {
    const copiaCheckboxesPermisos = checkboxesPermisos;
    setCheckboxesPermisos({
      ...copiaCheckboxesPermisos,
      responsable: !copiaCheckboxesPermisos.responsable,
    });
  };

  const handleEditarPermisoEscritura = () => {
    const copiaCheckboxesPermisos = checkboxesPermisos;
    setCheckboxesPermisos({
      ...copiaCheckboxesPermisos,
      escritura: !copiaCheckboxesPermisos.escritura,
    });
  };

  const handleEditarPermisoVideollamada = () => {
    const copiaCheckboxesPermisos = checkboxesPermisos;
    setCheckboxesPermisos({
      ...copiaCheckboxesPermisos,
      videollamada: !copiaCheckboxesPermisos.videollamada,
    });
  };

  const modalChildren = () => (
    <div className={styles.modalContenedor}>
      <TitleCard
        className={styles.titulo}
        title={TEXTO_TITULO_MODAL_EDITAR_AGENTE(agenteAEditar?.nombre_completo)}
      />
      <p className={styles.textosInfoAgente}>
        {textoModalCi(agenteAEditar?.numero_documento)}
      </p>
      <div className={styles.contenedorCheckboxes}>
        <Tooltip title={TEXTO_TOOLTIP_ACTIVO} placement="right" arrow>
          <FormControlLabel
            control={
              <Checkbox
                className={styles.checkboxMUI}
                checked={checkboxesPermisos.activo}
                onChange={handleEditarPermisoActivo}
              />
            }
            label={TEXTO_CAMPO_ACTIVO}
          />
        </Tooltip>

        <Tooltip
          title={
            agenteAEditar?.organismo?.escritura ||
            agenteAEditar?.organismo === null
              ? TEXTO_TOOLTIP_ESCRITURA
              : TEXTO_TOOLTIP_ESCRITURA_NO_PERMITIDO
          }
          placement="right"
          arrow
        >
          <FormControlLabel
            control={
              <Checkbox
                className={
                  agenteAEditar?.organismo?.escritura ||
                  agenteAEditar?.organismo === null
                    ? styles.checkboxMUI
                    : ''
                }
                checked={checkboxesPermisos.escritura}
                onChange={handleEditarPermisoEscritura}
                disabled={
                  !(
                    agenteAEditar?.organismo?.escritura ||
                    agenteAEditar?.organismo === null
                  )
                }
              />
            }
            label={TEXTO_CAMPO_ESCRITURA}
          />
        </Tooltip>
        {esSuperAgente && (
          <>
            <Tooltip title={TEXTO_TOOLTIP_VIDEOLLAMADA} placement="right" arrow>
              <FormControlLabel
                control={
                  <Checkbox
                    className={styles.checkboxMUI}
                    checked={checkboxesPermisos.videollamada}
                    onChange={handleEditarPermisoVideollamada}
                  />
                }
                label={TEXTO_CAMPO_VIDEOLLAMADA}
              />
            </Tooltip>
            <Tooltip title={TEXTO_TOOLTIP_RESPONSABLE} placement="right" arrow>
              <FormControlLabel
                control={
                  <Checkbox
                    className={styles.checkboxMUI}
                    checked={checkboxesPermisos.responsable}
                    onChange={handleEditarPermisoResponsable}
                  />
                }
                label={TEXTO_CAMPO_RESPONSABLE}
              />
            </Tooltip>
          </>
        )}
      </div>
      <div className={styles.botonesContenedor}>
        <ButtonFlat
          text={TEXTO_BOTON_SECUNDARIO}
          className={styles.botonSecundario}
          handleOnClick={() => setModalEditarAbierto(false)}
          isLoading={cargandoEditarPermisos}
        />
        <ButtonFlat
          text={TEXTO_BOTON_PRIMARIO}
          handleOnClick={handleBotonPrincipal}
          className={styles.botonPrimario}
        />
      </div>
    </div>
  );

  return (
    <ModalBox
      abierto={modalEditarAbierto}
      onClose={() => setModalEditarAbierto(false)}
    >
      {' '}
      {modalChildren()}
    </ModalBox>
  );
};

GestionAgentesModal.propTypes = {
  modalEditarAbierto: PropTypes.bool,
  setModalEditarAbierto: PropTypes.func,
  agenteAEditar: PropTypes.object,
  handleBotonPrincipal: PropTypes.func,
  cargandoEditarPermisos: PropTypes.bool,
  checkboxesPermisos: PropTypes.object,
  setCheckboxesPermisos: PropTypes.func,
  esSuperAgente: PropTypes.bool,
};

export default GestionAgentesModal;
