import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  MENSAJE_NO_VERIFICADO,
  MENSAJE_VERIFICADO,
  TOMAR_CAPTURA,
  ACEPTAR_SOLICITUD_TEXT,
  RECHAZAR_SOLICITUD_TEXT,
  obtenerSolicitudAceptadaText,
  SOLICITUD_RECHAZADA_TEXT,
  ERROR_ACEPTAR_TRAMITE,
  ERROR_RECHAZAR_TRAMITE,
  IR_AGENDA_TEXT,
  CONFIRMAR_RECHAZAR_VIDEOLLAMADA_TEXT,
  VOLVER_A_VIDEOLLAMADA,
  RECHAZAR_TRAMITE,
  AGUARDAR_PARTICIPANTES,
} from 'constants/commonConstants';

import { AGENDA_VIDEOLLAMADA } from 'constants/RouterConstants';
import { fetchAgente } from 'actions/agente';
import { aceptarTramite, rechazarTramite } from 'services/apiServices';

import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Alert from 'components/Alert';
import MotivoRechazo from '../MotivoRechazo/MotivoRechazo';
import styles from './verificacion.module.css';

const Verificacion = ({
  verificado,
  cargandoCaptura,
  capturarPantalla,
  errorVerificacion,
  id,
  nombrePersona,
  numeroParticipantes,
  textoCargandoCaptura,
  tramiteValidado,
  setTramiteValidado,
}) => {
  const [cargandoRechazar, setCargandoRechazar] = useState(false);
  const [cargandoAceptar, setCargandoAceptar] = useState(false);
  const [errorTramiteValidado, setErrorTramiteValidado] = useState(false);
  const [modalColgarAbierto, setModalColgarAbierto] = useState(false);

  const dispatch = useDispatch();

  const handleAceptarSolicitud = async () => {
    setCargandoAceptar(true);
    try {
      await aceptarTramite(id);
      setCargandoAceptar(false);
      setTramiteValidado({ decision: true });
    } catch {
      setCargandoAceptar(false);
      setErrorTramiteValidado(true);
    }
  };

  const handleRechazarSolicitud = async data => {
    setCargandoRechazar(true);
    try {
      await rechazarTramite(id, data.motivoRechazo, data.motivoRechazoExtra);
      setCargandoRechazar(false);
      setTramiteValidado({ decision: false });
    } catch {
      setCargandoRechazar(false);
      setErrorTramiteValidado(true);
    }
  };

  const botonCaptura = deshabilitar => (
    <Button
      type="primary"
      size="lg"
      title={TOMAR_CAPTURA}
      isLoading={cargandoCaptura}
      className={styles.boton}
      callback={capturarPantalla}
      disabled={deshabilitar}
    />
  );

  const botonAceptar = (
    <Button
      type="primary"
      size="lg"
      title={ACEPTAR_SOLICITUD_TEXT}
      isLoading={cargandoAceptar}
      className={styles.boton}
      callback={handleAceptarSolicitud}
    />
  );

  const botonRechazar = (
    <>
      <Button
        type="secondary"
        size="lg"
        title={RECHAZAR_SOLICITUD_TEXT}
        className={styles.boton_secundario}
        callback={() => setModalColgarAbierto(true)}
      />
      <MotivoRechazo
        onSubmit={handleRechazarSolicitud}
        cargandoRechazar={cargandoRechazar}
        onClose={() => setModalColgarAbierto(false)}
        modalAbierto={modalColgarAbierto}
        textoModal={CONFIRMAR_RECHAZAR_VIDEOLLAMADA_TEXT}
        textoConfirmar={RECHAZAR_TRAMITE}
        textoCancelar={VOLVER_A_VIDEOLLAMADA}
      />
    </>
  );

  const botonIrAgenda = (
    <Button
      type="secondary"
      size="lg"
      title={IR_AGENDA_TEXT}
      className={styles.boton_secundario}
      callback={() => {
        dispatch(fetchAgente());
      }}
    />
  );

  if (tramiteValidado && errorTramiteValidado) {
    return (
      <Alert
        text={
          tramiteValidado.decision
            ? ERROR_ACEPTAR_TRAMITE
            : ERROR_RECHAZAR_TRAMITE
        }
        type="error"
      />
    );
  }

  if (tramiteValidado) {
    return (
      <>
        <Alert
          text={
            tramiteValidado.decision
              ? obtenerSolicitudAceptadaText(nombrePersona.split(' ')[0])
              : SOLICITUD_RECHAZADA_TEXT
          }
          type={tramiteValidado.decision ? 'info' : 'warning'}
        />
        <div className={styles.botonesContenedor}>
          <Link to={AGENDA_VIDEOLLAMADA}>{botonIrAgenda}</Link>
        </div>
      </>
    );
  }

  const puedeTomarCaptura = numeroParticipantes <= 1;

  if (errorVerificacion) {
    return (
      <>
        <Alert text={errorVerificacion} type="error" />
        {botonCaptura(false)}
      </>
    );
  }
  if (verificado === true) {
    return (
      <>
        <Alert text={MENSAJE_VERIFICADO} type="info" />
        <div className={styles.botonesContenedor}>
          {botonAceptar}
          {botonRechazar}
        </div>
      </>
    );
  }
  if (verificado === false) {
    return (
      <>
        <Alert text={MENSAJE_NO_VERIFICADO} type="warning" />
        <div className={styles.botonesContenedor}>
          {botonCaptura(puedeTomarCaptura)}
          {botonRechazar}
        </div>
        {puedeTomarCaptura && (
          <Alert text={AGUARDAR_PARTICIPANTES} type="info" />
        )}
      </>
    );
  }
  // Si no está verificado aún, se muestra el boton de tomar captura
  return (
    <>
      <div className={styles.botonesContenedor}>
        {botonCaptura(puedeTomarCaptura)}
      </div>
      {textoCargandoCaptura && (
        <p className={styles.textoCargandoCaptura}>{textoCargandoCaptura}</p>
      )}
      {puedeTomarCaptura && <Alert text={AGUARDAR_PARTICIPANTES} type="info" />}
    </>
  );
};

Verificacion.propTypes = {
  verificado: PropTypes.bool,
  cargandoCaptura: PropTypes.bool,
  capturarPantalla: PropTypes.func,
  errorVerificacion: PropTypes.string,
  id: PropTypes.string,
  nombrePersona: PropTypes.string,
  numeroParticipantes: PropTypes.number,
  textoCargandoCaptura: PropTypes.string,
  tramiteValidado: PropTypes.bool,
  setTramiteValidado: PropTypes.func,
};

export default Verificacion;
