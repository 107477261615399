import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { isEmpty } from 'lodash';

// componentes
import Calendario from 'components/Calendario';

// assets
import menuAbajo from 'assets/icons/menu-down--black.png';
import menuArriba from 'assets/icons/menu-up--black.png';

import styles from './styles.module.css';

const CalendarioDesdeHastaInput = ({
  texto,
  fechaDesdeSeleccionada,
  setFechaDesdeSeleccionada,
  fechaHastaSeleccionada,
  setFechaHastaSeleccionada,
}) => {
  const [mostrarCalendario, setMostrarCalendario] = useState(false);

  const handleSeleccionarFecha = fechas => {
    setFechaDesdeSeleccionada(dayjs(fechas[0]));
    setFechaHastaSeleccionada(dayjs(fechas[1]));
    setMostrarCalendario(false);
  };

  dayjs.extend(updateLocale);
  dayjs.updateLocale('es', {
    weekdays: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
  });

  const textoDesde = !isEmpty(fechaDesdeSeleccionada)
    ? dayjs(fechaDesdeSeleccionada).locale('es').format('dddd, D MMM YYYY')
    : '';
  const textoHasta = !isEmpty(fechaHastaSeleccionada)
    ? dayjs(fechaHastaSeleccionada).locale('es').format('dddd, D MMM YYYY')
    : '';

  return (
    <div className={styles.contenedorPrincipal}>
      <p className={styles.texto}>{texto}</p>
      <div className={styles.contenedorInputs}>
        <button
          type="button"
          className={styles.input}
          onClick={() => {
            setMostrarCalendario(!mostrarCalendario);
          }}
        >
          {textoDesde}
          <img
            src={mostrarCalendario ? menuArriba : menuAbajo}
            className={
              !isEmpty(fechaDesdeSeleccionada)
                ? styles.menuDown
                : styles.menuDownNoValue
            }
            alt="dropdown"
          />
        </button>
        <button
          type="button"
          className={styles.input}
          onClick={() => {
            setMostrarCalendario(!mostrarCalendario);
          }}
        >
          {textoHasta}
          <img
            src={mostrarCalendario ? menuArriba : menuAbajo}
            className={
              !isEmpty(fechaHastaSeleccionada)
                ? styles.menuDown
                : styles.menuDownNoValue
            }
            alt="dropdown"
          />
        </button>
      </div>
      <div className={styles.calendario}>
        {mostrarCalendario && (
          <Calendario
            setFechasSeleccionadas={handleSeleccionarFecha}
            fechaMinima={dayjs().add(1, 'day').toDate()}
            tileDisabled={fecha => fecha.getDay() === 0 || fecha.getDay() === 6}
            esRango
            fechasSeleccionadas={
              !isEmpty(fechaDesdeSeleccionada) &&
              !isEmpty(fechaHastaSeleccionada)
                ? [
                    fechaDesdeSeleccionada.toDate(),
                    fechaHastaSeleccionada.toDate(),
                  ]
                : null
            }
          />
        )}
      </div>
    </div>
  );
};

CalendarioDesdeHastaInput.propTypes = {
  texto: PropTypes.string,
  fechaDesdeSeleccionada: PropTypes.object,
  setFechaDesdeSeleccionada: PropTypes.func,
  fechaHastaSeleccionada: PropTypes.object,
  setFechaHastaSeleccionada: PropTypes.func,
};

export default CalendarioDesdeHastaInput;
