import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const ButtonIcon = ({ icon, icon2X, icon3X, handleOnClick }) => (
  // eslint-disable-next-line react/button-has-type
  <button className="button-icon" onClick={handleOnClick}>
    <img
      className="button-icon__img"
      src={icon}
      srcSet={`${icon2X} 2x, ${icon3X} 3x`}
      alt="Menú para ver más opciones"
    />
  </button>
);

ButtonIcon.propTypes = {
  icon: PropTypes.string,
  icon2X: PropTypes.string,
  icon3X: PropTypes.string,
  handleOnClick: PropTypes.func,
};

export default ButtonIcon;
