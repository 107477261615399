import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// actions
import { showHeader } from 'actions/ui';
import fetchConfiguracionesTurno from 'actions/configuracionTurno';

// api services
import { eliminarConfiguracionesTurnoDia } from 'services/apiServices';
import { tipoTurnos, tipoTurnoFromPath } from 'utils/tipoTurnos';

import {
  CARGANDO,
  ELIGE_DIA_DISPONIBILIDAD,
  CON_AGENDAS,
  ELIMINAR_CONFIGURACION,
  CANCELAR_ELIMINAR_CONFIGURACION,
  ULTIMA_FECHA_DISPONIBLE,
  CONFIGURAR_NUEVA_AGENDA,
  ELIGE_FECHAS_DISPONIBLES,
  PARA_LOS_CIUDADANOS,
  NO_SE_PUDO_ELIMINAR,
  DIA_CON_AGENDAS,
  estaSeguroEliminar,
  cantidadTurnosRegistradosDia,
  estaSeguroEliminarTextoExtraAgendados,
} from 'constants/commonConstants';

// iconos
import iconoSinReservas from 'assets/icons/trianguloSuperior.svg';

// componentes
import Spinner from 'react-spinkit';
import Button from 'components/Button';
import CardSpinner from 'components/CardSpinner';
import Card from 'components/Card';
import InfoSection from 'components/InfoSection(viejo)';
import Calendario from 'components/Calendario';
import ModalConfirmarAccion from 'components/ModalConfirmarAccion';

// estilos
import styles from './ListarConfiguracionTurno.module.css';

const ConfiguracionTurnoContainer = ({
  mostrarHeader,
  configuracionesTurno,
  ultimaFechaConfigurada,
  cargando,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [cantidadTurnosDia, setCantidadTurnosDia] = useState(null);
  const [puedeEliminarConfiguracionTurno, setPuedeEliminarConfiguracionTurno] =
    useState(false);
  const [cargandoEliminar, setCargandoEliminar] = useState(false);
  const [cargandoConfiguraciones, setCargandoConfiguraciones] = useState(false);
  const [modalAbierto, setModalAbierto] = useState(false);
  const [errorEliminar, setErrorEliminar] = useState(false);
  const tipoTurno = tipoTurnoFromPath(window.location.pathname);

  useEffect(() => {
    mostrarHeader();
    dispatch(
      fetchConfiguracionesTurno(
        tipoTurno,
        dayjs().startOf('month').format('YYYY-MM-DD'),
        dayjs().endOf('month').format('YYYY-MM-DD'),
      ),
    );
  }, []);

  const generarIcono = fecha => {
    // Solamente se muestra el iconoSinReservas en fechas que tengan configuración turno
    // y no tengan reservas asociadas.
    const fechaFormateada = dayjs(fecha).format('YYYY-MM-DD');
    if (
      configuracionesTurno &&
      fechaFormateada in configuracionesTurno &&
      configuracionesTurno[fechaFormateada].every(
        configuracion => configuracion.cantidad_cupos_ocupados > 0,
      )
    ) {
      return (
        <img
          className={styles.esquinaDiaConAgendas}
          src={iconoSinReservas}
          alt={DIA_CON_AGENDAS}
        />
      );
    }
    return null;
  };

  const ultimaFecha = ultimaFechaConfigurada
    ? dayjs(ultimaFechaConfigurada).locale('es').format('D [d]e MMMM [d]e YYYY')
    : 'No hay configuraciones';

  const fechaDeshabilitada = fecha => {
    // Se deshabilitan aquellos que no tengan configuracion turno
    const fechaFormateada = dayjs(fecha).format('YYYY-MM-DD');
    const esFinDeSemana = fecha.getDay() === 0 || fecha.getDay() === 6;
    return (
      esFinDeSemana ||
      (configuracionesTurno && !(fechaFormateada in configuracionesTurno))
    );
  };

  const cantidadTurnos = fecha => {
    if (configuracionesTurno && fecha) {
      return configuracionesTurno[fecha]
        .reduce(
          (sumaParcial, configuracionTurno) =>
            sumaParcial +
            configuracionTurno.cantidad_cupos_ocupados +
            configuracionTurno.cantidad_cupos_disponibles,
          0,
        )
        .toString();
    }
    return null;
  };

  const puedeEliminar = fecha =>
    configuracionesTurno && fecha in configuracionesTurno;

  const handleCambiarModal = () => {
    setModalAbierto(!modalAbierto);
  };

  const handleClickEliminar = async () => {
    try {
      setCargandoEliminar(true);
      const fechaFormateada = fechaSeleccionada.format('YYYY-MM-DD');
      const ids = configuracionesTurno[fechaFormateada].map(
        configuracion => configuracion.id,
      );
      await eliminarConfiguracionesTurnoDia(tipoTurno, ids);
      dispatch(
        fetchConfiguracionesTurno(
          tipoTurno,
          fechaSeleccionada.startOf('month').format('YYYY-MM-DD'),
          fechaSeleccionada.endOf('month').format('YYYY-MM-DD'),
        ),
      );
      handleCambiarModal();
      setFechaSeleccionada(null);
      setCargandoEliminar(false);
    } catch {
      setCargandoEliminar(false);
      setErrorEliminar(NO_SE_PUDO_ELIMINAR);
      handleCambiarModal();
    }
  };

  const handleCrearConfiguracion = () => {
    history.push(tipoTurnos[tipoTurno].crearConfiguracionTurnoPath);
  };

  const handleCambioMes = cambio => {
    if (cambio?.action === 'next' || cambio?.action === 'prev') {
      setCargandoConfiguraciones(true);
      const nuevoDia = cambio?.activeStartDate;
      dispatch(
        fetchConfiguracionesTurno(
          tipoTurno,
          dayjs(nuevoDia).startOf('month').format('YYYY-MM-DD'),
          dayjs(nuevoDia).endOf('month').format('YYYY-MM-DD'),
        ),
      );
    }
  };

  const guardarConvertirFecha = fecha => {
    setFechaSeleccionada(dayjs(fecha));
  };

  useEffect(() => {
    setCantidadTurnosDia(
      cantidadTurnos(fechaSeleccionada?.format('YYYY-MM-DD')),
    );
    setPuedeEliminarConfiguracionTurno(
      puedeEliminar(fechaSeleccionada?.format('YYYY-MM-DD')),
    );
  }, [fechaSeleccionada]);

  useEffect(() => {
    setCargandoConfiguraciones(false);
  }, [configuracionesTurno]);

  if (cargando && !configuracionesTurno) {
    return <CardSpinner spin text={CARGANDO} />;
  }

  const cantidadAgendadosFechaSeleccionada = Object.values(
    configuracionesTurno?.[dayjs(fechaSeleccionada).format('YYYY-MM-DD')] || {},
  ).reduce(
    (acumulador, registro) => acumulador + registro.cantidad_cupos_ocupados,
    0,
  );

  return (
    <>
      <h1 className={styles.titulo}>{tipoTurnos[tipoTurno].agendasTitulo}</h1>
      <Card
        className={styles.cardCalendario}
        classNameInner={styles.cardCalendarioInner}
      >
        <p className={styles.cabezal}>{ELIGE_DIA_DISPONIBILIDAD}</p>
        <div className={styles.contenedorCalendario}>
          <Calendario
            generarIcono={generarIcono}
            tileClassName={styles.tile}
            setFechasSeleccionadas={guardarConvertirFecha}
            tileDisabled={fechaDeshabilitada}
            handleCambioMes={handleCambioMes}
            fechaMaxima={dayjs(ultimaFechaConfigurada).toDate()}
          />
          <div className={styles.contenedorCargandoReferencia}>
            <div className={styles.contenedorCargando}>
              {cargandoConfiguraciones && (
                <Spinner name="ball-beat" fadeIn="none" color="black" />
              )}
            </div>
            <div className={styles.referencia}>
              <div className={styles.referenciaCuadrado} />
              <p>{CON_AGENDAS}</p>
            </div>
          </div>
        </div>
        {cantidadTurnosDia && (
          <div className={styles.recuadroInformativo}>
            <p>{cantidadTurnosRegistradosDia(cantidadTurnosDia)}</p>
          </div>
        )}

        <Button
          className={styles.botonNuevo}
          title={ELIMINAR_CONFIGURACION}
          callback={handleCambiarModal}
          disabled={!puedeEliminarConfiguracionTurno}
          type="primary"
          size="lg"
        />
        <p className={styles.errorEliminar}>{errorEliminar}</p>
        <ModalConfirmarAccion
          isOpen={modalAbierto}
          onClose={handleCambiarModal}
          text={estaSeguroEliminar(fechaSeleccionada)}
          buttonYesCallback={handleCambiarModal}
          buttonNoCallback={handleClickEliminar}
          loading={cargandoEliminar}
          buttonTextPositiveAction={CANCELAR_ELIMINAR_CONFIGURACION}
          buttonTextNegativeAction={ELIMINAR_CONFIGURACION}
        >
          {cantidadAgendadosFechaSeleccionada > 0 && (
            <b>
              {estaSeguroEliminarTextoExtraAgendados(
                cantidadAgendadosFechaSeleccionada,
              )}
            </b>
          )}
        </ModalConfirmarAccion>
      </Card>
      <Card className={styles.cardNuevo} classNameInner={styles.cardNuevoInner}>
        <InfoSection
          small
          title={
            <h2 className={styles.tituloNuevo}>{CONFIGURAR_NUEVA_AGENDA}</h2>
          }
          info_array={[
            {
              title: ULTIMA_FECHA_DISPONIBLE,
              value: ultimaFecha,
            },
            {
              title: (
                <>
                  {ELIGE_FECHAS_DISPONIBLES} <br></br> {PARA_LOS_CIUDADANOS}
                </>
              ),
              value: (
                <Button
                  className={styles.botonConfigurarAgenda}
                  title={CONFIGURAR_NUEVA_AGENDA}
                  callback={handleCrearConfiguracion}
                  type="primary"
                  size="lg"
                  isLoading={false}
                />
              ),
            },
          ]}
        />
      </Card>
    </>
  );
};

ConfiguracionTurnoContainer.propTypes = {
  mostrarHeader: PropTypes.func,
  configuracionesTurno: PropTypes.object,
  ultimaFechaConfigurada: PropTypes.string,
  cargando: PropTypes.bool,
};

const mapStateToProps = state => ({
  configuracionesTurno: state.configuracionesTurno?.listado,
  ultimaFechaConfigurada: state.configuracionesTurno?.ultimaFecha,
  cargando: state.configuracionTurno?.loading,
});

export default connect(mapStateToProps, {
  fetchConfiguracionesTurno,
  mostrarHeader: showHeader,
})(ConfiguracionTurnoContainer);
