import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

// constantes
import {
  TEXTO_CANCELAR_GUARDAR_DATOS_PERSONALES,
  TEXTO_GUARDAR_DATOS_PERSONALES,
} from 'constants/commonConstants';

// componentes
import TextField from 'components/HookForm/TextField';
import ButtonFlat from 'components/ButtonFlat';

// styles
import styles from './styles.module.css';

/**
 * Este componente renderiza un formulario con campos precargados.
 *
 * @param campos - Los campos del formulario a renderizar con su valor precargado.
 * @param modificarCallback - Acción en caso de aceptar
 * @param botonVolverCallback - Acción en caso de cancelar
 * @returns {React.JSX.Element} - El JSX que renderiza el componente.
 */
const UpdateInfoCiudadanoForm = ({
  campos,
  modificarCallback,
  botonVolverCallback,
}) => {
  const obtenerDefaultValues = () =>
    campos.reduce(
      (defaultValues, campo) => ({
        ...defaultValues,
        [campo.nombre]: campo.valor,
      }),
      {},
    );

  const {
    register,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: obtenerDefaultValues(),
  });

  const handleModificarDatos = () => {
    const nuevosValores = campos.reduce(
      (valores, campo) => ({
        ...valores,
        [campo.nombre]: watch(campo.nombre),
      }),
      {},
    );
    modificarCallback(nuevosValores);
  };

  return (
    <div className={styles.contenedorPrincipal}>
      <div className={styles.contenedorCampos}>
        {campos.map(campo => (
          <TextField
            key={campo.nombre}
            register={register}
            name={campo.nombre}
            label={campo.label}
            type="text"
            required={campo.requerido}
            error={errors[campo.nombre]}
          />
        ))}
      </div>
      <div className={styles.contenedorBotones}>
        <ButtonFlat
          disabled={!isValid}
          className={styles.botonPrimario}
          handleOnClick={handleModificarDatos}
          text={TEXTO_GUARDAR_DATOS_PERSONALES}
          type="primary"
        />
        <ButtonFlat
          className={styles.boton}
          text={TEXTO_CANCELAR_GUARDAR_DATOS_PERSONALES}
          handleOnClick={botonVolverCallback}
        />
      </div>
    </div>
  );
};

UpdateInfoCiudadanoForm.propTypes = {
  campos: PropTypes.array,
  modificarCallback: PropTypes.func,
  botonVolverCallback: PropTypes.func,
};

export default UpdateInfoCiudadanoForm;
