import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// utils
import Auth from 'utils/Auth';

// constantes
import {
  bievenidoAgente,
  TEXTO_AGENTES,
  TEXTO_GUB_UY,
} from 'constants/commonConstants';

// router constants
import {
  AGENDA_VIDEOLLAMADA,
  CONFIGURACION_TURNOS,
  GESTION_AGENTES,
  INDEX_ROUTE,
  LOGOUT_PROCESS,
  SEARCH_ROUTE,
} from 'constants/RouterConstants';

// componentes
import HeaderMenuInterno from 'components/HeaderMenuInterno';

// styles
import styles from './styles.module.css';

const Header = ({ showHeader, currentAgente }) => {
  const [menuAbierto, setMenuAbierto] = useState(false);
  const [mostrarMasOpcionesTooltip, setMostrarMasOpcionesTooltip] =
    useState(false);

  const history = useHistory();

  const handleIrAGestionAgentes = () => {
    history.push(GESTION_AGENTES);
  };

  const handleIrAInicio = () => {
    setMenuAbierto(false);
    history.push(SEARCH_ROUTE);
  };

  const handleIrAAgendaVideollamada = () => {
    history.push(AGENDA_VIDEOLLAMADA);
  };

  const handleIrAConfiguracionTurnos = () => {
    history.push(CONFIGURACION_TURNOS);
  };

  const handleIrALogout = () => {
    history.push(LOGOUT_PROCESS);
  };

  const handleIrAHome = () => {
    if (Auth.getInstance().isAuthenticated()) {
      history.push(SEARCH_ROUTE);
    } else {
      history.push(INDEX_ROUTE);
    }
  };

  const handleAbrirMenu = () => {
    setMenuAbierto(!menuAbierto);
  };

  const handleAbrirTooltip = () => {
    setMostrarMasOpcionesTooltip(!mostrarMasOpcionesTooltip);
  };

  return (
    showHeader && (
      <header className={styles.headerContainer}>
        <div className={styles.headerWrapper}>
          <div className={styles.headerWrapper__left}>
            <button
              type="button"
              className={styles.focusStyle}
              onClick={handleIrAHome}
            >
              <span className={styles.headerWrapper__left__logo}>
                {TEXTO_GUB_UY}
              </span>
            </button>
            <span className={styles.headerWrapper__left__lineSeparator}></span>
            <div className={styles.headerWrapper__left__text}>
              {TEXTO_AGENTES}
            </div>
            {currentAgente && (
              <span className={styles.headerWrapper__right__agenteNombre}>
                {bievenidoAgente(currentAgente)}
              </span>
            )}
          </div>
          <div className={styles.headerWrapper__right}>
            <HeaderMenuInterno
              menuAbierto={menuAbierto}
              mostrarMasOpcionesTooltip={mostrarMasOpcionesTooltip}
              handleAbrirMenu={handleAbrirMenu}
              handleAbrirTooltip={handleAbrirTooltip}
              handleIrAGestionAgentes={handleIrAGestionAgentes}
              handleIrAInicio={handleIrAInicio}
              handleIrAAgendaVideollamada={handleIrAAgendaVideollamada}
              handleIrAConfiguracionTurnos={handleIrAConfiguracionTurnos}
              handleIrALogout={handleIrALogout}
            />
          </div>
        </div>
      </header>
    )
  );
};

Header.propTypes = {
  showHeader: PropTypes.bool,
  currentAgente: PropTypes.string,
};

export default Header;
