import React, { useEffect, useState } from 'react';

// constantes
import {
  snackbarEditarPermisosExito,
  snackbarEditarPermisosFallo,
  TEXTO_AGENTES,
  TEXTO_BUSCA_ADMINISTRA,
  TEXTO_NO_SE_ENCONTRARON,
} from 'constants/commonConstants';

// api services
import {
  editarPermisosAgente,
  obtenerAgentesOrganismo,
  obtenerOrganismos,
} from 'services/apiServices';

// componentes
import Card from 'components/Card';
import GestionAgentesTabla from 'components/GestionAgentes/GestionAgentesTabla';
import GestionAgentesModal from 'components/GestionAgentes/GestionAgentesModal';
import Snackbar from 'components/Snackbar';
import GestionAgentesFiltrado from 'components/GestionAgentes/GestionAgentesFiltrado';

// permisos
import usePermissions from 'hooks/usePermissions';
import { PERMISO_SUPER_AGENTE } from 'constants/permissionsConstants';

// MUI
import { CircularProgress, Pagination } from '@mui/material';

// styles
import styles from './styles.module.css';

export const DEFAULT_FILTROS = {
  organismo: '',
  cedula: '',
  nombre: '',
  permisos: [],
};

const GestionAgentesContainer = () => {
  const [datosAgentes, setDatosAgentes] = useState(null);
  const [cargandoAgentes, setCargandoAgentes] = useState(false);
  const [paginaActual, setPaginaActual] = useState(1);
  const [cantidadAgentesPagina, setCantidadAgentesPagina] = useState(0);
  const [modalEditarAbierto, setModalEditarAbierto] = useState(false);
  const [agenteAEditar, setAgenteAEditar] = useState(null);
  const [cargandoEditarPermisos, setCargandoEditarPermisos] = useState(false);
  const [checkboxesPermisos, setCheckboxesPermisos] = useState({});
  const [snackBarInfo, setSnackBarInfo] = useState({
    mostrar: false,
    texto: '',
  });

  const [existenMasOrganismos, setExistenMasOrganismos] = useState(true);
  const [cargandoOrganismo, setCargandoOrganismo] = useState(false);
  const [organismosNextUrl, setOrganismosNextUrl] = useState(null);
  const [organismos, setOrganismos] = useState([]);

  const [filtros, setFiltros] = useState(DEFAULT_FILTROS);

  const esSuperAgente = usePermissions([PERMISO_SUPER_AGENTE]);

  const obtenerOrganismosPaginados = async url => {
    try {
      setCargandoOrganismo(true);
      const response = await obtenerOrganismos(url);
      const organismosResponse = response.data.results;

      if (response.data.next === null) {
        setExistenMasOrganismos(false);
      } else {
        setOrganismosNextUrl(response.data.next);
      }
      setOrganismos(...organismos, organismosResponse);
    } catch {
      setExistenMasOrganismos(false);
    } finally {
      setCargandoOrganismo(false);
    }
  };

  useEffect(() => {
    if (esSuperAgente && !cargandoOrganismo && existenMasOrganismos) {
      obtenerOrganismosPaginados(organismosNextUrl);
    }
  }, [organismos]);

  const handleEditarInfoAgente = agente => {
    setAgenteAEditar(agente);
    setCheckboxesPermisos({
      activo: agente?.permisos.activo,
      responsable: agente?.permisos.responsable,
      escritura: agente?.permisos.escritura,
      videollamada: agente?.permisos.videollamada,
    });
    setModalEditarAbierto(true);
  };

  const obtenerAgentes = async (pagina, filtrosRequest) => {
    try {
      const filtrosParams = {
        organismo: filtrosRequest?.organismo ? filtrosRequest?.organismo : null,
        numero_documento: filtrosRequest?.cedula
          ? filtrosRequest?.cedula
          : null,
        nombre: filtrosRequest?.nombre ? filtrosRequest?.nombre : null,
        escritura: filtrosRequest?.permisos.includes('Escritura')
          ? filtrosRequest?.permisos.includes('Escritura')
          : null,
        activo: filtrosRequest?.permisos.includes('Activo')
          ? filtrosRequest?.permisos.includes('Activo')
          : null,
        videollamada: filtrosRequest?.permisos.includes('Videollamada')
          ? filtrosRequest?.permisos.includes('Videollamada')
          : null,
        responsable: filtrosRequest?.permisos.includes('Responsable')
          ? filtrosRequest?.permisos.includes('Responsable')
          : null,
      };
      setCargandoAgentes(true);
      const response = await obtenerAgentesOrganismo(pagina, filtrosParams);
      const agentes = response.data.results;
      if (pagina === null) {
        const cantidadAgentes = response.data.count;
        const tamanioPagina = agentes.length;
        setCantidadAgentesPagina(Math.ceil(cantidadAgentes / tamanioPagina));
      }
      setPaginaActual(pagina || 1);
      setDatosAgentes(agentes);
    } catch {
      setCantidadAgentesPagina(0);
      setDatosAgentes([]);
    } finally {
      setCargandoAgentes(false);
    }
  };

  useEffect(() => {
    obtenerAgentes(null);
  }, []);

  const handleCambiarPagina = async pagina => {
    obtenerAgentes(pagina, filtros);
  };

  const handleBotonPrincipal = async () => {
    let snackBarTexto;
    try {
      const data = {
        activo: checkboxesPermisos.activo,
        responsable: checkboxesPermisos.responsable,
        escritura: checkboxesPermisos.escritura,
        videollamada: checkboxesPermisos.videollamada,
      };
      setCargandoEditarPermisos(true);
      await editarPermisosAgente(agenteAEditar?.id, data);
      snackBarTexto = snackbarEditarPermisosExito(
        agenteAEditar?.nombre_completo,
      );
      setModalEditarAbierto(false);
      obtenerAgentes(paginaActual, filtros);
    } catch {
      snackBarTexto = snackbarEditarPermisosFallo(
        agenteAEditar?.nombre_completo,
      );
    } finally {
      setCargandoEditarPermisos(false);
      setSnackBarInfo({
        mostrar: true,
        texto: snackBarTexto,
      });
    }
  };

  const renderizarTabla = () => {
    if (cargandoAgentes) {
      return <CircularProgress size="100px" />;
    }
    return datosAgentes?.length > 0 ? (
      <GestionAgentesTabla
        datosAgentes={datosAgentes}
        handleEditarInfoAgente={handleEditarInfoAgente}
        esSuperAgente={esSuperAgente}
      />
    ) : (
      <p className={styles.noEncontrado}>{TEXTO_NO_SE_ENCONTRARON}</p>
    );
  };

  return (
    <>
      <GestionAgentesModal
        modalEditarAbierto={modalEditarAbierto}
        setModalEditarAbierto={setModalEditarAbierto}
        agenteAEditar={agenteAEditar}
        handleBotonPrincipal={handleBotonPrincipal}
        cargandoEditarPermisos={cargandoEditarPermisos}
        checkboxesPermisos={checkboxesPermisos}
        setCheckboxesPermisos={setCheckboxesPermisos}
        esSuperAgente={esSuperAgente}
      />
      <div className={styles.mainContenedor}>
        <p className={styles.textoAgentes}>{TEXTO_AGENTES}</p>
        <p className={styles.textoInformativo}>{TEXTO_BUSCA_ADMINISTRA}</p>
        <Card className={styles.card}>
          <div className={styles.cardContenedor}>
            <GestionAgentesFiltrado
              filtros={filtros}
              setFiltros={setFiltros}
              organismosDisponibles={organismos}
              obtenerAgentes={obtenerAgentes}
              cargandoAgentes={cargandoAgentes}
              esSuperAgente={esSuperAgente}
            />
            {renderizarTabla()}
          </div>
          {!cargandoAgentes && datosAgentes?.length > 0 && (
            <div className={styles.paginationContenedor}>
              <Pagination
                page={paginaActual}
                count={cantidadAgentesPagina}
                variant="outlined"
                onChange={(_, pagina) => handleCambiarPagina(pagina)}
              />
            </div>
          )}
        </Card>
      </div>
      <Snackbar
        desplegar={snackBarInfo.mostrar}
        mensaje={snackBarInfo.texto}
        onClose={() => setSnackBarInfo({ ...snackBarInfo, mostrar: false })}
      />
    </>
  );
};

export default GestionAgentesContainer;
