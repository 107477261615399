/* eslint-disable react/prop-types */
import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';

const Alert = ({ link, linkText, linkOnClick, type, text }) => {
  const linkComponent = link ? (
    <button type="button" className="like-link" onClick={linkOnClick}>
      {linkText}{' '}
    </button>
  ) : null;
  return (
    <div className={`alert alert--${type} row`}>
      <div className="col-md-11">
        <span>
          {text} {linkComponent}
        </span>
      </div>
    </div>
  );
};

Alert.propTypes = {
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkOnClick: PropTypes.func,
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default Alert;
