import { FETCH_CONFIGURACIONES_TURNO } from 'constants/ActionTypes';

import { API_REQUEST } from 'constants/ApiConstants';
import { tipoTurnos } from 'utils/tipoTurnos';

const fetchConfiguracionesTurno = (tipoTurno, fechaInicio, fechaFin) => ({
  type: API_REQUEST,
  payload: {
    url: tipoTurnos[tipoTurno].fetchTurnosUrl(fechaInicio, fechaFin),
    method: 'get',
    next: FETCH_CONFIGURACIONES_TURNO,
  },
});

export default fetchConfiguracionesTurno;
