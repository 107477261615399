import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';

import App from 'components/App';
import Auth from 'utils/Auth';
import { fetchAgente } from 'actions/agente';
import { currentAgenteSelector } from 'store/selectors';

// Inicializar Google Tag Manager
if (window.REACT_APP_GTM_KEY) {
  const tagManagerArgs = {
    gtmId: window.REACT_APP_GTM_KEY,
  };
  TagManager.initialize(tagManagerArgs);
}

const AppContainer = () => {
  const dispatch = useDispatch();

  const currentAgente = useSelector(currentAgenteSelector);

  useEffect(() => {
    if (Auth.getInstance().isAuthenticated() && !currentAgente) {
      dispatch(fetchAgente());
    }
  }, []);

  return <App />;
};

export default AppContainer;
