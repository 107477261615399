import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

// constantes
import {
  ELEGIR_DIAS_DESC,
  ELEGIR_DIAS_TEXT,
  SELECCIONE_FECHAS_TEXT,
  SELECCIONE_HORARIOS_TEXT,
  CANTIDAD_AGENTES_TEXT,
  CANTIDAD_AGENTES_PLACEHOLDER,
} from 'constants/commonConstants';

// componentes
import CalendarioDesdeHastaInput from 'components/CalendarioDesdeHastaInput';
import DropdownSelectorHora from 'components/DropdownSelectorHora';
import TextField from 'components/HookForm/TextField';
import TitleCard from 'components/TitleCard';

import dayjs from 'dayjs';
import styles from './styles.module.css';

const CrearConfiguracionTurnoForm = ({
  fechaDesdeSeleccionada,
  setFechaDesdeSeleccionada,
  fechaHastaSeleccionada,
  setFechaHastaSeleccionada,
  horaDesdeSeleccionada,
  setHoraDesdeSeleccionada,
  horaHastaSeleccionada,
  setHoraHastaSeleccionada,
  cantidadAgentes,
  setCantidadAgentes,
}) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      cantidadAgentesForm:
        cantidadAgentes.length > 0 && Number(cantidadAgentes),
    },
  });

  const [mostrarSelectorHoraDesde, setMostrarSelectorHoraDesde] =
    useState(false);
  const [mostrarSelectorHoraHasta, setMostrarSelectorHoraHasta] =
    useState(false);

  const cantidadAgentesForm = watch('cantidadAgentesForm');

  useEffect(() => {
    setCantidadAgentes(String(cantidadAgentesForm));
  }, [cantidadAgentesForm]);

  return (
    <>
      <TitleCard title={ELEGIR_DIAS_TEXT} className={styles.textoCardTitulo} />
      <TitleCard
        title={ELEGIR_DIAS_DESC}
        className={styles.textoCardDescripcion}
      />
      <CalendarioDesdeHastaInput
        texto={SELECCIONE_FECHAS_TEXT}
        fechaDesdeSeleccionada={fechaDesdeSeleccionada}
        setFechaDesdeSeleccionada={setFechaDesdeSeleccionada}
        fechaHastaSeleccionada={fechaHastaSeleccionada}
        setFechaHastaSeleccionada={setFechaHastaSeleccionada}
      />
      <div className={styles.contenedorSelectoresHora}>
        <DropdownSelectorHora
          texto={SELECCIONE_HORARIOS_TEXT}
          horaSeleccionada={horaDesdeSeleccionada}
          setHoraSeleccionada={hora => {
            setHoraDesdeSeleccionada(hora);
            setMostrarSelectorHoraHasta(true);
          }}
          mostrarSelectorHora={mostrarSelectorHoraDesde}
          setMostrarSelectorHora={setMostrarSelectorHoraDesde}
          horaMinima={dayjs().hour(0).minute(0)}
        />
        <DropdownSelectorHora
          horaSeleccionada={horaHastaSeleccionada}
          setHoraSeleccionada={setHoraHastaSeleccionada}
          mostrarSelectorHora={mostrarSelectorHoraHasta}
          setMostrarSelectorHora={setMostrarSelectorHoraHasta}
          horaMinima={
            !isEmpty(horaDesdeSeleccionada)
              ? dayjs(horaDesdeSeleccionada.value).add(30, 'minutes')
              : dayjs().hour(0).minute(0)
          }
        />
      </div>
      <div className={styles.inputContainer}>
        <TextField
          clase="cantidadAgentes"
          register={register}
          name="cantidadAgentesForm"
          label={CANTIDAD_AGENTES_TEXT}
          type="number"
          required
          error={errors.cantidadAgentesForm}
          placeholder={CANTIDAD_AGENTES_PLACEHOLDER}
          upper
          small
        />
      </div>
    </>
  );
};

CrearConfiguracionTurnoForm.propTypes = {
  fechaDesdeSeleccionada: PropTypes.object,
  setFechaDesdeSeleccionada: PropTypes.func,
  fechaHastaSeleccionada: PropTypes.object,
  setFechaHastaSeleccionada: PropTypes.func,
  horaDesdeSeleccionada: PropTypes.object,
  setHoraDesdeSeleccionada: PropTypes.func,
  horaHastaSeleccionada: PropTypes.object,
  setHoraHastaSeleccionada: PropTypes.func,
  cantidadAgentes: PropTypes.string,
  setCantidadAgentes: PropTypes.func,
};

export default CrearConfiguracionTurnoForm;
