import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const TitleCard = ({ className, title }) => (
  <h1 className={className}>{title}</h1>
);

TitleCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default TitleCard;
