import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const ErrorHelpText = ({ error, msg, small }) => (
  <div
    className={
      small
        ? styles['form-field__help-text__error__div--small']
        : styles['form-field__help-text__error__div']
    }
  >
    {error && (
      <span
        className={
          small
            ? styles['form-field__help-text__error--small']
            : styles['form-field__help-text__error']
        }
      >
        {msg}
      </span>
    )}
  </div>
);
ErrorHelpText.propTypes = {
  error: PropTypes.bool,
  msg: PropTypes.string,
  small: PropTypes.bool,
};

export default ErrorHelpText;
