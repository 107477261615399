import { FETCH_CONFIGURACIONES_TURNO } from 'constants/ActionTypes';

export const INITIAL_STATE = {
  listado: null,
  ultimaFecha: null,
  loading: true,
};

export default function configuracionTurnoReducer(
  // eslint-disable-next-line default-param-last
  state = INITIAL_STATE,
  action,
) {
  switch (action.type) {
    // -----------------------------------------------------------------------
    // FETCH_CONFIGURACIONES_TURNO
    // -----------------------------------------------------------------------
    case FETCH_CONFIGURACIONES_TURNO.SUCCESS:
      return {
        ...state,
        listado: action.payload.data.configuraciones_turno,
        ultimaFecha: action.payload.data.ultima_fecha_configurada,
        loading: true,
      };

    case FETCH_CONFIGURACIONES_TURNO.ERROR:
      return {
        ...state,
        listado: null,
        ultimaFecha: null,
        loading: false,
      };

    case FETCH_CONFIGURACIONES_TURNO.PENDING:
      return {
        ...state,
        loading: true,
      };

    // DEFAULT
    default:
      return state;
  }
}
