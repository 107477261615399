import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { hideHeader } from 'actions/ui';
import { fetchAgente } from 'actions/agente';
import { connect } from 'react-redux';

// API
import { fetchVideollamadaSoporte } from 'services/apiServices';

// Components
import Card from 'components/Card';
import CardSpinner from 'components/CardSpinner';
import { Videollamada } from './videollamada';
import { VideollamadaSoporteInfo } from './Info';

// Styles
import styles from '../Videollamada/Container/index.module.css';

const VideollamadaSoporteContainer = ({ ocultarHeader, agenteLogueado }) => {
  const [cargando, setCargando] = useState(true);
  const [videollamada, setVideollamada] = useState(null);
  const { uuid } = useParams();

  const handleObtenerVideollamada = async () => {
    try {
      const response = await fetchVideollamadaSoporte(uuid);
      setVideollamada(response.data);
      setCargando(false);
    } catch (e) {
      setVideollamada(null);
    }
  };

  useEffect(() => {
    ocultarHeader();
    handleObtenerVideollamada();
  }, []);

  if (cargando) {
    return <CardSpinner spin />;
  }

  return (
    <div className={styles.contenedor}>
      <Card className={styles.contenedorInformacion}>
        <VideollamadaSoporteInfo videollamada={videollamada} />;
      </Card>
      <Card className={styles.contenedorVideollamada}>
        <Videollamada
          videollamada={videollamada}
          nombreAgente={agenteLogueado}
        />
      </Card>
    </div>
  );
};

VideollamadaSoporteContainer.propTypes = {
  ocultarHeader: PropTypes.func,
  agenteLogueado: PropTypes.string,
};

const mapStateToProps = state => ({
  agenteLogueado: state.agente?.currentAgenteNombre,
});

export default connect(mapStateToProps, {
  fetchAgente,
  ocultarHeader: hideHeader,
})(VideollamadaSoporteContainer);
