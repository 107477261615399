import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';

import styles from '../styles.module.css';

const ListaItem = ({ ciudadano, callback }) => (
  <tr className={`row ${styles.table__item_group}`}>
    <td className={`col-md-3 ${styles.table__item}`}>
      {ciudadano.nombre_completo}
    </td>
    <td className={`col-md-3 ${styles.table__item}`}>
      {ciudadano.numero_documento}
    </td>
    <td className={`col-md-4 ${styles.table__item}`}>{ciudadano.user.email}</td>
    <td className={styles.table__btn}>
      <Button
        title="Perfil"
        type="primary"
        size="80"
        side="right"
        className="table__item-action"
        callback={() => {
          callback(ciudadano.id);
        }}
      />
    </td>
  </tr>
);

ListaItem.propTypes = {
  ciudadano: PropTypes.object,
  callback: PropTypes.func,
};

export default ListaItem;
