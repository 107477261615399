import {
  FETCH_CIUDADANO,
  RESET_PROFILE,
  RESET_FORM_ERRORS,
  REMOVE_CIUDADANO, // BLOQUEAR_CIUDADANO, DESBLOQUEAR_CIUDADANO,
} from 'constants/ActionTypes';

// ###################################
// TODO: Mejorar el manejo del estado.
// Mucha lógica manejada por flags..
// ###################################
export const INITIAL_STATE = {
  loading: false,
  ciudadano: null,
  formErrors: null,
  removed: null,
  removing: false,
  removingError: null,
  errorStatusCode: '',
};

// eslint-disable-next-line default-param-last
export default function ciudadanoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_CIUDADANO.SUCCESS:
      return {
        ...state,
        ciudadano: action.payload.data,
        loading: false,
      };

    case FETCH_CIUDADANO.PENDING:
      return {
        ...state,
        loading: true,
        ciudadanoCreado: false,
      };

    case FETCH_CIUDADANO.ERROR:
      return {
        ...state,
        loading: false,
      };

    case RESET_FORM_ERRORS:
      return {
        ...state,
        formErrors: null,
      };

    case RESET_PROFILE:
      return {
        ...state,
        ciudadanoCambioAPresencial: null,
        ciudadanoCambioDatosPersonales: null,
        ciudadanoCambioEmail: null,
        resendErrors: null,
        resended: false,
        loading: false,
        sending: false,
        removing: false,
        removed: null,
        removingError: null,
        blocked: null,
        blocking: false,
        blockingErrors: null,
        errorStatusCode: null,
      };

    case REMOVE_CIUDADANO.PENDING:
      return {
        ...state,
        removing: true,
        removingError: null,
      };

    case REMOVE_CIUDADANO.SUCCESS:
      return {
        ...state,
        removed: true,
        removing: false,
        listaCiudadanos: null,
      };

    case REMOVE_CIUDADANO.ERROR:
      return {
        ...state,
        errorStatusCode: action.error_code,
        removing: false,
        removed: false,
        removingError: action.paylod,
      };

    default:
      return state;
  }
}
