import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  obtenerMensajeErrorLargoMaximo,
  obtenerMensajeErrorLargoMinimo,
  REQUERIDO,
} from 'constants/commonConstants';

import styles from './styles.module.css';
import ErrorHelpText from './ErrorHelpText';

export const TextAreaField = ({
  register,
  name,
  small,
  label,
  required,
  error,
  placeholder,
  minLength,
  maxLength,
  validationRegex,
  regexErrorMessage,
  validationErrorMessage,
  className,
}) => {
  const clase = small ? 'form-field__input--small' : 'form-field__input';
  const [claseInput, setClaseInput] = useState(clase);
  const [claseLabel, setClaseLabel] = useState('form-field__label');
  const [validationError, setValidationError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validations = {
    required,
    minLength,
    maxLength,
    pattern: validationRegex,
  };

  useEffect(() => {
    if (error) {
      setValidationError(true);
      setClaseLabel('form-field__label--error');
      setClaseInput('form-field__text-area--error');
      switch (error.type) {
        case 'required':
          setErrorMessage(REQUERIDO);
          break;
        case 'minLength':
          setErrorMessage(obtenerMensajeErrorLargoMinimo(minLength));
          break;
        case 'maxLength':
          setErrorMessage(obtenerMensajeErrorLargoMaximo(maxLength));
          break;
        case 'pattern':
          setErrorMessage(regexErrorMessage);
          break;
        case 'validate':
          setErrorMessage(validationErrorMessage);
          break;
        default:
      }
    } else {
      setSubmitError(false);
      setClaseLabel(`form-field__label`);
      setValidationError(false);
      let nuevaClaseInput = 'form-field__text-area';
      if (small) {
        nuevaClaseInput += '--small';
      }
      setClaseInput(nuevaClaseInput);
    }
  }, [error]);

  return (
    <div className={`${styles['form-field']} ${className}`}>
      <span>
        <label className={styles[claseLabel]} htmlFor={name}>
          {label}
        </label>
        {!required && (
          <span className={styles['form-field__label--required']}>
            {' '}
            (Opcional)
          </span>
        )}
      </span>
      <span>
        <textarea
          {...register(name, validations)}
          className={styles[claseInput]}
          placeholder={placeholder}
          id={name}
          rows={3}
        />
      </span>

      <ErrorHelpText
        error={validationError || submitError}
        msg={errorMessage}
        small={small}
      />
    </div>
  );
};

TextAreaField.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  validationRegex: PropTypes.oneOfType([
    PropTypes.shape(RegExp),
    PropTypes.bool,
  ]),
  regexErrorMessage: PropTypes.string,
  validationErrorMessage: PropTypes.string,
  upper: PropTypes.bool,
  className: PropTypes.string,
};

export default TextAreaField;
