import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Card = ({ className = '', classNameInner = '', children }) => (
  <div className={`card ${className}`} role="region">
    <div className={`card__inner ${classNameInner}`}>{children}</div>
  </div>
);

Card.propTypes = {
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Card;
