import React, { useState, useEffect } from 'react';

// api services
import { obtenerEstadisticasDeCiudadanosLogueados } from 'services/apiServices';

// componentes
import ShortCard from 'components/ShortCard/ShortCard';
import Spinner from 'react-spinkit';

import 'components/ShortCard/styles.css';
import styles from './styles.module.css';

const EstadisticasUsuariosLogueados = () => {
  const [
    cargandoEstadisticasDeCiudadanosLogueados,
    setCargandoEstadisticasDeCiudadanosLogueados,
  ] = useState(true);
  const [ciudadanosLogueados, setCiudadanosLogueados] = useState();

  useEffect(() => {
    const cargarDatosDeCiudadanosLogueados = async () => {
      const response = await obtenerEstadisticasDeCiudadanosLogueados();
      if (response.data) setCiudadanosLogueados(response.data);
      setCargandoEstadisticasDeCiudadanosLogueados(false);
    };
    cargarDatosDeCiudadanosLogueados();
  }, []);

  return (
    <div>
      {cargandoEstadisticasDeCiudadanosLogueados ? (
        <div className={styles.center}>
          <Spinner
            className="spin-class"
            name="ball-spin-fade-loader"
            fadeIn="none"
          />
        </div>
      ) : (
        <div>
          {ciudadanosLogueados && Object.keys(ciudadanosLogueados).length ? (
            <div>
              <p>Cantidad de usuarios por fecha de logueo:</p>
              <div className="container">
                <ShortCard
                  titulo="Logueados últimas 24hs"
                  valor={ciudadanosLogueados.total_logueados_ultimas_24_horas}
                />
                <ShortCard
                  titulo="Logueados últimos 7 días"
                  valor={ciudadanosLogueados.total_logueados_ultimos_7_dias}
                />
                <ShortCard
                  titulo="Logueados últimos 30 días"
                  valor={ciudadanosLogueados.total_logueados_ultimos_30_dias}
                />
              </div>
            </div>
          ) : (
            <p>
              Todavia no hay datos de usuarios por fecha de logueo disponibles
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default EstadisticasUsuariosLogueados;
