// Videollamada
export const PERMISO_GESTIONAR_CONFIGURACION_TURNO = [
  'videollamada',
  'gestionar_configuracion_turno',
];

export const PERMISO_HACER_TRAMITE_VIDEOLLAMADA = [
  'videollamada',
  'gestionar_tramite_videollamada',
];

// Perfil ciudadano
export const FF_OBTENER_INFO_DNIC = ['ciudadanos', 'ff_obtener_info_dnic'];

// Gestión de agentes
export const PERMISO_AGENTE_RESPONSABLE = ['ciudadanos', 'agente_responsable'];
export const PERMISO_SUPER_AGENTE = ['ciudadanos', 'super_agente'];
export const PERMISO_PUEDE_ACCEDER_GESTION_AGENTES = [
  'ciudadanos',
  'acceder_gestion_agentes',
];
export const PERMISO_CREAR_CIUDADANO = ['ciudadanos', 'crear_ciudadano'];
export const PERMISO_VALIDAR_CIUDADANO = [
  'ciudadanos',
  'verificar_a_presencial_cualquier_ciudadano',
];
export const PERMISO_ELIMINAR_CIUDADANO = [
  'ciudadanos',
  'eliminar_cualquier_ciudadano',
];
export const PERMISO_MODIFICAR_INFO_PERSONAL_CUALQUIER_CIUDADANO = [
  'ciudadanos',
  'modificar_info_personal_cualquier_ciudadano',
];
export const PERMISO_MODIFICAR_CORREO_CUALQUIER_CIUDADANO = [
  'ciudadanos',
  'modificar_correo_electronico_cualquier_ciudadano',
];
export const PERMISO_REENVIAR_ACTIVACION_CUALQUIER_CIUDADANO = [
  'ciudadanos',
  'reenviar_activacion_cualquier_ciudadano',
];
